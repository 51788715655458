import React, { useContext, useEffect } from "react";
import Banner from '../../../components/Banner/Banner'
import Planos from "../../../components/Planos/Planos";
import Beneficios from '../../../components/Beneficios/Beneficios'
import BotaoBranco from "../../../components/Botao/BotaoBranco";
import Footer from "../../../components/Footer/Footer";
import '../../Planos/style.css'
import escritafiscal from '../../../images/Planos/escritafiscal.webp'
import folha from '../../../images/Planos/folha.webp'
import contabilidade from '../../../images/Planos/contabilidade.webp'
import patrimonial from '../../../images/Planos/patrimonialw.webp'
import beneficios from '../../../images/Planos/portal.webp'
import check from '../../../images/PlanosAssinatura/check.svg'
import { NavLink } from 'react-router-dom'
import { EmailContext } from "../../../context/EmailContext";

function PlanoEmpresarial() {
    const { setPdf } = useContext(EmailContext)
    useEffect(() => {
        setPdf("")
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner titulo={'Plano Empresarial'} texto={'Solução completa para sua empresa. Contando com diversos módulos integrados, suporte online e outras funcionalidades.'} textoBotao={'Entre em Contato!'} classe={'banner-empresarial'} origem={"Assunto: Assinatura Plano Empresarial"} nomeProduto={"Plano Empresarial"} />
            <Planos titulo={'O que é o Plano EMPRESARIAL?'} texto={'O Plano Empresarial é a oferta de nível superior da Exactus Intelligence, destinada a atender às necessidades complexas das empresas em crescimento. Este plano abrangente abarca recursos avançados, como escrita fiscal, folha de pagamento, contabilidade, E-Busca XML e Módulo CND, fornecendo uma solução completa para otimizar a eficiência e a conformidade contábil de organizações em ascensão.'} />
            <section className="plano-modulos">
                <h2>Módulos do Plano EMPRESARIAL</h2>
                <div className="container-modulos">
                    <img src={escritafiscal} alt="Imagem de escrita fiscal" />
                    <div className="content-modulos-direita">
                        <h3>Escrita Fiscal</h3>
                        <h4>Skill</h4>
                        <p>Escrita Fiscal Simplificada e Eficiente. Automatize obrigações fiscais, emissão de guias, controle de impostos e integração contábil. Ganhe tempo e evite erros.</p>
                        <div>
                            <NavLink to="/fiscal">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="container-modulos">
                    <div className="content-modulos-esquerda">
                        <h3>Folha de Pagamento</h3>
                        <h4>Winner</h4>
                        <p>Plataforma robusta simplifica e agiliza todas as etapas da Folha de Pagamento, proporcionando uma experiência completa para o mercado.</p>
                        <div>
                            <NavLink to="/folha-de-pagamento">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                    <img src={folha} alt="Imagem de folha de pagamento" />
                </div>
                <div className="container-modulos">
                    <img src={contabilidade} alt="Imagem de contabilidade" />
                    <div className="content-modulos-direita">
                        <h3>Contabilidade</h3>
                        <h4>Maxximum</h4>
                        <p>Sistema eficiente é mais do que apenas um software; é uma solução completa que otimiza todas as suas operações contábeis.</p>
                        <div>
                            <NavLink to="/contabil">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="container-modulos">
                    <div className="content-modulos-esquerda">
                        <h3>Patrimonial</h3>
                        <h4>Prattic</h4>
                        <p>O Seu Parceiro Eficiente para Controle Patrimonial.</p>
                        <div>
                            <NavLink to="/patrimonial">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                    <img src={patrimonial} alt="Imagem de patrimônio" />
                </div>
                <div className="container-beneficios">
                    <img src={beneficios} alt="Imagem de portal" />
                    <div className="content-modulos-direita">
                        <h3>Benefícios</h3>
                        <div className="beneficios-modulos">
                            <span>Porta do Cliente <img src={check} alt="Ícone de check" /></span>
                            <span>Portal do Colaborador <img src={check} alt="Ícone de check" /></span>
                            <span>e-Busca XML <img src={check} alt="Ícone de check" /></span>
                            <span>CND <img src={check} alt="Ícone de check" /></span>
                        </div>
                    </div>
                </div>
            </section>
            <Beneficios tituloBeneficio={'Benefícios do Plano EMPRESARIAL'} tituloServico={'Serviços Disponíveis'} qtdUsuarios={'Solicitar orçamento'} qtdEmpresas={'Solicitar orçamento'} qtdWeb={'Adicionado por usuário'} servicoSuporte={'Multicanais'} servicoTreinamento={'Multicanais'} textoBotao={'Contratar Plano EMPRESARIAL'} plano={'Plano Empresarial'} />
            <Footer />
        </>
    )
}

export default PlanoEmpresarial