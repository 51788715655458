import React, { useContext } from "react";
import './style.css'
import Botao from "../Botao/Botao";
import { EmailContext } from "../../context/EmailContext";
import { NavLink } from "react-router-dom";

function CardSolucoesCinza({ imagem, alt, titulo, texto, icone, nomePdf }) {
    const { setPdf } = useContext(EmailContext)
    const handlePdf = () => {
        setPdf(nomePdf)
    }
    return (
        <div className="card-empresas">
            <img src={imagem} alt={alt} className="bg-empresas" />
            <div className="content-card-empresas-cinza">
                <img src={icone} alt="Ícone" className="icon-empresas" />
                <div>
                    <h1>{titulo}</h1>
                    <p>{texto}</p>
                    <NavLink to="/contato">
                        <Botao texto={'Solicite o PDF aqui'} onClick={() => handlePdf()}/>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default CardSolucoesCinza