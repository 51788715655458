import { useContext } from "react";
import { EmailContext } from "../../context/EmailContext";
import Botao from "../Botao/Botao";
import { NavLink } from "react-router-dom";

function SectionSolucoes({ produto, nomeProduto, origem, link }) {
    const { setContabilidade, setOrigem } = useContext(EmailContext)
    const handleContabilidade = () => {
        setContabilidade(nomeProduto)
        setOrigem(origem)
    }
    return (
        <section className="razoes">
            <h2>{produto}</h2>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <iframe width="560" height="315" src={link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
                <NavLink to="/contato">
                    <Botao texto="Entre em contato aqui" onClick={() => handleContabilidade()} />
                </NavLink>
            </div>
        </section>
    )
}

export default SectionSolucoes