import React, { useContext, useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import PlanosImagem from "../../components/Planos/PlanosImagem";
import Footer from "../../components/Footer/Footer";
import './style.css'
import pessoas from '../../images/Planos/pessoas.svg'
import razoes from '../../images/Planos/razoes.svg'
import compatibilidade from '../../images/Eficatus/compatibilidade.svg'
import eficacia from '../../images/Eficatus/eficacia.svg'
import ia from '../../images/Eficatus/ia.svg'
import integracao from '../../images/Eficatus/integracao.svg'
import inteligencia from '../../images/Eficatus/inteligencia.svg'
import simples from '../../images/Eficatus/simpels.svg'
import tempo from '../../images/Eficatus/tempo.svg'
import { EmailContext } from "../../context/EmailContext";

function Eficatus() {
    const { setPdf } = useContext(EmailContext)
    useEffect(() => {
        setPdf('')
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner titulo={'e-Eficatus'} texto={'Automatize Sua Contabilidade e Escrita Fiscal com o e-Eficatus!'} classe={'banner-eficatus'} origem={'Assunto: e-Eficatus'} nomeProduto={'e-Eficatus'} />
            <PlanosImagem imagem={pessoas} titulo={'Você já se perguntou como poderia transformar a complexidade da contabilidade em uma oportunidade para ser um consultor excepcional? '} texto={'Apresentamos o e-Eficatus, a solução que vai revolucionar a maneira como você faz negócios!'} />
            <section className="razoes">
                <div className="elemento-centralizado">
                    <img src={razoes} alt="Ícone de razões" style={{ width: '80px', height: '80px' }} />
                </div>
                <h2>Automatize Sua Contabilidade e Escrita Fiscal com o e-Eficatus!</h2>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={integracao} alt="Ícone de integração" style={{ width: '32px', height: '32px' }} />
                        <h3>Integração Financeira Simplificada</h3>
                        <p>Imagine receber automaticamente todas as informações de seus clientes, diretamente do governo e dos bancos. Diga adeus à digitação manual e dê as boas-vindas à precisão e eficiência da automação!</p>
                    </div>
                    <div className="card-razoes">
                        <img src={tempo} alt="Ícone de tempo" style={{ width: '32px', height: '32px' }} />
                        <h3>Tempo Para Consultoria Estratégica</h3>
                        <p>Com o e-Eficatus ao seu lado, você terá mais tempo para se tornar o consultor que seus clientes precisam. Deixe a IA cuidar das tarefas rotineiras enquanto você se concentra em oferecer insights valiosos e orientação financeira inteligente.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={inteligencia} alt="Ícone de IA" style={{ width: '32px', height: '32px' }} />
                        <h3>Inteligência Artificial a Seu Favor</h3>
                        <p>Nossa tecnologia avançada utiliza a inteligência artificial para criar uma integração financeira perfeita entre você e seus clientes. Transforme a maneira como você lida com a contabilidade e abra as portas para a inovação.</p>
                    </div>
                </div>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={compatibilidade} alt="Ícone de compatibilidade" style={{ width: '32px', height: '32px' }} />
                        <h3>Compatibilidade Universal</h3>
                        <p>Do Excel aos sistemas de gestão financeira mais complexos, o e-Eficatus é compatível com todos.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={simples} alt="Ícone de simples" style={{ width: '32px', height: '32px' }} />
                        <h3>Simples e Descomplicado</h3>
                        <p>Seu cliente não precisa se preocupar com layouts específicos. Basta fornecer as informações necessárias para a integração.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={eficacia} alt="Ícone de eficácia" style={{ width: '32px', height: '32px' }} />
                        <h3>Eficácia Comprovada</h3>
                        <p>Reduza erros manuais a zero e otimize suas operações contábeis com a nossa solução.</p>
                    </div>
                </div>
            </section>
            <section className="eficatus">
                <div className="elemento-centralizado">
                    <img src={ia} alt="Ícone de inteligência artificial" style={{ width: '100px', height: '100px' }} />
                </div>
                <h2>Quer descobrir como a inteligência artificial pode revolucionar sua contabilidade?</h2>
                <p>Simplifique, otimize e transforme sua contabilidade com o e-Eficatus. Liberte seu potencial como consultor e embarque na era da colaboração inteligente. A contabilidade do futuro começa aqui. Venha conosco nessa jornada!</p>
            </section>
            <Footer />
        </>
    )
}

export default Eficatus