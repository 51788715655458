import React, { useContext } from "react";
import './style.css'
import { CompraContext } from "../../context/CompraContext";
import { MdErrorOutline } from 'react-icons/md'
import { EmailContext } from "../../context/EmailContext";

function Erro() {
    const { msgErro } = useContext(CompraContext)
    const { msgErroEmail } = useContext(EmailContext)
    return (
        <>
            {msgErro &&
                <div className="alert-erro"><p><MdErrorOutline style={{ fontSize: '20px', marginRight: '5px' }} />{msgErro}</p></div>
            }
            {msgErroEmail &&
                <div className="alert-erro"><p><MdErrorOutline style={{ fontSize: '20px', marginRight: '5px' }} />{msgErroEmail}</p></div>
            }
        </>
    )
}

export default Erro