import React from "react";

function CardDescricao({ descricao, proxPassos, docNecessarios }) {
  const descricaoComQuebras = descricao.split('\n').map((item, index) => (
    <React.Fragment key={index}>
      {item}
      <br />
    </React.Fragment>
  ));

  const proxPassosComQuebras = proxPassos.split('\n').map((item, index) => (
    <React.Fragment key={index}>
      {item}
      <br />
    </React.Fragment>
  ));

  const docNecessariosComQuebras = docNecessarios.split('\n').map((item, index) => (
    <React.Fragment key={index}>
      {item}
      <br />
    </React.Fragment>
  ));
  return (
    <div className="card-descricao">
      <h2>Descrição do Produto</h2>
      <p>{descricaoComQuebras}</p>
      <h2>Próximos Passos</h2>
      <p>{proxPassosComQuebras}</p>
      <h2>Documentos necessários</h2>
      <p>{docNecessariosComQuebras}</p>
    </div>
  )
}

export default CardDescricao