import React, { useContext } from "react";
import './style.css'
import { CompraContext } from "../../context/CompraContext";
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { EmailContext } from "../../context/EmailContext";

function Sucesso() {
    const { msgSucesso } = useContext(CompraContext)
    const { msgSucessoEmail } = useContext(EmailContext)
    return (
        <>
            {msgSucesso &&
                <div className="sucesso"><p><IoMdCheckmarkCircleOutline style={{ fontSize: '20px', marginRight: '5px' }} />{msgSucesso}</p></div>
            }
            {msgSucessoEmail &&
                <div className="sucesso"><p><IoMdCheckmarkCircleOutline style={{ fontSize: '20px', marginRight: '5px' }} />{msgSucessoEmail}</p></div>
            }
        </>
    )
}

export default Sucesso