import React, { useContext, useState, useEffect } from "react";
import './style.css'
import Botao from "../../components/Botao/Botao";
import CardDescricao from "../../components/Card/CardDescricao";
import Footer from '../../components/Footer/Footer'
import ModalPix from "../../components/Modal/ModalPix";
import Sucesso from "../../components/Alert/Sucesso";
import Erro from '../../components/Alert/Erro'
import Loading from "../../components/Loading/Loading";
import { NavLink, useNavigate } from "react-router-dom";
import cartao from '../../images/Compra/card.svg'
import pix from '../../images/Compra/pix.svg'
import { CompraContext } from "../../context/CompraContext";
import InputMask from "react-input-mask";
import { FiAlertTriangle } from 'react-icons/fi'
import Lottie from "lottie-react";
import json from '../../images/drjc2eHoKC.json'
import token from '../../images/Certificado/token.svg'
import file from '../../images/Certificado/file.svg'
import cloud from '../../images/Certificado/cloud.svg'
import cartaoleitora from '../../images/Certificado/cartaoleitora.svg'
import cartaoIcon from '../../images/Certificado/cartao.svg'

function Compra() {
    const { Nome, setNome, NumeroCartao, setNumeroCartao, DataValidade, setDataValidade, CodSeguranca, setCodSeguranca, GeraPix, abreModal, setAbreModal, encryptCardDetails, msgSucesso, msgErro, setMsgErro, loading, ProdutoCompra, ConsultaProdutos, ValidaCupom, Cupom, setCupom, Descricao, setDescricao, Desconto, setDesconto, setRevenda, VendaKey } = useContext(CompraContext)

    const navigate = useNavigate()

    const [card, setCard] = useState(false)
    const [cardPix, setCardPix] = useState(false)

    const abrirCard = () => {
        if (cardPix) {
            setCardPix(false)
        }
        setCard(!card)
    }

    const abrirCardPix = () => {
        if (card) {
            setCard(false)
        }
        setCardPix(!cardPix)
    }

    const pagarCartao = () => {
        if (typeof NumeroCartao === 'undefined' || NumeroCartao === '') {
            setMsgErro("Número do cartão obrigatório")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        if (typeof Nome === 'undefined' || Nome === '') {
            setMsgErro("Nome obrigatório")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        if (typeof DataValidade === 'undefined' || DataValidade === '') {
            setMsgErro("Data de validade obrigatório")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        if (typeof CodSeguranca === 'undefined' || CodSeguranca === '') {
            setMsgErro("CVV obrigatório")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        encryptCardDetails(Cupom, VendaKey)
            .then(() => {
                navigate('/compra-finalizada')
                setNumeroCartao('')
                setNome('')
                setDataValidade('')
                setCodSeguranca('')
                setCupom('')
                setDescricao('')
                setRevenda('')
            })
            .catch(error => {
                console.log(error);
            });
    }

    function verificaIcone(item) {
        if (item.resumo.includes('Cartão + Leitora')) {
            return cartaoleitora;
        } else if (item.resumo.includes('Cartão')) {
            return cartaoIcon;
        } else if (item.resumo.includes('Token')) {
            return token;
        } else if (item.resumo.includes('Arquivo')) {
            return file;
        } else if (item.resumo.includes('Nuvem')) {
            return cloud;
        } else {
            return 'Sem mídia';
        }
    }

    useEffect(() => {
        setCupom('')
        setDescricao('')
        setDesconto('')
        window.scrollTo(0, 0);
        if (ProdutoCompra) {
            ConsultaProdutos(ProdutoCompra.id, ProdutoCompra.anos[0]?.id);
        }
    }, []);

    return (
        <>
            {abreModal &&
                <ModalPix close={setAbreModal} />
            }
            {msgSucesso &&
                <Sucesso />
            }
            {msgErro &&
                <Erro />
            }
            {loading &&
                <Loading />
            }
            <section className="page-compra">
                <p style={{ textAlign: 'center', color: '#354056' }}>Dados Cadastrais {'>'} <b>Pagamento</b></p>
                {ProdutoCompra ?
                    <>
                        <h2>Você está comprando:</h2>
                        <div className="compra">
                            <div className="container-compra">
                                <div className="content-compra">
                                    <h2>{ProdutoCompra.descricaoResumida}</h2>
                                    <div className="elemento-centralizado">
                                        {verificaIcone(ProdutoCompra) === 'Sem mídia'
                                            ?
                                            <p style={{ fontSize: '20px', color: '#277C82', fontWeight: '700', margin: 0 }}>Sem mídia</p>
                                            :
                                            <img src={verificaIcone(ProdutoCompra)} alt="Ícone de token" style={{ margin: '30px 0' }} />
                                        }
                                    </div>
                                    <h3>{ProdutoCompra.resumo}</h3>
                                    <p>Você está comprando um produto com a validade de {ProdutoCompra.anos[0].descricao}</p>
                                    {Cupom ?
                                        <>
                                            <h4 style={{ textDecoration: 'line-through' }}>R$ {ProdutoCompra.anos[0].valor.toFixed(2)}</h4>
                                            <h4>R$ {(ProdutoCompra.anos[0].valor.toFixed(2) - ((ProdutoCompra.anos[0].valor.toFixed(2) * Desconto) / 100)).toFixed(2)}</h4>
                                        </>
                                        :
                                        <h4>R$ {ProdutoCompra.anos[0].valor.toFixed(2)}</h4>}

                                </div>
                            </div>
                            <div className="container-compra" style={{ width: '50%' }}>
                                <CardDescricao descricao={ProdutoCompra.descricaoCompleta} proxPassos={ProdutoCompra.proximoPasso} docNecessarios={ProdutoCompra.documentos} />
                            </div>
                            <div className="container-compra">
                                <div className="pagamento-compra" onClick={() => abrirCard()}>
                                    <h2>Pagamento com Cartão de Crédito</h2>
                                    <img src={cartao} alt="Ícone de cartão" />
                                </div>
                                {card &&
                                    <div className="pagamento-compra-dados">
                                        <div className="input">
                                            <label htmlFor="numerocartao">Número do Cartão *</label>
                                            <InputMask mask="9999 9999 9999 9999" onChange={(e) => setNumeroCartao(e.target.value)} value={NumeroCartao} name="numerocartao" id="numerocartao" placeholder="____ _____ _____ _____" />
                                        </div>
                                        <div className="input">
                                            <label htmlFor="nomecompleto">Nome Completo - Conforme impresso no cartão *</label>
                                            <input type="text" name="nomecompleto" id="nomecompleto" value={Nome} onChange={(e) => setNome(e.target.value)} />
                                        </div>
                                        <div className="input-compra">
                                            <div className="input">
                                                <label htmlFor="datavalidade">Data de validade *</label>
                                                <InputMask mask="99/9999" onChange={(e) => setDataValidade(e.target.value)} value={DataValidade} name="datavalidade" id="datavalidade" placeholder="__/____" />
                                            </div>
                                            <div className="input">
                                                <label htmlFor="cvv">CVV *</label>
                                                <InputMask mask="999" onChange={(e) => setCodSeguranca(e.target.value)} value={CodSeguranca} name="cvv" id="cvv" placeholder="___" />
                                            </div>
                                        </div>
                                        <div className="elemento-centralizado" style={{ margin: '15px 0' }}>
                                            <Botao texto={'Confirmar pagamento'} onClick={() => pagarCartao()} />
                                        </div>
                                    </div>
                                }
                                <div className="pagamento-compra" onClick={() => abrirCardPix()}>
                                    <h2>Pagamento com PIX</h2>
                                    <img src={pix} alt="Ícone de PIX" />
                                </div>
                                {cardPix &&
                                    <div className="pagamento-compra-dados">
                                        <p>Será gerado um QR Code de R$
                                            {Cupom ?
                                                <>{(ProdutoCompra.anos[0].valor.toFixed(2) - ((ProdutoCompra.anos[0].valor.toFixed(2) * Desconto) / 100)).toFixed(2)} </>
                                                :
                                                <>{ProdutoCompra.anos[0].valor.toFixed(2)} </>}
                                            válido por 1 minuto e 30 segundos para a realização do pagamento da compra do {ProdutoCompra.resumo}</p>
                                        <div className="elemento-centralizado" style={{ margin: '15px 0' }}>
                                            <Botao texto={'Confirmar pagamento'} onClick={() => GeraPix(Cupom, VendaKey)} />
                                        </div>
                                        {/* <p>Funcionalidade em breve...</p> */}
                                    </div>
                                }
                                <div className="pagamento-compra" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', cursor: 'default' }}>
                                    <h2>Cupom de desconto</h2>
                                    <div className="input" style={{ width: '100%', marginTop: '15px' }}>
                                        <label htmlFor="text">Insira seu cupom de desconto</label>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <input type="text" onChange={(e) => setDescricao(e.target.value)} value={Descricao} />
                                            <Botao texto={'Adicionar cupom'} onClick={() => ValidaCupom(Descricao)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : <>
                        <div className="elemento-centralizado">
                            <FiAlertTriangle className="icon-alert" />
                        </div>
                        <h2 style={{ textAlign: 'center', marginTop: '20px' }}>Nenhum produto selecionado</h2>
                        <div style={{ width: '300px', height: '300px', margin: '50px auto' }}>
                            <Lottie animationData={json} loop={true} />
                        </div>
                        <div className="elemento-centralizado">
                            <NavLink to="/certificado">
                                <Botao texto={"Comprar produtos"} />
                            </NavLink>
                        </div>
                    </>
                }
            </section>
            <Footer footerValid={true} certificado={true} />
        </>
    )
}

export default Compra