import React, { useContext, useEffect } from "react";
import Botao from "../../components/Botao/Botao";
import CardSolucoes from "../../components/Card/CardSolucoes";
import CardSolucoesCinza from "../../components/Card/CardSolucoesCinza";
import Footer from "../../components/Footer/Footer";
import './style.css'
import forcadevendas from '../../images/SolucoesEmpresas/forcadevendas.svg'
import forcadevendasimg from '../../images/SolucoesEmpresas/planejamento-tributario-comercio-atacadista-scaled 1.png'
import atacadista from '../../images/SolucoesEmpresas/atacadista.svg'
import atacadistaimg from '../../images/SolucoesEmpresas/planejamento-tributario-comercio-atacadista-scaled 1 (1).png'
import varejista from '../../images/SolucoesEmpresas/varejista.svg'
import varejistaimg from '../../images/SolucoesEmpresas/planejamento-tributario-comercio-atacadista-scaled 1 (2).png'
import shopping from '../../images/SolucoesEmpresas/shopping.svg'
import shoppingimg from '../../images/SolucoesEmpresas/planejamento-tributario-comercio-atacadista-scaled 1 (3).png'
import postocomb from '../../images/SolucoesEmpresas/postocomb.svg'
import postocombimg from '../../images/SolucoesEmpresas/planejamento-tributario-comercio-atacadista-scaled 1 (4).png'
import supermarket from '../../images/SolucoesEmpresas/supermarket.svg'
import supermarketimg from '../../images/SolucoesEmpresas/planejamento-tributario-comercio-atacadista-scaled 1 (5).png'
import agro from '../../images/SolucoesEmpresas/agro.svg'
import agroimg from '../../images/SolucoesEmpresas/planejamento-tributario-comercio-atacadista-scaled 1 (6).png'
import industria from '../../images/SolucoesEmpresas/industria.svg'
import industriaimg from '../../images/SolucoesEmpresas/ind_erp_img 1.png'
import alimentos from '../../images/SolucoesEmpresas/alimentos.svg'
import alimentostimg from '../../images/SolucoesEmpresas/planejamento-tributario-comercio-atacadista-scaled 1 (7).png'
import metalmecanica from '../../images/SolucoesEmpresas/metalmecanica.svg'
import metalmecanicaimg from '../../images/SolucoesEmpresas/planejamento-tributario-comercio-atacadista-scaled 1 (8).png'
import movelaria from '../../images/SolucoesEmpresas/movelaria.svg'
import movelariaimg from '../../images/SolucoesEmpresas/planejamento-tributario-comercio-atacadista-scaled 1 (9).png'
import construtora from '../../images/SolucoesEmpresas/construtora.svg'
import construtoraimg from '../../images/SolucoesEmpresas/ind_erp_img 1 (1).png'
import assist from '../../images/SolucoesEmpresas/assist.svg'
import assistimg from '../../images/SolucoesEmpresas/ind_erp_img 1 (2).png'
import oficina from '../../images/SolucoesEmpresas/oficina.svg'
import oficinaimg from '../../images/SolucoesEmpresas/ind_erp_img 1 (3).png'
import loteamento from '../../images/SolucoesEmpresas/loteamento.svg'
import loteamentoimg from '../../images/SolucoesEmpresas/planejamento-tributario-comercio-atacadista-scaled 1 (10).png'
import pontodigital from '../../images/SolucoesEmpresas/pontodigital.svg'
import pontodigitalimg from '../../images/SolucoesEmpresas/planejamento-tributario-comercio-atacadista-scaled 1 (12).png'
import transport from '../../images/SolucoesEmpresas/transport.svg'
import transportimg from '../../images/SolucoesEmpresas/planejamento-tributario-comercio-atacadista-scaled 1 (13).png'
import prestadora from '../../images/SolucoesEmpresas/prestadora.svg'
import prestadoraimg from '../../images/SolucoesEmpresas/planejamento-tributario-comercio-atacadista-scaled 1 (11).png'
import { EmailContext } from "../../context/EmailContext";
import { NavLink } from "react-router-dom";

function Solucoes() {
    const { setPdf, setContabilidade, setOrigem } = useContext(EmailContext)
    const handleContabilidade = () => {
        setContabilidade('Soluções para minha empresa')
        setOrigem('Soluções')
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        setPdf('')
    }, []);
    return (
        <>
            <div>
                <div className="banner-padding-top">
                    <section className="solucoes-banner">
                        <div className="solucoes-container">
                            <div className="content-solucoes">
                                <h1>Soluções Exactus para empresas</h1>
                                <h2>Da prateleira do supermercado ao chão de fábrica, a Exactus tem uma solução na medida certa!</h2>
                                <NavLink to="/contato">
                                    <Botao texto={'Entre em contato aqui'} onClick={() => handleContabilidade()}/>
                                </NavLink>
                            </div>
                        </div>
                    </section>
                </div>
                <section className="planos">
                    <h2 style={{ textAlign: 'center' }}>Sistemas de Gestão ERP completos e específicos para seu negócio</h2>
                    <p style={{ textAlign: 'center', lineHeight: '30px' }}>A Exactus possui diversos sistemas para Comércio, Indústria e Prestadoras de Serviços</p>
                </section>
                <section className="razoes">
                    <h2 style={{ textAlign: 'start', marginBottom: '20px' }}>Comércio</h2>
                    <hr />
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }} className="solucoes-container-cards">
                        <CardSolucoes imagem={forcadevendasimg} alt={'Imagem de força de vendas'} titulo={'Automação de Força de Vendas'} texto={'Aumente suas vendas com nosso sistema de automação de força de vendas!'} icone={forcadevendas} nomePdf={'Automação de Força de Vendas'} />
                        <CardSolucoes imagem={atacadistaimg} alt={'Imagem de comércio'} titulo={'Comércio Atacadista'} texto={'Uma gestão com sistema integrado de todas as operações'} icone={atacadista} nomePdf={'Comércio Atacadista'} />
                        <CardSolucoes imagem={varejistaimg} alt={'Imagem de comércio'} titulo={'Comércio Varejista'} texto={'Fidelização, otimização e competitividade da empresa seja física ou online'} icone={varejista} nomePdf={'Comércio Varejista'} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="solucoes-container-cards">
                        <CardSolucoes imagem={shoppingimg} alt={'Imagem de shopping'} titulo={'Lojas de Shopping'} texto={'Uma gestão com sistema integrado de todas as operações'} icone={shopping} nomePdf={'Lojas de Shopping'}/>
                        <CardSolucoes imagem={postocombimg} alt={'Imagem de Postos de Combustíveis'} titulo={'Postos de Combustíveis'} texto={'A completa solução para gestão de Postos de Combustíveis, e um completo suporte técnico, inclusive nos finais de semana e feriados.'} icone={postocomb} nomePdf={'Postos de Combustíveis'}/>
                        <CardSolucoes imagem={supermarketimg} alt={'Imagem de Supermercados'} titulo={'Supermercados'} texto={'Produtos confiáveis, seguros, funcionais com interface amigável.'} icone={supermarket} nomePdf={'Supermercados'}/>
                    </div>
                </section>
                <section className="planos">
                    <h2 style={{ marginBottom: '20px' }}>Indústria</h2>
                    <hr />
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }} className="solucoes-container-cards">
                        <CardSolucoesCinza imagem={agroimg} alt={'Imagem de Agro Business'} titulo={'Agro Business'} texto={'Soluções integradas para a informatização de empreendimentos do setor agropecuário.'} icone={agro} nomePdf={'Agro Business'}/>
                        <CardSolucoesCinza imagem={industriaimg} alt={'Imagem de Indústria'} titulo={'Indústrias (ERP, MRP I e II PCP)'} texto={'Gerencie todas as atividades do processo produtivo:  da engenharia de produtos até os produtos acabados!'} icone={industria} nomePdf={'Indústrias (ERP, MRP I e II PCP)'}/>
                        <CardSolucoesCinza imagem={alimentostimg} alt={'Imagem de Indústria de Alimentos'} titulo={'Indústria de Alimentos'} texto={'A Ferramenta que possibilita o controle dos processos de produção da matéria prima ao produto acabado.'} icone={alimentos} nomePdf={'Indústria de Alimentos'}/>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="solucoes-container-cards">
                        <CardSolucoesCinza imagem={metalmecanicaimg} alt={'Imagem de Indústrias Metal Mecânica'} titulo={'Indústrias Metal Mecânica'} texto={'Simplifique os controles do processo produtivo. Do planejamento ao controle da produção aumentando sua produtividade e reduzindo custos.'} icone={metalmecanica} nomePdf={'Indústrias Metal Mecânica'}/>
                        <CardSolucoesCinza imagem={movelariaimg} alt={'Imagem de Indústria de Móveis'} titulo={'Indústria de Móveis'} texto={'Otimize sua produção e entregas com nossa solução para a indústria moveleira, garantindo eficiência e satisfação do cliente.'} icone={movelaria} nomePdf={'Indústria de Móveis'}/>
                        <CardSolucoesCinza imagem={construtoraimg} alt={'Imagem de Construtoras'} titulo={'Construtoras'} texto={'Módulos integrados, destinados à automação de todas as atividades de uma incorporadora/construtora.'} icone={construtora} nomePdf={'Construtoras'}/>
                    </div>
                </section>
                <section className="razoes">
                    <h2 style={{ textAlign: 'start', marginBottom: '20px' }}>Serviços</h2>
                    <hr />
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }} className="solucoes-container-cards">
                        <CardSolucoes imagem={assistimg} alt={'Imagem de Assistência Técnica'} titulo={'Assistência Técnica'} texto={'Gerencie todas as atividades: Cadastros, contas a pagar e receber, orçamentos, cadastros de peças com inclusão de imagens.'} icone={assist} nomePdf={'Assistência Técnica'}/>
                        <CardSolucoes imagem={oficinaimg} alt={'Imagem de Oficinas Automotivas'} titulo={'Oficinas Automotivas'} texto={'Otimize seu tempo fazendo ordens de serviços, revisão programada, nfc-e, gestão de compras, vendas!'} icone={oficina} nomePdf={'Oficinas Automotivas'}/>
                        <CardSolucoes imagem={loteamentoimg} alt={'Imagem de Loteamento'} titulo={'Loteamento'} texto={'Sistema completo para loteamentos. Gerencie todas as atividades rotineiras em um lugar só!'} icone={loteamento} nomePdf={'Loteamento'}/>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="solucoes-container-cards">
                        <CardSolucoes imagem={prestadoraimg} alt={'Imagem de Prestadoras de Serviços'} titulo={'Prestadoras de Serviços'} texto={'Sistema confiável, seguro e funcional, além de possuir uma interface amigável!'} icone={prestadora} nomePdf={'Prestadoras de Serviços'}/>
                        <CardSolucoes imagem={pontodigitalimg} alt={'Imagem de Ponto Eletrônico'} titulo={'Ponto Eletrônico'} texto={'Soluções em tecnologia e confiabilidade em suas mãos. Otimize seus processos.'} icone={pontodigital} nomePdf={'Ponto Eletrônico'}/>
                        <CardSolucoes imagem={transportimg} alt={'Imagem de Transportadoras'} titulo={'Transportadoras'} texto={'Nosso sistema atende às mais diversas necessidades administrativas, operacionais e financeiras do setor de transporte rodoviário de cargas'} icone={transport} nomePdf={'Transportadoras'}/>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default Solucoes