import React, { useContext, useEffect } from "react";
import './style.css'
import Botao from "../../components/Botao/Botao";
import Card from "../../components/Card/Card";
import Banner from "../../components/Banner/Banner";
import Footer from "../../components/Footer/Footer";
import estudantes from '../../images/PlanosAssinatura/estudantes.svg'
import modulos from '../../images/PlanosAssinatura/modulos.svg'
import carreira from '../../images/PlanosAssinatura/carreira.svg'
import pequenasempresas from '../../images/PlanosAssinatura/pequenasempresas.svg'
import solucoes from '../../images/PlanosAssinatura/solucoes.svg'
import planoavancado from '../../images/PlanosAssinatura/planoavancado.svg'
import colaborador from '../../images/PlanosAssinatura/colaborador.svg'
import ebusca from '../../images/PlanosAssinatura/ebusca.svg'
import moduloss from '../../images/PlanosAssinatura/moduloss.svg'
import smartmodulos from '../../images/PlanosAssinatura/smartmodulos.svg'
import cnd from '../../images/PlanosAssinatura/cnd.svg'
import check from '../../images/PlanosAssinatura/check.svg'
import uncheck from '../../images/PlanosAssinatura/uncheck.png'
import acordo from '../../images/PlanosAssinatura/acordo.svg'
import faturamento from '../../images/PlanosAssinatura/faturamento.svg'
import { NavLink } from "react-router-dom";
import { EmailContext } from "../../context/EmailContext";

function PlanosAssinatura() {
    const { setOrigem, setContabilidade, setPdf } = useContext(EmailContext)
    useEffect(() => {
        setPdf("")
        window.scrollTo(0, 0);
    }, []);
    const contato = () => {
        setOrigem("Assunto: Assinatura")
        setContabilidade("Planos e Assinatura")
    }
    return (
        <div>
            <Banner titulo={'Planos e Assinatura'} texto={'Sistemas para Contabilidade na medida certa para o seu Negócio! Compare nossas soluções e entre em contato com nosso suporte!'} textoBotao={'Entre em Contato!'} classe={'banner-planos'} origem={"Assunto: Assinatura"} nomeProduto={"Planos e Assinatura"}/>
            <section className="planos planos-contabilidade">
                <h2>Exactus Intelligence: Suporte sob medida, desde a Academia até o Sucesso Empresarial</h2>
                <p>Na Exactus Intelligence, entendemos que cada jornada contábil é única. É por isso que oferecemos soluções sob medida para atender às necessidades específicas de nossos clientes. Seja você um estudante de contabilidade buscando aprimorar suas habilidades ou uma empresa em crescimento procurando serviços contábeis confiáveis, estamos aqui para acompanhá-lo em cada etapa do caminho. Descubra nossos planos abaixo e escolha a Exactus para orientar o seu sucesso.</p>
            </section>
            <section className="planos plano-academico">
                <h3>Nossos planos</h3>
                <h2>Plano ACADÊMICO</h2>
                <hr />
                <p>Nosso Plano Acadêmico é como um mentor virtual para futuros contadores! Desperte seu potencial com acesso a escrita fiscal, folha de pagamento e contabilidade de ponta. Comece sua jornada com um aliado confiável.</p>
                <div className="container-academico">
                    <Card imagem={estudantes} alt={'Ícone de estudante'} texto={'Ideal para Estudantes de Contabilidade.'} />
                    <Card imagem={modulos} alt={'Ícone de modulos'} texto={'Módulos: Contábil, Escrita e Folha.'} />
                    <Card imagem={carreira} alt={'Ícone de carreira'} texto={'Preparação completa para sua futura carreira de contador.'} />
                </div>
                <div className="elemento-centralizado">
                    <NavLink to="/plano-academico">
                        <Botao texto={'Saiba mais Aqui!'} />
                    </NavLink>
                </div>
            </section>
            <section className="planos plano-fit">
                <h2>Plano FIT</h2>
                <hr />
                <p>O Plano Fit oferece uma abordagem personalizada para pequenas empresas. Desfrute da eficiência da escrita fiscal, folha de pagamento, contabilidade e controle patrimonial, adaptados às suas necessidades. Uma solução contábil sob medida para o sucesso de seu negócio.</p>
                <div className="container-fit">
                    <Card imagem={pequenasempresas} alt={'Ícone de pequenas empresas'} texto={'Perfeito para pequenas empresas.'} />
                    <Card imagem={modulos} alt={'Ícone de módulos'} texto={'Módulos: Contábil, Escrita, Folha, Patrimonial e CDN.'} />
                    <Card imagem={solucoes} alt={'Ícone de solução'} texto={'Solução sob medida para suas necessidades contábeis.'} />
                </div>
                <div className="elemento-centralizado">
                    <NavLink to="/plano-fit">
                        <Botao texto={'Saiba mais Aqui!'} />
                    </NavLink>
                </div>
            </section>
            <section className="planos plano-plus">
                <h2>Plano PLUS</h2>
                <hr />
                <p>O Plano Plus proporciona excelência em serviços contábeis. Com escrita fiscal, folha de pagamento, contabilidade e controle patrimonial, além do Portal do Colaborador e Portal do Cliente, oferecemos uma solução completa para otimizar seus processos financeiros e aprimorar a relação com seus colaboradores e clientes.</p>
                <div className="container-plus">
                    <Card imagem={cnd} alt={'Ícone de CND'} texto={'Planos mais completos para o seu Negócio, com e-Busca XML e CND.'} />
                    <Card imagem={smartmodulos} alt={'Ícone de módulos inteligentes'} texto={'Módulos Inteligentes: Contábil, Escrita, Folha e Patrimonial.'} />
                    <Card imagem={colaborador} alt={'Ícone de colaborador'} texto={'Portal do Colaborador e Portal do Cliente para maior eficiência.'} />
                </div>
                <div className="elemento-centralizado">
                    <NavLink to="/plano-plus">
                        <Botao texto={'Saiba mais Aqui!'} />
                    </NavLink>
                </div>
            </section>
            <section className="planos plano-empresarial">
                <h2>Plano MAXX</h2>
                <hr />
                <p>O Plano Maxx é a escolha para empresas que buscam excelência contábil. Com recursos avançados, incluindo gestão de faturamento e honorários, módulo financeiro, tarefas e processos, E-Busca XML e Módulo CND, oferecemos uma solução completa para impulsionar sua eficiência e conformidade. Transforme sua contabilidade com o Plano Maxx.</p>
                <div className="container-plus">
                    <Card imagem={planoavancado} alt={'Ícone de homem'} texto={'Plano avançado com recursos poderosos.'} />
                    <Card imagem={modulos} alt={'Ícone de módulos'} texto={'Módulos: Escrita Fiscal, Folha de Pagamento, Contabilidade e Controle Patrimonial.'} />
                    <Card imagem={faturamento} alt={'Ícone de faturamento'} texto={'Gestão de Faturamento e Honorários, Módulo Financeiro, Módulo de Tarefas e Processos.'} />
                </div>
                <div className="container-maxx">
                    <Card imagem={ebusca} alt={'Ícone de e-busca'} texto={'E-Busca XML para facilitar a busca de notas fiscais.'} />
                    <Card imagem={moduloss} alt={'Ícone de módulos'} texto={'Módulo CND para maior conformidade.'} />
                </div>
                <div className="elemento-centralizado">
                    <NavLink to="/plano-maxx">
                        <Botao texto={'Saiba mais Aqui!'} />
                    </NavLink>
                </div>
            </section>
            <section className="planos plano-maxx">
            <h2>Plano EMPRESARIAL</h2>
                <hr />
                <p>Nosso Plano Empresarial é a escolha definitiva para empresas em busca de excelência contábil. Com recursos avançados, como escrita fiscal, folha de pagamento, contabilidade, E-Busca XML e Módulo CND, oferecemos uma solução completa para atender às demandas das empresas em crescimento. Eleve a eficiência e a conformidade da sua empresa com o Plano Empresarial.</p>
                <div className="container-empresarial">
                    <div className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={planoavancado} alt="Ícone de plano avançado" style={{width: '80px', height: '80px'}}/>
                        </div>
                        <h4>Plano avançado com recursos de ponta</h4>
                    </div>
                    <div className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={modulos} alt="Ícone de módulos" style={{width: '80px', height: '80px'}}/>
                        </div>
                        <h4>Módulos: Escrita Fiscal, Folha de Pagamento, Contabilidade e Controle Patrimonial.</h4>
                    </div>
                    <div className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={ebusca} alt="Ícone de e-busca" style={{width: '80px', height: '80px'}}/>
                        </div>
                        <h4>Portal do Colaborador e e-Busca XML.</h4>
                    </div>
                    <div className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={moduloss} alt="Ícone de módulos" style={{width: '80px', height: '80px'}}/>
                        </div>
                        <h4>Módulo CND para atender às necessidades de sua empresa em crescimento.</h4>
                    </div>
                </div>
                <div className="elemento-centralizado">
                    <NavLink to="/plano-empresarial">
                        <Botao texto={'Saiba mais Aqui!'} />
                    </NavLink>
                </div>
            </section>
            <section className="planos planos-comparativo">
                <div className="container-comparativo">
                    <h2>Comparativo de Planos:</h2>
                    <hr />
                    <table>
                        <thead>
                            <tr>
                                <th>Recursos</th>
                                <th>Plano Acadêmico</th>
                                <th>Plano Fit</th>
                                <th>Plano Plus</th>
                                <th>Plano Maxx</th>
                                <th>Plano Empresarial</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Escrita Fiscal</td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                            </tr>
                            <tr>
                                <td>Folha de Pagamento</td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                            </tr>
                            <tr>
                                <td>Contabilidade</td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                            </tr>
                            <tr>
                                <td>Controle Patrimonial</td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                            </tr>
                            <tr>
                                <td>Portal do Colaborador</td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                            </tr>
                            <tr>
                                <td>Portal do Cliente</td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                            </tr>
                            <tr>
                                <td>Gestão de Faturamento e Honorários</td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                            </tr>
                            <tr>
                                <td>Módulo Financeiro</td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                            </tr>
                            <tr>
                                <td>Módulo de Tarefas e Processos</td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                            </tr>
                            <tr>
                                <td>e-Busca XML</td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                                <td><img src={check} alt="Ícone de check" /></td>
                            </tr>
                            <tr>
                                <td style={{ border: 'none' }}>Módulo CND</td>
                                <td style={{ borderBottom: 'none' }}><img src={uncheck} alt="Ícone de uncheck" /></td>
                                <td style={{ borderBottom: 'none' }}><img src={check} alt="Ícone de check" /></td>
                                <td style={{ borderBottom: 'none' }}><img src={check} alt="Ícone de check" /></td>
                                <td style={{ borderBottom: 'none' }}><img src={check} alt="Ícone de check" /></td>
                                <td style={{ borderBottom: 'none' }}><img src={check} alt="Ícone de check" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
            <section className="planos planos-contato">
                <div className="elemento-centralizado">
                    <img src={acordo} alt="Ícone de acordo" style={{width: '100px', height: '74px'}}/>
                </div>
                <h2>Selecione o plano que mais se adequa às suas necessidades e permita que a Exactus Intelligence o acompanhe em sua trajetória em direção ao êxito financeiro e contábil.</h2>
                <p>Independentemente de ser um estudante determinado ou uma empresa em expansão, nossas soluções personalizadas estão prontas para oferecer recursos importantes para o seu Sucesso!</p>
                <div className="elemento-centralizado">
                    <NavLink to="/contato">
                        <Botao texto={'Fale com um consultor'} onClick={() => contato()}/>
                    </NavLink>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default PlanosAssinatura