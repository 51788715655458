import React, {useEffect} from "react";
import './style.css'
import Footer from "../../components/Footer/Footer";

function PoliticaPrivacidade() {
        useEffect(() => {
        window.scrollTo(0, 0);
    }, []);;
    return (
        <>
            <div className="politica">
                <h1>Política de Privacidade da Exactus</h1>
                <h2>Última Atualização: 07/2021</h2>
                <p>
                    A Exactus tem um compromisso com você, nosso cliente e/ou usuário, quanto à segurança e privacidade de seus dados. O respeito e o sigilo de suas informações é muito importante para nós. Nossa Política de Privacidade explica como a Exactus Software, Ltda (“Exactus”, “nós” ou “nosso”) e as suas empresas afiliadas e parceiras, que não possuam suas próprias políticas de privacidade, coletam, processam, usam, protegem e compartilham as informações pessoais que obtemos sobre você e sua empresa, de acordo com os seus direitos de privacidade sob as leis aplicáveis, incluindo a Lei Geral de Proteção de Dados do Brasil (Lei 13.709/2018), ao acessar nossos sites, aplicativos (incluindo nossos aplicativos móveis), produtos, softwares (On-Premise) ou serviços (SaaS) vinculados a nossa política (nossos “Serviços”).
                </p>
                <p>
                    <b>Nosso Compromisso de Privacidade</b>
                    <br />
                    O objetivo desta política é tornar explícitas as informações coletadas sobre nossos clientes e usuários, o modo como elas são usadas ou não, como e quando são processadas, compartilhadas e descartadas.
                    Infelizmente, nossa política é mais longa do que gostaríamos, e nela abordamos inequivocamente todos os casos relevantes a sua privacidade. Tentaremos manter uma linguagem simples e direta.
                </p>
                <p>
                    <b>Escopo da Política de Privacidade Exactus</b>
                    <br />
                    Esta Política de Privacidade aplica-se a todos os “Serviços” da Exactus vinculados a ela e não se aplica: a) a toda e qualquer “informação” que nosso cliente insere/carrega, processa, armazena ou hospeda usando nossos “Serviços”, sendo essas informações pessoais regidas pelas respectivas políticas de privacidade definidas pelos nossos clientes; b) a qualquer serviço oferecido por terceiros ou vinculado à própria declaração de privacidade, política de privacidade ou aviso de privacidade; c) a nenhum dos nossos sites, produtos ou serviços que tenham uma política de privacidade separada.
                    Ressaltamos que a LGPD (Lei Geral de Proteção de Dados) estabelece quem utiliza os dados em dois níveis de trabalho, o de operador e o de controlador. A responsabilidade de cada profissional é diferente, ou seja, nós atuamos com as responsabilidades de um “Controlador” em situações em que tomamos decisões sobre como as informações pessoais são usadas em nossos Serviços, de acordo com as leis aplicáveis em relação ao processamento de informações pessoais e, quando usamos apenas informações pessoais, conforme permitido por nossos clientes nós atuamos como “Operador”. Nossos clientes são igualmente responsáveis pelas obrigações de um “Controlador” de acordo com as leis aplicáveis com o processamento de informações pessoais e, caso você utilize nossos “Serviços” através de um de nossos clientes, entre em contato diretamente com eles se tiver dúvidas ou preocupações sobre o processamento de suas informações pessoais em conformidade com as leis aplicáveis.
                </p>
                <p>
                    <b>1 - INFORMAÇÕES QUE COLETAMOS E CONTROLAMOS</b>
                    <br />
                    <b>Quais Informações a EXACTUS coleta</b><br />
                    As informações pessoais coletadas dependem de como você está interagindo especificamente com nossos “Serviços”. Em regra, coletamos informações pessoais sobre você diretamente de você, de terceiros, como o seu empregador ou assinante que fornece acesso aos nossos Serviços, ou de outras fontes públicas disponíveis.
                </p>
                <p><b>As informações que você nos fornece</b></p>
                <p>Você nos fornece informações pessoais da seguinte forma, quando: a) pesquisa, assina ou adquire nossos Serviço; b) mantém um perfil ou conta conosco; c) compra, usa ou interage com conteúdo, produtos ou serviços de terceiros que mantêm um relacionamento com a Exactus; d) cria, publica ou envia conteúdo de usuário em nossos Serviços; e) registra-se ou participa de um de nossos eventos virtuais ou locais; f) solicita ou inscreve-se para obter informações; g) comunica-se conosco por telefone, e-mail, chat, pessoalmente ou de outra forma; h) preenche um questionário, pesquisa, ticket de suporte ou outro formulário de solicitação de informações; i) manifesta interesse em trabalhar ou se candidata a uma vaga de emprego conosco.
                    O tipo de informação que coletamos depende de como você interage conosco e, geralmente, coletamos as seguintes categorias:</p>
                <ul>
                    <li>Na contratação de Serviços: solicitamos informações como seu nome, número de contato, endereço de e-mail, nome da empresa para concluir o processo de contratação/ativação da conta.</li>
                    <li>Nas Informações de contato: como nome, endereço, número de telefone, dados do usuário em mídias sociais e endereço de e-mail.</li>
                    <li>Nos Registros em eventos e outros formulários enviados: nós registramos as informações que você envia quando você: a) se inscreve para qualquer evento, incluindo webinars ou seminários; b) se inscreve em nosso boletim informativo ou qualquer outra lista de correspondência; c) envia um formulário para fazer download de qualquer produto, artigo técnico ou outros materiais; d) participa de concursos ou responde pesquisas; ou e) envia um formulário para solicitar suporte ao cliente ou entrar em contato com a Exactus para qualquer outra finalidade.</li>
                    <li>Nos Depoimentos: quando você nos autoriza a publicar depoimentos sobre nossos produtos e serviços em sites, podemos incluir seu nome e outras informações pessoais no depoimento. Você terá a oportunidade de analisar e aprovar o depoimento antes da publicação. Se você quiser atualizar ou excluir seu depoimento, veja as instruções no item “Como você pode enviar uma solicitação ou exercer seus direitos”.</li>
                    <li>No Conteúdo do Usuário: como conteúdo de comunicações, sugestões, perguntas, comentários, feedback e outras informações que você nos envia, que nos fornece quando entra em contato ou que publica em nossos serviços (incluindo informações em alertas, pastas, notas, e compartilhamentos de conteúdo) e dados semelhantes.</li>
                    <li>Nas Interações com a Exactus: podemos gravar, analisar e usar suas interações conosco, incluindo conversas por e-mail, telefone e chat com nossos profissionais de vendas e suporte ao cliente, para melhorar nossas interações com você e outros clientes.</li>
                    <li>Nos Dados de localização: como a localização do seu dispositivo, sua residência e dados de localização semelhantes.</li>
                    <li>Nas Informações demográficas: como país, idioma preferencial, idade e data de nascimento, estado civil, sexo, características físicas, status e métricas financeiras pessoais ou domésticas/familiares, status militar e dados semelhantes.</li>
                    <li>Com Sua imagem: como fotos, vídeo, voz e outros dados semelhantes.</li>
                    <li>Nas Informações de identidade: como informações de identificação emitidas pelo governo, identificadores de impostos, números de seguro social, outros identificadores emitidos pelo governo e dados semelhantes.</li>
                    <li>Nas Informações financeiras: como endereço de cobrança, informações de cartão de crédito, detalhes de contato da cobrança e dados semelhantes.</li>
                    <li>Nas Informações relacionadas a carreira: educação e emprego, como preferências ou interesses de trabalho, desempenho e histórico de trabalho, histórico salarial, status de veterano, nacionalidade e status de imigração, dados demográficos, informações relacionadas a deficiências, informações sobre candidaturas, informações sobre licenciamento profissional e atividades de conformidade relacionada, credenciamentos e outros elogios, histórico educacional (incluindo escolas frequentadas, graus ou áreas acadêmicas de estudo, desempenho acadêmico e classificações) e dados semelhantes.</li>
                </ul>
                <p><b>Informações que coletamos automaticamente</b></p>
                <p>Nós coletamos informações pessoais automaticamente quando você: a) procura, visita, interage ou usa nossos Serviços, inclusive por meio de um dispositivo; b) acessa, usa ou descarrega conteúdo de nossos Serviços; c) abre e-mails ou clica em links em e-mails ou nossos anúncios; d) interage ou se comunica conosco em nossas contas em mídias sociais. <br />
                    Os tipos de informações que coletamos são de fontes públicas disponíveis, e geralmente elas são coletadas da seguinte forma:</p>
                    <ul>
                        <li>Disponibilizadas pelo navegador: como seu endereço de IP, tipo e idioma do navegador, tempo de acesso e endereços de Website de encaminhamento e, ainda, podem ser coletadas informações sobre páginas visualizadas em nossos sites e outras ações que você executar enquanto estiver nos visitando. Incluímos essas informações em nossos arquivos de registro para entender melhor os visitantes de nossos sites.
                            Relacionadas ao uso da tecnologia: em que poderemos usar estas tecnologias para determinar se você abriu um e-mail da Exactus ou clicou em um link contido em um de nossos e-mails. A coleta de informações desta forma nos permite coletar estatísticas sobre o uso e eficiência de nossos websites e nossos softwares.</li>
                        <li>De cookies primários e tecnologias de rastreamento: usamos cookies temporários e permanentes para identificar os usuários de nossos serviços e melhorar a experiência do usuário. Incorporamos identificadores exclusivos em nossos produtos para download com o objetivo de controlar o uso dos produtos. Também utilizamos cookies, beacons, tags, scripts e outras tecnologias semelhantes para identificar visitantes, rastrear a navegação no site, coletar informações demográficas sobre visitantes e usuários, entender a eficácia das campanhas de e-mail e a captação de visitantes e usuários rastreando suas atividades em nossos sites.</li>
                        <li>De registros de aplicativos e análise de dispositivos móveis: coletamos informações sobre seu uso de nossos produtos, serviços e aplicativos móveis a partir de registros de aplicativos e de ferramentas de análise de uso interno e usamos tais informações para compreender como o uso e as necessidades de sua empresa podem melhorar nossos produtos. Essas informações incluem cliques, rolagens, recursos acessados, tempo e frequência de acesso, erros gerados, dados de desempenho, armazenamento utilizado, configurações/definições do usuário, dispositivos usados para acesso e seus locais.</li>
                        <li>Específicas de dispositivos: como modelo de hardware, versão do sistema operacional, identificadores exclusivos de produtos e informações de rede móvel). Podemos coletar e armazenar informações (inclusive informações pessoais) localmente em seu dispositivo usando mecanismos como armazenamento no navegador da web (inclusive HTML 5) e caches de dados de aplicativo.</li>
                    </ul>
                    <p>
                    Informações que coletamos de terceiros <br />
                    Nos também coletamos informações pessoais de terceiros, quando: a) seu empregador e/ou outras pessoas com quem você mantém um relacionamento e que fornecem ou publicam informações pessoais relacionadas a você para uso em nossos Serviços; b) nossos clientes permitem o acesso aos nossos Serviços para você ou de outros quando criam, publicam ou enviam conteúdo de usuário em nossos Serviços que podem incluir suas informações pessoais; c) Organizações profissionais ou do setor e agências de certificação/licenciamento fornecem ou publicam informações pessoais relacionadas a você; e) terceiros e afiliadas que revendem ou se integram aos nossos Serviços; f) provedores de serviços e parceiros de negócios que trabalham conosco em relação aos nossos Serviços e que podemos utilizar para fornecer determinados conteúdos, produtos ou serviços ou aprimorar sua experiência; g) em campanhas de Marketing, geração de vendas e recrutamento de parceiros de negócios; h) agências de crédito e outras agências similares; i) órgãos governamentais e outros que divulgam ou publicam registros públicos sobre você; j) outras fontes públicas ou geralmente disponíveis, como sites de mídias sociais, sites públicos e online, bancos de dados abertos e dados em domínio público.
                </p>
                <p><b>Geralmente, os tipos de informações que coletamos de terceiros são do seu empregador, mas também existem outras categorias:</b></p>
                <ul>
                    <li>Inscrições usando provedores de serviços de autenticação federada: você pode efetuar login nos serviços Exactus usando provedores de serviços de autenticação federadas suportados pelos nossos Serviços, como LinkedIn, Microsoft e Google. Esses serviços autenticam sua identidade e proporcionam a você a opção de compartilhar certas informações pessoais conosco, como nome e endereço de e-mail.</li>
                    <li>Referências: se alguém fez referência de algum de nossos produtos ou serviços a você por meio de qualquer um de nossos programas de referência, essa pessoa pode ter nos fornecido seu nome, endereço de e-mail e outras informações pessoais. Você pode entrar em contato conosco para solicitar a remoção destas informações do nosso banco de dados. Se você nos fornecer informações sobre outra pessoa, ou se outra pessoa nos fornecer suas informações, usaremos essas informações apenas pelo motivo específico pelo qual elas nos foram fornecidas.</li>
                    <li>Informações de nossos parceiros de revenda e provedores de serviços: se você entrar em contato com qualquer um dos nossos parceiros de revenda ou, de outra forma, expressar seu interesse por qualquer um dos nossos produtos ou serviços a eles, o parceiro de revenda poderá passar seu nome, endereço de e-mail, nome da empresa e outras informações para a Exactus. Se você se registrar ou participar de um evento patrocinado pela Exactus, o organizador do evento poderá compartilhar suas informações conosco. A Exactus também poderá receber informações sobre você de sites de análise caso você comente sobre qualquer revisão dos nossos produtos e serviços e de outros fornecedores de serviços de terceiros com os quais estamos envolvidos para fazer o marketing de nossos produtos e serviços.</li>
                    <li>Informações de sites de mídia social e outras fontes disponíveis publicamente: quando você interagir ou engajar-se conosco em sites de mídias sociais, como Facebook, Twitter, Google+ e Instagram, por meio de publicações, comentários, perguntas e outras interações, poderemos coletar tais informações publicamente disponíveis, incluindo informações de perfil, para que possamos entrar em contato com você, melhorar nossos produtos ou entender melhor as reações e problemas do usuário. Devemos dizer que uma vez coletadas, essas informações poderão permanecer conosco, mesmo se você excluí-las dos sites de mídia social. A Exactus também poderá adicionar e atualizar informações sobre você a partir de outras fontes disponíveis publicamente.</li>
                </ul>
                <p><b>Processamento e uso de informações pessoais</b></p>
                <p>Com base na coleta mencionada acima, suas informações pessoais são processadas com nossos Serviços para fins comerciais e legais, incluindo:</p>
                    <ul>
                        <li>Para se comunicar com você em relação aos nossos serviços ou outras notificações, programas, eventos ou atualizações nas quais você possa ter se registrado;</li>
                        <li>Fornecer, entregar, analisar e administrar nossos serviços, inclusive em conexão com a execução de um contrato;</li>
                        <li>Para fins internos de pesquisa e desenvolvimento de novos conteúdos, produtos e serviços, e para melhorar, testar e aprimorar os recursos e funções de nossos serviços atuais;</li>
                        <li>Processar transações relacionadas aos nossos serviços e administrar contas ou perfis relacionados a você ou à sua organização, incluindo registros, assinaturas, compras, eventos de cobrança, cálculos de royalties e pagamentos;</li>
                        <li>Personalizar e fazer recomendações relacionadas aos nossos serviços e outras ofertas;</li>
                        <li>Enviar ofertas, publicidade e materiais de marketing, inclusive para fornecer publicidade personalizada para você;</li>
                        <li>Para recrutamento e administração de recursos humanos;</li>
                        <li>Cumprir suas instruções ou outros fins especificados para os quais você deu seu consentimento;</li>
                        <li>Oferecer suporte ao cliente, analisar e aprimorar nossas interações com clientes;</li>
                        <li>Monitoras e evitar transações fraudulentas e outras atividades ilegais, relatar spam e para proteger os direitos e interesses da Exactus, dos nossos clientes e usuários, de terceiros e do público em geral;</li>
                        <li>Detectar e impedir fraude e abuso para garantir a segurança e a proteção de todos os clientes e outros, além de identificar e autenticar o seu acesso em nosso Serviço ou para identificar e autenticar você antes de fornecermos determinadas informações;</li>
                        <li>Exercitar nossos direitos e proteger nossos e outros direitos e/ou propriedade, inclusive para tomar medidas contra aqueles que buscam violar ou abusar de nossos serviços;</li>
                        <li>Cumprir a lei e nossas obrigações legais, inclusive para responder a uma solicitação ou pedido de um Tribunal, Órgão Regulador ou autoridade, bem como cumprir nossas obrigações contratuais com nossos clientes quando eles organizarem acesso em nossos serviços para você;</li>
                        <li>Efetuar venda, fusão, aquisição ou outra alienação de nossos negócios, inclusive em conexão com qualquer falência ou processo semelhante.</li>
                    </ul>
                <p><b>Bases legais para coleta e uso de suas informações</b></p>
                <p>Bases legais de processamento aplicáveis à Exactus: A maioria de nossas atividades de coleta e processamento de informações é tipicamente baseada em: a) necessidade contratual; bi) um ou mais interesses legítimos da Exactus ou de terceiros que não sejam sobrepostos por seus interesses de proteção de dados; c) seu consentimento para processar suas informações pessoais a fins especificados; ou d) cumprir a lei, onde às vezes, podemos ser legalmente obrigados a coletar suas informações ou precisar de suas informações pessoais para proteger seus interesses vitais ou de outra pessoa.
                    Cancelamento de consentimento: quando confiamos no seu consentimento como base legal, você tem o direito de retirar seu consentimento a qualquer momento, mas isso não afetará nenhum processamento que já tenha ocorrido.<br />
                    Aviso de interesses legítimos: ao confiarmos em interesses legítimos como base legal e se esses interesses legítimos não estiverem especificados acima, explicaremos claramente a você quais são esses interesses no momento em que coletarmos suas informações para seu consentimento.</p>
                <p><b>Sua escolha no uso de informações</b></p>
                <p>Desativação das comunicações eletrônicas não essenciais: você pode cancelar o recebimento de boletins informativos e outras mensagens não essenciais usando a função “cancelar inscrição” incluída em todas essas mensagens. Entretanto, você continuará a receber notificações e e-mails essenciais sobre transações.
                    Desativação de cookies: você pode desativar os cookies do navegador antes de visitar nossos sites. No entanto, se você fizer isso, talvez não seja possível usar certos recursos dos sites de modo adequado.
                    Informações opcionais: você pode optar por não fornecer informações opcionais de perfil, como sua foto. Pode também excluir ou alterar suas informações opcionais de perfil e, sempre, pode optar por não preencher campos não obrigatórios quando enviar qualquer formulário vinculado aos nossos Serviços.</p>
                <p><b>Com quem suas informações são compartilhadas</b></p>
                <p>Todas as entidades do Grupo Exactus têm acesso às informações mencionadas nesta Política de Privacidade. Compartilhamos suas informações apenas das formas descritas nesta Política de privacidade e somente com as partes que adotam medidas adequadas de confidencialidade e segurança.</p>
                <p>Grupo Exactus Software: Podemos compartilhar suas informações pessoais entre as empresas do grupo de empresas Exactus Software.</p>
                <p>Sua organização e contatos: Podemos compartilhar suas informações pessoais com a sua organização e outras pessoas com quem você mantém um relacionamento para cumprir ou executar um contrato ou outra obrigação legal, inclusive com o cliente que organiza o acesso aos nossos Serviços e nos paga em relação ao seu acesso. Também poderemos compartilhar suas informações pessoais com seus contatos se você estiver na mesma organização ou para facilitar a troca de informações entre você e os contato.</p>
                <p>Fornecedores de serviços terceirizados: pode ser necessário o compartilhamento de suas informações pessoais e informações agregadas ou não identificadas com provedores de serviços terceirizados, como parceiros de marketing e publicidade, organizadores de eventos, provedores de análise da Web e processadores de pagamento. Esses prestadores de serviço são autorizados a usar suas informações pessoais apenas conforme necessário para prestar esses serviços a nós.</p>
                <p>Parceiros de revenda: podemos compartilhar suas informações pessoais com nossos parceiros de revenda autorizados em sua região, unicamente com o propósito de entrar em contato com você sobre produtos que você baixou ou serviços nos quais você se inscreveu. Daremos a você a opção de não continuar a trabalhar com esse parceiro.</p>
                <p>Provedores de serviços de terceiros: Podemos compartilhar suas informações pessoais com provedores de serviços de terceiros para executar tarefas em nosso nome e para nos ajudar a oferecer, fornecer, entregar, analisar, administrar, melhorar e personalizar nossos Serviços. Por exemplo, prestadores de serviços que nos auxiliam na execução, fornecimento ou aprimoramento de determinados produtos e serviços relacionados à entrega e operação de nossos Serviços, que fornecem suporte técnico e/ou ao cliente em nosso nome, que fornecem desenvolvimento de aplicativos ou software e garantia de qualidade, que fornecem funções de rastreamento e relatórios, pesquisas sobre dados demográficos, interesses e comportamento dos usuários e outros produtos ou serviços. Esses provedores de serviços de terceiros também podem coletar informações pessoais sobre você ou sobre você na execução de seus serviços e/ou funções em nossos Serviços. Também podemos encaminhar determinadas solicitações suas ou de sua organização a esses provedores de serviços de terceiros.</p>
                <p>
                    Anunciantes: Podemos compartilhar suas informações pessoais com anunciantes, trocas de publicidade e agências de marketing que contratamos para serviços de publicidade, anunciar alguns de nossos Serviços e nos ajudar a anunciar a nossa marca, produtos e serviços. Esses serviços de publicidade também podem direcionar anúncios em sites de terceiros com base em cookies ou outras informações indicando interação anterior conosco e/ou com nossos Serviços.</p>
                <p>Usuários: Podemos compartilhar suas informações pessoais com outros usuários que são seus contatos, se você estiver na mesma organização ou para facilitar a troca de informações entre você e os contatos. Além disso, se você optar por incluir suas informações pessoais em quaisquer avaliações, comentários ou outras postagens que criar, essas informações pessoais poderão ser exibidas a outros usuários como parte de sua postagem.</p>
                <p>Em caso de venda, fusão ou mudança de controle: Podemos transferir esta Política de Privacidade e suas informações pessoais para uma entidade de terceiros que adquira, ou seja, incorporada como parte de uma fusão, aquisição, venda ou outra mudança de controle.</p>
                <p>Outras divulgações: Podemos divulgar suas informações pessoais a terceiros para cumprir qualquer lei ou regulamento aplicável, para cumprir ou responder a um processo legal ou aplicação da lei ou solicitação governamental, para impor nossos termos e condições ou outros direitos, incluindo investigações sobre possíveis violações de nossos direitos, para detectar, impedir ou resolver problemas de fraude ou segurança ou para proteger contra danos aos direitos, propriedade ou segurança da Exactus. Clientes, usuários e pode público.</p>
                <p>Colaboradores e prestadores de serviço independentes: os colaboradores e prestadores de serviço independentes de todas as entidades do grupo Exactus têm acesso às informações mencionadas nesta Política de Privacidade com base na necessidade de conhecimento. Exigimos que todos os colaboradores e prestadores de serviço independentes das entidades do grupo Exactus sigam esta Política de privacidade para tratar de informações pessoais que compartilhamos com eles.</p>
                <p>Desenvolvedores de aplicativos de mercado: quando você instalar ou comprar qualquer aplicativo desenvolvido usando APIs da Exactus, que tenha sido publicado pela Exactus, seu nome e endereço de e-mail serão compartilhados com o desenvolvedor do aplicativo para que ele possa entrar em contato diretamente com você como provedor do aplicativo ou serviço. A Exactus não controla o uso de suas informações pessoais pelos desenvolvedores, o que será baseado nas políticas de privacidade específicas de cada desenvolvedor.</p>
                <p>Reiteramos que NÃO vendemos nenhuma informação pessoal.</p>
                <p>Seus direitos em relação as informações que mantemos como controlador
                    Esses direitos diferem conforme a base legal ou consentimento previstos nas leis locais de proteção de dados, mas eles podem incluir uma ou mais das seguintes possibilidades:</p>
                <p>Direito de Acesso a informações pessoais: você pode ter o direito de obter a nossa confirmação de que processamos suas informações pessoais e, nesse caso, pode ter o direito de solicitar acesso às suas informações pessoais. Observe que, até a extensão permitida por lei, podemos cobrar uma taxa razoável com base nos custos administrativos pela primeira ou demais cópias das informações pessoais solicitadas por você.</p>
                <p>Direito de Retificação: você pode ter o direito de atualizar as informações, ou solicitar que retifiquemos informações pessoais imprecisas a seu respeito e, dependendo dos propósitos do processamento, pode ter o direito de que informações pessoais incompletas sejam complementadas.</p>
                <p>Direito de Eliminação de dados: você pode ter o direito de exigir que apaguemos algumas ou todas as informações pessoais a seu respeito em determinadas circunstâncias, como quando elas não são mais necessárias ao propósito pelo qual foram coletadas e armazenadas.</p>
                <p>Direito de Restrição de processamento: você também pode ter o direito de exigir que restrinjam o uso de suas informações pessoais em determinadas circunstâncias. Nesses casos suas informações só poderão ser processadas por nós para determinados fins.</p>
                <p>Direito de Portabilidade de dados: você pode ter o direito de transferir cópia das suas informações pessoais para terceiros em um formato estruturado.</p>
                <p>Direito de Objeção: você pode ter o direito de se opor ao uso de suas informações pessoais por nós, em determinadas circunstâncias e por motivos relacionados à uma situação específica.</p>
                <p>Direito de Revogação: você pode ter o direito de revogar o consentimento que nos forneceu, onde confiamos apenas no seu consentimento para processar suas informações pessoais. Você poderá fornecer novamente o seu consentimento a qualquer tempo.</p>
                <p>Direito de Reclamar: você pode ter o direito de reclamar à autoridade de supervisão aplicável ou outro Órgão Regulador se tiver alguma queixa contra a maneira como coletamos, usamos, mantemos, compartilhamos e descartamos suas informações, ou se não estiver satisfeito com nossas respostas às suas solicitações. Recomendamos fazer o contato conosco para abordarmos suas preocupações diretamente.</p>
                <p><b>Retenção de informações</b></p>
                <p>Podemos reter suas informações pessoais enquanto for necessário para os fins descritos nesta Política de privacidade. Por vezes, reteremos as informações pessoais de acordo com nossa necessidade de retenção de registros empresariais, que pode variar de acordo com o Serviço, função comercial, classes de registro e tipos de registro.</p>
                <p>Nos reservamos o direito de manter informações pessoais pelos períodos em que elas são necessárias para: (a) cumprir os propósitos descritos nesta Política de Privacidade, (b) cumprir os prazos determinados ou recomendados por Órgãos Reguladores, organismos profissionais ou associações; (c) cumprir as leis aplicáveis, detenções legais e outras obrigações legais e contratuais); e (d) atender às suas solicitações.</p>
                <p>Quando não existir mais a necessidade legítima de manter e processar suas informações, excluiremos ou anonimizaremos suas informações em nossos bancos de dados ativos. Também armazenaremos suas informações com segurança e as isolaremos de processamentos adicionais em discos de backup até que a exclusão seja possível.</p>
                <p><b>2 - INFORMAÇÕES QUE PROCESSAMOS EM SEU NOME</b></p>
                <p><b>Informações confiadas à Exactus e finalidade</b></p>
                <p>Informações fornecidas em conexão com os serviços: você pode confiar à Exactus as informações controladas por você ou por sua organização (“você”) em conexão com o uso de nossos serviços ou para solicitar suporte técnico para nossos produtos. Isso inclui informações sobre seus clientes e colaboradores, quando você for um controlador, ou dados que você mantém e usa em nome de outra pessoa para um fim específico, como um cliente para o qual você fornece serviços, quando você for um operador. Os dados podem ser armazenados em nossos servidores quando você usa nossos serviços na modalidade SaaS ou armazenados em seus servidores (ou de terceiros contratados por você) quando na modalidade On-Premise. Esses dados pode ainda ser transferidos e compartilhados como parte de uma solicitação de suporte técnico ou de outros serviços.</p>
                <p>Informações de dispositivos móveis: quando permitido por você, alguns dos nossos aplicativos móveis têm acesso à câmera, ao histórico de chamadas, às informações de contato, à biblioteca de fotos e a outras informações armazenadas em seu dispositivo móvel. Nossos aplicativos exigem esse acesso para fornecer seus serviços. Da mesma forma, quando você opta por fornecer acesso, as informações baseadas em localização também são coletadas para fins que incluem, entre outros, a localização de contatos próximos ou a configuração de lembretes baseados em localização. Essas informações serão compartilhadas exclusivamente com nossos fornecedores de mapeamento e serão usadas apenas para mapeamento de usuários locais. A qualquer momento, você pode desativar o acesso dos aplicativos móveis a essas informações editando as configurações do seu dispositivo móvel. Os dados armazenados em seu dispositivo móvel e suas informações de localização às quais os aplicativos móveis têm acesso serão usados no contexto do aplicativo móvel e transferidos e associados à sua conta nos serviços (em alguns casos os dados serão armazenados em nossos servidores) ou produtos (nesse caso, os dados permanecerão com você, a menos que você os compartilhe) correspondentes.</p>
                <p>Todas as informações confiadas à Exactus são coletivamente denominadas “dados de serviço”.</p>
                <p><b>Propriedade e controle de seus dados de serviço</b></p>
                <p>Reconhecemos que você é proprietário dos seus dados de serviço. Fornecemos a você o controle completo de seus dados de serviço proporcionando-lhe a capacidade de: a) acessar seus dados de serviço; b) compartilhar seus dados de serviço por meio de integrações de terceiros suportadas; e c) solicitar exportação ou exclusão de seus dados de serviço.</p>
                <p><b>Como usamos os dados de serviço</b></p>
                <p>Processamos seus dados de serviço quando você nos fornece instruções através de vários módulos de nossos serviços. Por exemplo, quando você importar um arquivo com dados referente a documentos fiscais, emite uma guia, emite uma nota fiscal ou gera uma fatura, as informações como o nome e endereço de seu cliente serão usadas para gerar essa fatura; e quando você usa nosso serviço de envio de e-mail, os endereços de e-mail das pessoas em sua base de contato serão usados para enviar os e-mails.</p>
                <p><b>Notificações por push</b></p>
                <p>Se você tiver ativado a notificação em nossos aplicativos para computador e dispositivos móveis, enviaremos notificações por meio de um provedor de notificações por push, como o Serviço de Notificação por Push da Apple, o Google Cloud Messaging ou o Serviços de Notificação por Push do Windows. Você pode gerenciar suas preferências de notificação por push ou desativar essas notificações cancelando as notificações no aplicativo ou nas configurações do dispositivo.</p>
                <p><b>Com quem compartilhamos dados de serviço</b></p>
                <p>Grupo Exactus e operadores terceirizados: a fim de fornecer serviços e suporte técnico de nossos serviços, a entidade contratante do grupo Exactus envolve outras entidades do grupo e terceiros.</p>
                <p>Colaboradores e prestadores de serviço independentes: podemos fornecer acesso aos seus dados de serviço para nossos colaboradores e indivíduos independentes que são contratados pelas entidades do grupo Exactus envolvidos no fornecimento dos serviços (coletivamente nossos “colaboradores”) para que eles possam identificar, analisar e resolver dúvidas e/ou inconformidades em nossos Serviços. Garantimos que o acesso de nossos colaboradores aos dados de serviço é registrado, auditado sempre que necessários e restrito a indivíduos específicos. Nossos colaboradores também terão acesso a dados que você compartilhar conscientemente conosco para suporte técnico ou para importar dados para nossos produtos ou serviços. Comunicamos, fortemente, nossas diretrizes de privacidade e segurança aos nossos colaboradores e parceiros, e aplicamos rigorosamente as proteções de privacidade dentro do grupo Exactus.</p>
                <p>Seus colaboradores e Outros usuários: alguns de nossos Serviços permitem que você colabore com outros usuários ou terceiros. Iniciar a colaboração pode permitir que outros colaboradores visualizem algumas ou todas as suas informações de perfil. Por exemplo, quando você edita um documento que compartilhou com outras pessoas para colaboração, seu nome e sua foto de perfil serão exibidos ao lado de suas edições para permitir que seus colaboradores saibam que você fez essas edições.</p>
                <p>Integrações de terceiros habilitadas por você: a maioria dos nossos Serviços tem suporte à integrações com produtos e serviços de terceiros. Se você utiliza as integrações de terceiros, poderá permitir que terceiros acessem suas informações de serviço e informações pessoais. Recomendamos que você revise as práticas de privacidade dos serviços e produtos de terceiros antes de utilizar as integrações com eles.</p>
                <p>Outros cenários: em que podemos compartilhar as informações que são comuns às informações mencionadas nas Partes 1 e 2 são descritos na Parte 3.</p>
                <p><b>Retenção de dados de serviço</b></p>
                <p>Mantemos seus dados armazenados desde que você escolha usar um de nossos Serviços. Assim que você encerrar sua conta de usuário ou cancelar todos seus contratos, seus dados serão excluídos do banco de dados ativo na próxima limpeza, que ocorre uma vez a cada 2 meses. Os dados excluídos do banco de dados ativo serão excluídos dos backups após 2 meses.</p>
                <p><b>Solicitações do titular dos dados</b></p>
                <p>Se você acredita que armazenamos, usamos ou processamos suas informações em nome de um de nossos clientes, entre em contato com o cliente se quiser acessar, corrigir, apagar, restringir ou recusar o processamento, ou exportar seus dados pessoais. Nessa situação, estenderemos nosso suporte ao cliente para atender à sua solicitação dentro de um prazo razoável.</p>
                <p><b>3 - INFORMAÇÕES GERAIS</b></p>
                <p><b>Privacidade de crianças e menores de idade</b></p>
                <p>Geralmente, nossos serviços fornecem soluções de informação e, como tal, são destinados a profissionais e/ou indivíduos maiores de dezesseis (16) anos de idade. Não visamos crianças e não coletamos intencionalmente nenhuma informação pessoal de crianças. Quando um usuário divulga informações pessoais de crianças em nossos serviços, ele está automaticamente confirmando que ela possui pelo menos dezesseis (16) anos de idade ou esta atendendo alguma exigência legal, como por exemplo, informações de dependentes no contrato de trabalho ou declarações de IR.</p>
                <p><b>Confidencialidade e Segurança das Informações</b></p>
                <p>A segurança das informações pessoais é importante para nós. Considerando a natureza, o escopo, o contexto e os objetivos do processamento de informações pessoais, bem como os riscos para indivíduos em relação a confidencialidade dos dados, inserimos em nosso dia a dia o uso de boas práticas e implementamos medidas técnicas e organizacionais desenvolvidas para proteger a segurança de informações pessoais.</p>
                <p>Nossos Serviços (SaaS) possuem base de dados armazenada em servidores situados em território nacional e internacional, e sempre que transferirmos informações pessoais para outras jurisdições, garantiremos que elas sejam transferidas de acordo com esta Política de Privacidade e conforme permitido pelas leis de proteção dados aplicáveis.</p>
                <p>No entanto, observe que nenhum método de transmissão pela Internet ou método de armazenamento eletrônico é 100% seguro. Portanto, embora nos esforcemos para usar medidas comercialmente aceitáveis projetadas para proteger informações pessoais, não podemos garantir sua absoluta segurança.</p>
                <p>Nossos procedimentos de segurança também significam que podemos solicitar, sempre que oportuno, prova de identidade antes de divulgarmos suas informações pessoais ou antes de processarmos suas solicitações.
                    Como protegemos suas informações</p>
                <p>Tomamos medidas para implementar proteções administrativas, técnicas e físicas apropriadas para impedir o acesso, uso, modificação, divulgação ou destruição não autorizados das informações que você nos confia. Se você tiver alguma dúvida sobre a segurança de seus dados, recomendamos que você verifique nossa Política de segurança ou entre em contato para apresentar sua dúvida.</p>
                <p>A Exactus adota medidas de proteção técnicas, organizacionais e de seguranças adequadas, inclusive de segurança física de informações, para proteger os seus dados pessoais contra uso incorreto, interferência e perda, bem como acesso não autorizado, modificação ou divulgação, conforme exigido por lei. As formas como fazemos isso incluem:</p>
                <ul>
                    <li>Uso de criptografia ao coletar ou transferir informações confidenciais;</li>
                    <li>Definição de nossas proteções de segurança de dados para garantir a segurança, integridade, disponibilidade e resiliência contínuas dos sistemas e serviços de processamento;</li>
                    <li>Limitação do acesso físico às nossas instalações e de terceiros.</li>
                    <li>Limitação do acesso às informações que coletamos sobre você.</li>
                    <li>Verificação e monitoramento de que nossos parceiros de negócios tenham medidas de segurança técnica e organizacional apropriadas para manter suas informações pessoais protegidas.</li>
                    <li>Destruição ou anonimização de informações pessoais, quando exigido por lei.</li>
                    <li>Encorajamos a preservação e a confidencialidade de quaisquer senhas que você usa em nossos Serviços e a ter cuidado para evitar golpes de “phishing” onde alguém pode enviar-lhe um e-mail que parece ser da Exactus pedindo suas informações pessoais. Nós não solicitamos seu login ou senha por e-mail.</li>
                </ul>
                <p><b>Escolhas de marketing</b></p>
                <p>Nos fornecemos publicidade e marketing através de várias plataformas, como em nossos serviços ou por e-mail, telefone, mensagens de texto, mala direta e vários canais online. Nossos serviços e materiais de marketing também podem incluir publicidade de terceiros e links para outros sites e aplicativos. Parceiros de publicidade de terceiros podem coletar informações sobre você ao interagir com conteúdo, publicidade ou serviços.</p>
                <p>Você pode controlar cookies e ferramentas de rastreamento. Para saber como gerenciar como nós e nossos parceiros usamos os cookies e outras ferramentas de rastreamento, acesse a nossa Política de Cookies.</p>
                <p>Sobre como a Exactus entra em contato com você para apresentar ofertas de marketing e sobre outros usos de suas informações, você pode clicar em “cancelar a inscrição” em qualquer comunicação de e-mail marketing que enviamos para você.</p>
                <p>Por favor, esteja ciente de que pode levar até 10 dias úteis para que suas preferências de e-mail entrem em vigor.</p>
                <p><b>Responsável pela proteção de dados</b></p>
                <p>Nomeamos um Responsável pela proteção de dados para supervisionar nosso gerenciamento de suas informações pessoais de acordo com esta Política de privacidade. Se você tiver dúvidas ou preocupações sobre nossas práticas de privacidade com relação às suas informações pessoais, você poderá entrar em contato com nosso responsável pela proteção de dados - consulte o item “Quem somos e como entrar em contato conosco”.
                    Aditivo sobre o contrato.</p>
                <p>Para permitir que você esteja em conformidade com as obrigações de proteção de dados sob a LGPD (Lei Geral de Proteção de Dados), estamos abertos para assinar um Aditivo de Contrato, baseado em Cláusulas Contratuais Padrão. Basta solicitar o aditivo para uma de nossas Centrais de Atendimento e/ou Agentes comerciais. Assim que recebermos sua solicitação, encaminharemos o aditivo para colher sua assinatura online.</p>
                <p><b>Links externos em nossos sites</b></p>
                <p>Alguns de nossos Serviços podem conter links para sites que não estão vinculados a esta Política de privacidade. Se você enviar suas informações pessoais para qualquer um desses serviços de terceiros, suas informações pessoais serão controladas através das políticas de privacidade desses serviços.</p>
                <p>Como medida de segurança, recomendamos que você não compartilhe informações pessoais com terceiros, a menos que tenha verificado suas políticas de privacidade e tenha certeza de suas práticas de privacidade.</p>
                <p><b>Blogs e fóruns</b></p>
                <p>Oferecemos blogs e fóruns acessíveis publicamente em nossos sites. Esteja ciente de que qualquer informação que você fornecer nesses blogs e fóruns pode ser usada para contatá-lo por meio de mensagens não solicitadas. Recomendamos que você seja cauteloso na divulgação de informações pessoais em nossos blogs e fóruns.</p>
                <p>A Exactus não se responsabiliza por informações pessoais que você decidir divulgar publicamente. Suas publicações e determinadas informações do perfil poderão permanecer públicas mesmo após o encerramento de sua conta. Para solicitar a remoção de suas informações de nossos blogs e fóruns, você pode entrar em contato conosco - consulte o item “Quem somos e como entrar em contato conosco”.</p>
                <p><b>Mídias sociais</b></p>
                <p>Nossos Serviços incluem widgets de mídia social, como os botões “Curtir” do Facebook e “Tweetar” do Twitter, que permitem compartilhar artigos e outras informações. Esses widgets podem coletar seu endereço IP, quais páginas você está visitando em nosso site e podem configurar um cookie para permitir que os widgets funcionem adequadamente. Suas interações com esses widgets são regidas pelas políticas de privacidade das empresas que os fornecem.</p>
                <p><b>Divulgações em conformidade com obrigações legais</b></p>
                <p>Podemos ser obrigados por lei a preservar ou divulgar suas informações pessoais e dados de serviço para cumprir qualquer lei, regulamento, processo legal ou solicitação governamental aplicável, inclusive para atender aos requisitos de segurança nacional.</p>
                <p><b>Aplicação de nossos direitos</b></p>
                <p>Podemos divulgar informações pessoais e dados de serviços a terceiros para prevenir fraudes, investigar qualquer atividade ilegal suspeita, fazer cumprir nossos contratos ou políticas, ou proteger a segurança de nossos clientes, usuários e parceiros comerciais.
                    Transferências de negócios.<br />
                    No caso de venda, aquisição ou fusão de empresas do Grupo Exactus, será garantido que a entidade adquirente seja legalmente obrigada a honrar nossos compromissos com você. Vamos notificá-lo via e-mail ou através de um aviso em destaque no nosso site ou, ainda, por correspondência via Correios, sobre qualquer alteração na propriedade ou nos usos de suas informações pessoais e dados de serviço. Também notificaremos você sobre quaisquer ações que possam ocorrer em relação às suas informações pessoais e dados de serviço.</p>
                <p><b>Conformidade com esta Política de privacidade</b></p>
                <p>Nós faremos todos os esforços possíveis, incluindo análises periódicas, para assegurar que as informações pessoais fornecidas por você sejam usadas em conformidade com esta Política de privacidade. Se você tiver alguma dúvida sobre o nosso cumprimento desta Política de privacidade ou sobre a maneira com que suas informações pessoais são usadas, entre em contato conosco - consulte o item “Quem somos e como entrar em contato conosco”. Assim que formos contatados, entraremos em contato com você e, se necessário, coordenaremos com as autoridades reguladoras apropriadas para tratar de suas preocupações de maneira eficaz.</p>
                <p><b>Como você pode enviar uma solicitação ou exercer seus direitos</b></p>
                <p>Para fazer uma solicitação ou exercer seus direitos, fale conosco através dos canais divulgados nesta política de privacidade.
                    Ao fazer uma solicitação ou exercer seus direitos, ajude-nos com as seguintes informações: a) diga-nos que direito você está exercendo; b) detalhe mais sobre seu relacionamento com agente; c) ajude-nos a verificar sua identidade; d) como deseja receber nossa resposta.</p>
                <p><b>Quem somos e como entrar em contato conosco</b></p>
                <p>A Exactus Software, Ltda. é uma empresa de tecnologia sediada na Avenida Rio Branco, 444 - Sobreloja - Londrina/PR, com filiais em São Paulo e Curitiba.<br />
                    Quaisquer comentários, preocupações, reclamações ou perguntas sobre a nossa Política de Privacidade e Proteção de Dados podem ser encaminhados para:</p>
                <ul>
                    <li>Nosso telefone +55 43 3372 7000 em horário comercial das 09:00 as 17:00</li>
                    <li>Por e-mail envie para:</li>
                    <ul>
                        <li>Política de Privacidade - juridico@exactus.com.br</li>
                        <li>Proteção de Dados - dpo@exactus.com.br</li>
                    </ul>
                    <li>Opcionalmente, escreva para:</li>
                </ul>
                <p>Privacidade Nacional da Exactus Software <br />
                    A/C Dr. Roberto Carlos Bueno<br />
                    Departamento Jurídico<br />
                    Exactus Software, Ltda<br />
                    Avenida Rio Branco, 444<br />
                    Sobreloja, Londrina/PR<br />
                    CEP 86.025-595</p>
                <p><b>Proteção de Dados da Exactus Software</b> <br />
                    A/C Fábio Leandro Mazzo Dutra<br />
                    Departamento de Tecnologia<br />
                    Exactus Software, Ltda<br />
                    Avenida Rio Branco, 444<br />
                    Sobreloja, Londrina/PR<br />
                    CEP 86.025-595<br />
                    Como já mencionado, se você estiver usando nossos Serviços por meio de um de nossos clientes, entre em contato diretamente com eles sobre quaisquer questões ou perguntas relacionadas a privacidade.
                </p>
                <p><b>Alterações nesta Declaração de Privacidade</b></p>
                <p>Como tudo em uma organização muda, espera-se que esta Política de Privacidade também mude. Então, a Exactus reserva-se o direito de poder alterar a presente política a qualquer momento, por qualquer motivo e sem aviso prévio, dado que a presente política de privacidade não cria nenhum vínculo contratual entre a Exactus, seus clientes e usuário ou terceiros. Sempre que possível, enviaremos notificações e lembretes por e-mail acerca de nossos avisos, termos e condições, mas é fundamental que você verifique com frequência em nossos Serviços a nossa Política de Privacidade em vigor para se atualizar sobre quaisquer alterações que possam ter sido realizadas.</p>
            </div>
            <Footer />
        </>
    )
}

export default PoliticaPrivacidade