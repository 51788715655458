import React, { useEffect, useContext } from "react";
import BannerTop from '../../components/Banner/BannerTop'
import SectionSolucoes from "../../components/Section/SectionSolucoes";
import Footer from "../../components/Footer/Footer";
import { EmailContext } from "../../context/EmailContext";
import { NavLink } from "react-router-dom";
import Botao from "../../components/Botao/Botao";
import Accordion from "../../components/Accordion/AccordionFolha";
import folhabanner from '../../images/Solucoes/folha.png'
import folhapagamento from '../../images/Planos/folhapagamento.svg'
import calculos from '../../images/Planos/calculos.svg'
import gestao from '../../images/Planos/gestao.svg'
import relatoriospersonalizados from '../../images/Planos/relatoriospersonalizados.svg'
import robos from '../../images/Planos/robos.svg'
import remuneracao from '../../images/Planos/remuneracao.svg'
import gerenciamento from '../../images/Planos/gerenciamento.svg'
import esocial from '../../images/Planos/esocial.svg'
import tempo from '../../images/Planos/tempo.svg'
import banco from '../../images/Planos/banco.svg'
import fiscal from '../../images/Planos/fiscal.svg'
import patrimonial from '../../images/Planos/patrimonial.svg'
import nuvem from '../../images/Home/nuvem.svg'
import contabil from '../../images/Planos/contabil.svg'

function Folha() {
    const { setPdf } = useContext(EmailContext)
    const { setContabilidade, setOrigem } = useContext(EmailContext)
    const handleContabilidade = () => {
        setContabilidade('Folha')
        setOrigem('folhadepagamento')
    }
    useEffect(() => {
        setPdf('')
        window.scrollTo(0, 0);
    }, []);;
    return (
        <>
            <BannerTop imagem={folhabanner} icone={folhapagamento} titulo={'FOLHA DE PAGAMENTO'} subtitulo={'O seu parceiro eficiente em Folha de Pagamento!'} texto={'Nossa plataforma robusta simplifica e agiliza todas as etapas da Folha de Pagamento, proporcionando uma experiência completa para o mercado.'} origem={'folhadepagamento'} nomeProduto={'Folha'} />
            <SectionSolucoes produto={'Saiba mais sobre o Módulo Folha de Pagamento'} link={'https://www.youtube.com/embed/EJMCh4digOM?si=5_vWYBFGLEYey3BQ'} origem={'folhadepagamento'} nomeProduto={'Folha'}/>
            <section className="planos">
                <h2 style={{ textAlign: 'center', marginBottom: '50px' }}>Benefícios da Plataforma Exactus</h2>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={calculos} alt="Ícone de cálculos" style={{ width: '32px', height: '32px' }} />
                        <h3>Cálculos Precisos e Flexíveis</h3>
                        <p>Otimize sua folha de pagamento com cálculos personalizados para adiantamentos, salários, férias e muito mais. O Winner oferece flexibilidade total para se adaptar à sua realidade.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={gestao} alt="Ícone de gestão" style={{ width: '32px', height: '32px' }} />
                        <h3>Gestão de Férias Simplificada</h3>
                        <p>Gerencie férias individuais, coletivas, programadas e diferenças de forma eficiente, economizando tempo e garantindo precisão em todo o processo.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={esocial} alt="Ícone de eSocial" style={{ width: '32px', height: '32px' }} />
                        <h3>eSocial</h3>
                        <p>Além de gerar todas as informações exigidas pelo eSocial, possui um mecanismo completo de controle das informações, alertando o usuário sobre todas as ações necessárias.</p>
                    </div>
                </div>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={relatoriospersonalizados} alt="Ícone de relatórios personalizados" style={{ width: '32px', height: '32px' }} />
                        <h3>Relatórios Personalizados</h3>
                        <p>Crie relatórios detalhados em diversos formatos, desde página cheia até etiquetas, para analisar movimentações e tomar decisões embasadas.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={gerenciamento} alt="Ícone de gerenciamento" style={{ width: '32px', height: '32px' }} />
                        <h3>Gerenciamento Eficiente de Benefícios</h3>
                        <p>Administre com facilidade benefícios como vale-transporte, alimentação/refeição e plano privado de assistência à saúde, simplificando a vida de seus colaboradores.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={tempo} alt="Ícone de tempo" style={{ width: '32px', height: '32px' }} />
                        <h3>Controle de Tempo e Ponto Simplificado</h3>
                        <p>Integre informações do ponto eletrônico, otimizando o controle de horas trabalhadas e garantindo precisão nos cálculos salariais.</p>
                    </div>
                </div>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={robos} alt="Ícone de robôs" style={{ width: '32px', height: '32px' }} />
                        <h3>Robôs Inteligentes</h3>
                        <p>Através do AGIUS, o robô Exactus que automatiza rotinas manuais através de agendamentos, você pode agendar processos noturnos, que são executados como serviço, e analisar os resultados no dia seguinte. Ou seja, sua contabilidade 24 horas.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={remuneracao} alt="Ícone de remuneração" style={{ width: '32px', height: '32px' }} />
                        <h3>Gestão de Remuneração e Carreira</h3>
                        <p>Controle plano de cargos e salários, ajustes monetários, participação nos lucros e alterações salariais de maneira organizada e eficaz.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={banco} alt="Ícone de banco" style={{ width: '32px', height: '32px' }} />
                        <h3>Suporte e Integração com Bancos</h3>
                        <p>Mantenha-se no controle com o gerenciamento de cadastro, emissão de informações bancárias e arquivos de retorno de crédito consignado, simplificando a relação com instituições financeiras.</p>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <NavLink to="/contato">
                        <Botao texto="Entre em contato aqui" onClick={() => handleContabilidade()} />
                    </NavLink>
                </div>
            </section>
            <Accordion />
            <section className="planos">
                <h2>Conheça outro produtos para Contabilidade:</h2>
                <div className="container-empresarial">
                    <a href="/fiscal" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={fiscal} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Gestão Fiscal</h4>
                    </a>
                    <a href="/contabil" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={contabil} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Gestão Contábil</h4>
                    </a>
                    <a href="/patrimonial" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={patrimonial} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Gestão Patrimonial</h4>
                    </a>
                    <a href="/compliance-fiscal" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Compliance Fiscal</h4>
                    </a>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Folha