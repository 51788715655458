import React, { useContext, useEffect } from "react";
import BannerImagem from "../../components/Banner/BannerImagem";
import PlanosImagem from "../../components/Planos/PlanosImagem";
import Footer from "../../components/Footer/Footer";
import './style.css'
import methodus from '../../images/Planos/methodusicon.svg'
import homem from '../../images/Planos/homem.svg'
import razoes from '../../images/Planos/razoes.svg'
import canalunico from '../../images/Methodus/canalunico.svg'
import controle from '../../images/Methodus/controle.svg'
import acesso from '../../images/Methodus/acesso.svg'
import app from '../../images/Methodus/app.svg'
import gestaofinanceira from '../../images/Methodus/gestaofinanceira.svg'
import inadimplencia from '../../images/Methodus/inadimplencia.svg'
import notas from '../../images/Methodus/notas.svg'
import maistempo from '../../images/Methodus/maistempo.svg'
import marca from '../../images/Methodus/marca.svg'
import decisoes from '../../images/Methodus/decisoes.svg'
import pix from '../../images/Methodus/pix.svg'
import pagamentos from '../../images/Methodus/pagamentos.svg'
import check from '../../images/Methodus/methodusicon2.svg'
import agilize from '../../images/Methodus/agilize.svg'
import { EmailContext } from "../../context/EmailContext";

function Methodus() {
    const { setPdf } = useContext(EmailContext)
    useEffect(() => {
        setPdf('')
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <BannerImagem titulo={'Methodus'} imagem={methodus} texto={'O verdadeiro ERP das Empresas de Contabilidade. Controla todos os processos inerentes aos serviços prestados, proporcionando eficácia e redução da relação custo/benefício.'} classe={'banner-methodus'} origem={'Assunto: Methodus'} nomeProduto={'Methodus'} />
            <PlanosImagem imagem={homem} titulo={'Imagine um cenário onde sua contabilidade está sobrecarregada...'} texto={'Diversas solicitações e atendimentos dos clientes dispersos por diversos canais. O retrabalho é constante, os custos estão aumentando e a agilidade operacional está comprometida. É hora de uma transformação.'} />
            <section className="razoes">
                <div className="elemento-centralizado">
                    <img src={razoes} alt="Ícone de razões" style={{ width: '80px', height: '80px' }} />
                </div>
                <h2>Apresentamos o Methodus: Sua Solução Integrada de Gestão Empresarial</h2>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={canalunico} alt="Ícone de suporte" style={{ width: '32px', height: '32px' }} />
                        <h3>Canal Único para Clientes</h3>
                        <p>Atenda e resolva solicitações em um só lugar, eliminando confusões e retrabalho. Fortalecendo a relação entre escritório e seus clientes.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={controle} alt="Ícone de controle" style={{ width: '32px', height: '32px' }} />
                        <h3>Controle Eficiente de Processos</h3>
                        <p>Padronize atividades, estabeleça a padronização de tarefas, prazos e responsáveis visando uma gestão contábil eficiente e estruturada.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={acesso} alt="Ícone de acesso" style={{ width: '32px', height: '32px' }} />
                        <h3>Acesso Simples e Seguro</h3>
                        <p>Ofereça um portal para colaboradores realizarem consultas e downloads.</p>
                    </div>
                </div>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={app} alt="Ícone de App" style={{ width: '32px', height: '32px' }} />
                        <h3>Solicitações por App</h3>
                        <p>As solicitações do colaborador são enviadas por meio de aplicativo.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={gestaofinanceira} alt="Ícone de gestão financeira" style={{ width: '32px', height: '32px' }} />
                        <h3>Gestão Financeira em Tempo Real</h3>
                        <p>Gerencie contas, fluxo de caixa e transações bancárias com facilidade e precisão.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={pagamentos} alt="Ícone de pagamentos" style={{ width: '32px', height: '32px' }} />
                        <h3>Gere Pagamentos Facilitados</h3>
                        <p>Envie boletos e controle recebimentos de forma integrada, com total suporte bancário.</p>
                    </div>
                </div>
            </section>
            <section className="methodus">
                <div className="elemento-centralizado">
                    <img src={check} alt="Ícone de check" style={{ width: '80px', height: '80px' }} />
                </div>
                <h2>Para todo problema uma solução. Para seus problemas, nós temos o METHODUS!</h2>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={inadimplencia} alt="Ícone de inadimplência" style={{ width: '32px', height: '32px' }} />
                        <h3>Evite Inadimplências</h3>
                        <p>Mantenha os clientes informados sobre datas de vencimento, ao mesmo tempo em que aprimora a estratégia de cobrança para otimização dos resultados.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={notas} alt="Ícone de nota fiscal" style={{ width: '32px', height: '32px' }} />
                        <h3>Nota Fiscal sem Complicações</h3>
                        <p>Configure tributações e emita NFS-e sem esforço, atendendo regulamentações.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={maistempo} alt="Ícone de tempo" style={{ width: '32px', height: '32px' }} />
                        <h3>Mais Tempo para Estratégias</h3>
                        <p>Automatize processos internos e entregas, focando no crescimento de sua contabilidade.</p>
                    </div>
                </div>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={marca} alt="Ícone de marca" style={{ width: '32px', height: '32px' }} />
                        <h3>Marca Presente em Tudo</h3>
                        <p>Ofereça uma experiência única com o Methodus White Label com sua marca.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={decisoes} alt="Ícone de decisões" style={{ width: '32px', height: '32px' }} />
                        <h3>Decisões Baseadas em Dados</h3>
                        <p>Organize informações para planejamento estratégico e sucesso nos negócios.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={pix} alt="Ícone de PIX" style={{ width: '32px', height: '32px' }} />
                        <h3>Modernize Suas Cobranças</h3>
                        <p>Integre o PIX para cobranças ágeis e práticas.</p>
                    </div>
                </div>
            </section>
            <section className="razoes">
                <div className="container-agilize">
                    <h2>Agilize Compras e Controle com uma Gestão eficiente de Estoque</h2>
                    <div className="elemento-centralizado">
                        <img src={agilize} alt="Ícone de caixa" style={{ width: '80px', height: '80px' }} />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Methodus