import React, { useEffect } from "react";
import Banner from '../../../components/Banner/Banner'
import Botao from "../../../components/Botao/Botao";
import Footer from "../../../components/Footer/Footer";
import check from '../../../images/ERP/check (3).svg'
import SectionCertificado from "../../../components/Section/SectionCertificado";
import { NavLink } from 'react-router-dom'

function CertificadoSaibaMaisIcp() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner titulo={'Exactus Certify+'} texto={'A Exactus tem o certificado certo para você! Adquira ou renove seu Certificado com a Exactus Certify+'} classe={'banner-certify'} origem={"Certificado Digital"} nomeProduto={"Certificado Digital"}/>
            <section className="razoes">
                <h2 style={{ textAlign: 'start', margin: '0 0 30px 0' }}>O que é um Certificado de Conectividade Social ICP?</h2>
                <p>O Certificado de Conectividade Social ICP é uma versão eletrônica que segue padrões da Infraestrutura de Chaves Públicas Brasileira (ICP-Brasil) para a autenticação segura de dados e informações relacionadas ao FGTS e outras obrigações trabalhistas.</p>
            </section>
            <section className="planos">
                <h2 style={{ margin: '0 0 30px 0' }}>Vantagens do Certificado de Conectividade Social ICP:</h2>
                <div className="certificado-itens">
                    <ul>
                        <li><img src={check} alt='Ícone de check' /><b>Agilidade nas Transmissões de Informações:</b></li>
                        <li>Transmita informações sociais de forma ágil e segura, cumprindo obrigações trabalhistas de maneira eficiente.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Acesso a Serviços Online da Caixa Econômica Federal:</b></li>
                        <li>Simplifique a interação com a Caixa Econômica Federal, acessando serviços online de forma segura, como o FGTS.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Segurança nas Transações com Órgãos Públicos:</b></li>
                        <li>Realize transações com órgãos públicos, como a Receita Federal, de maneira segura, utilizando seu Certificado ICP.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Envio de Documentos Trabalhistas de Forma Eletrônica:</b></li>
                        <li>Agilize o envio de documentos trabalhistas, como CAGED e RAIS, de forma eletrônica, evitando burocracias e reduzindo custos.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Assinatura Digital de Documentos Trabalhistas:</b></li>
                        <li>Assine digitalmente documentos relacionados a questões trabalhistas, conferindo validade jurídica e segurança às transações.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Proteção contra Fraudes e Acessos Não Autorizados:</b></li>
                        <li>Reforce a segurança nas operações relacionadas às obrigações trabalhistas, protegendo informações sensíveis da sua empresa.</li>
                    </ul>
                </div>
            </section>
            <SectionCertificado />
            <section className="planos">
                <h2 style={{textAlign: 'center'}}>Adquirir meu Certificado de Conectividade Social ICP:</h2>
                <div className="elemento-centralizado">
                    <NavLink to="/certificado">
                        <Botao texto={'Adquira aqui seu Certificado Digital'} />
                    </NavLink>
                </div>
            </section>
            <Footer footerValid={true} />
        </>
    )
}

export default CertificadoSaibaMaisIcp