import React, { useContext, useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import PlanosImagem from '../../components/Planos/PlanosImagem'
import Footer from "../../components/Footer/Footer";
import descritivo from '../../images/Five/descritivo.svg'
import beneficios from '../../images/Five/beneficios.svg'
import gestaofinanceira from '../../images/Five/gestaofinanceira.svg'
import estoque from '../../images/Five/estoque.svg'
import producao from '../../images/Five/producao.svg'
import ordemdeservico from '../../images/Five/ordemdeservico.svg'
import gestaodepessoas from '../../images/Five/gestaodepessoas.svg'
import entrega from '../../images/Five/entrega.svg'
import televendas from '../../images/Five/televendas.svg'
import frota from '../../images/Five/frota.svg'
import caixa from '../../images/Five/caixa.svg'
import cobranca from '../../images/Five/cobranca.svg'
import cta from '../../images/Five/cta.svg'
import { EmailContext } from "../../context/EmailContext";

function Five() {
    const { setPdf } = useContext(EmailContext)
    useEffect(() => {
        setPdf("")
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner titulo={'Exactus Five'} texto={'A gestão da sua empresa em um sistema completo e personalizável!'} classe={'banner-busca'} origem={'Assunto: Exactus Five'} nomeProduto={'Exactus Five'} />
            <PlanosImagem imagem={descritivo} titulo={'Tenha seus resultados com análises precisas e integrações avançadas. Experimente o Exactus Five.'} texto={''} />
            <section className="razoes">
                <div className="elemento-centralizado">
                    <img src={beneficios} alt="Ícone de benefícios" style={{ width: '100px', height: '100px' }} />
                </div>
                <h2>Por que utilizar o Exactus Five?</h2>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={gestaofinanceira} alt="Ícone de Gestão Financeira" style={{ width: '60px', height: '60px' }} />
                        <h3>Gestão Financeira</h3>
                        <p>Muito além de contas a pagar e a receber, aqui nós entregamos uma análise gerencial da sua empresa através de diversos relatórios e gráficos destinados a administrar os recursos de sua empresa.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={estoque} alt="Ícone de estoque" style={{ width: '60px', height: '60px' }} />
                        <h3>Controle de Estoque</h3>
                        <p>Como você gerencia seu estoque? Nós entregamos análises precisas para controle de estoque, auxiliando nas demandas de compra e venda, tornando-o mais seguro e prático.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={producao} alt="Ícone de Produção" style={{ width: '60px', height: '60px' }} />
                        <h3>Controle de Produção</h3>
                        <p>Para você que produz seus produtos, nosso sistema conta com um completo MRP/PCP para auxiliar na execução correta das atividades, sucesso nas entregas, maior controle dos pedidos e uma maior eficiência.</p>
                    </div>
                </div>
            </section>
            <section className="planos">
                <div className="busca">
                    <div className="content-busca">
                        <div style={{ width: '70%' }}>
                            <h2>Ordem de Serviço</h2>
                            <p>Possuímos um sistema de O.S personalizável à sua atividade, que permite acompanhar o total andamento das suas prestações de serviços, desde orçar os serviços e peças, até a emissão de NFSe após concluído.</p>
                        </div>
                        <img src={ordemdeservico} alt="Ícone de ordem de serviço" style={{ width: '100px', height: '100px' }} />
                    </div>
                    <div className="content-busca">
                        <img src={gestaodepessoas} alt="Ícone de pessoas" style={{ width: '100px', height: '100px' }} />
                        <div style={{ width: '70%' }}>
                            <h2>Gestão de Pessoas</h2>
                            <p>Um módulo que facilita o controle geral dos colaboradores, como treinamentos, exames, ocorrências e mais, tornando sua gestão de pessoas simples e automatizada.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="razoes">
                <h2>Módulos Inteligentes para Otimizar suas Rotinas!</h2>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={gestaofinanceira} alt="Ícone de Gestão Financeira" style={{ width: '60px', height: '60px' }} />
                        <h3>Módulos Fiscais</h3>
                        <p>Sistema completo em emissão de NFe, NFCe, NFSe e CTe. Possuímos a gestão dos documentos fiscais, incluindo armazenamento eletrônico.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={entrega} alt="Ícone de entrega" style={{ width: '60px', height: '60px' }} />
                        <h3>Controle de Entrega</h3>
                        <p>Sistema adequado ao uso logístico, que possibilita a melhoria do planejamento e da execução na movimentação de mercadorias.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={televendas} alt="Ícone de televendas" style={{ width: '60px', height: '60px' }} />
                        <h3>Televendas</h3>
                        <p>Um importante recurso para que sua empresa alcance resultados, obtenha uma excelente performance, sendo um potencializador de vendas.</p>
                    </div>
                </div>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={frota} alt="Ícone de frota" style={{ width: '60px', height: '60px' }} />
                        <h3>Controle de Frota</h3>
                        <p>Você possui vários veículos na sua empresa, nós temos a gestão das manutenções, controle de pneus e muitos mais. Permite gerenciar seus veículos, despesas e motoristas de maneira prática e eficiente.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={caixa} alt="Ícone de caixa" style={{ width: '60px', height: '60px' }} />
                        <h3>Frente de Caixa</h3>
                        <p>Prezando pela otimização de tempo, nossa frente de caixa é ideal para sua empresa, permitindo realizar vendas, consultar produtos de forma inteligente e devoluções de forma rápida e intuitiva.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={cobranca} alt="Ícone de cobrança" style={{ width: '60px', height: '60px' }} />
                        <h3>Cobrança</h3>
                        <p>Além do tradicional arquivo de remessa e retorno, temos integrações com APIs de bancos que permitem o envio instantâneo de boletos.</p>
                    </div>
                </div>
            </section>
            <section className="eficatus">
                <div className="elemento-centralizado">
                    <img src={cta} alt="Ícone de engrenagem" style={{ width: '100px', height: '100px' }} />
                </div>
                <h2>Com o Exactus Five é possível integrar sua empresa a diversos sistemas de e-Commerce, Marketplace, Sistemas Bancários.</h2>
            </section>
            <Footer />
        </>
    )
}

export default Five