import React, { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";

function CertificadoDuvidas() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);;
    return (
        <>
            <h1>CertificadoDuvidas</h1>
            <Footer footerValid={true} />
        </>
    )
}

export default CertificadoDuvidas