import React, { useContext, useState } from "react";
import { NavLink } from 'react-router-dom'
import './style.css'
import Botao from "../Botao/Botao";
import BotaoBranco from '../Botao/BotaoBranco'
import { CompraContext } from "../../context/CompraContext";

function CardCertificado({ titulo, icone, texto, validade, id, produto }) {
    const { ConsultaProdutos } = useContext(CompraContext)

    const [idValidade, setIdValidade] = useState(validade[0].id)
    const [precoValidade, setPrecoValidade] = useState(validade[0].valor)

    function handleChange(id) {
        const posicaoValidade = validade.findIndex((item) => item.id == id)
        setPrecoValidade(validade[posicaoValidade].valor)
        setIdValidade(validade[posicaoValidade].id)
    }

    function verificaProduto(produto) {
        if (produto.includes('Certificado Digital e-CNPJ')) {
            return '/certificado-saiba-mais/cnpj'
        } else if (produto.includes('Certificado Digital e-CPF')) {
            return '/certificado-saiba-mais/cpf'
        } else if (produto.includes('Certificado Digital Conectividade Social ICP')) {
            return '/certificado-saiba-mais/icp'
        } else if (produto.includes('Certificado Digital NF-e')) {
            return '/certificado-saiba-mais/nfe'
        } else if (produto.includes('Certificado Digital CT-e')) {
            return '/certificado-saiba-mais/cte'
        }
    }

    return (
        <div className="card-certificado-digital">
            <h1>{titulo}</h1>
            <div className="elemento-centralizado">
                {icone === 'Sem mídia'
                    ?
                    <p style={{ fontSize: '20px', color: '#277C82', fontWeight: '700', margin: 0 }}>Sem mídia</p>
                    :
                    <img src={icone} alt="Ícone" className="icon-certificado" style={{width: '50px', height: '30px'}}/>
                }
            </div>
            <div className="content-card-certificado">
                <h2>{texto}</h2>
                <label htmlFor="validade" style={{ fontSize: '15px', fontWeight: 'bold', color: '#777' }}>Validade: </label>
                <select onChange={(e) => handleChange(e.target.value)}>
                    {validade.map((item) => (
                        <option key={item.id} value={item.id}>{item.descricao}</option>
                    ))}
                </select>
                <h3>R$ {precoValidade.toFixed(2)}</h3>
                <div className="elemento-centralizado">
                    <NavLink to="/cadastro">
                        <Botao texto={'Comprar'} onClick={() => ConsultaProdutos(id, idValidade)} />
                    </NavLink>
                    <NavLink to={verificaProduto(produto)}>
                        <div style={{ marginLeft: '20px' }}>
                            <BotaoBranco texto={'Saiba mais'} />
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default CardCertificado