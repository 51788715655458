import React, { useContext, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import './style.css'
import { EmailContext } from "../../context/EmailContext";
import { NavLink } from "react-router-dom";
import Botao from "../../components/Botao/Botao";
import BannerTop from '../../components/Banner/BannerTop'
import SectionSolucoes from "../../components/Section/SectionSolucoes";
import Accordion from "../../components/Accordion/AccordionContabil";
import contabilbanner from '../../images/Solucoes/contabil.png'
import contabil from '../../images/Planos/contabil.svg'
import importacao from '../../images/Planos/importacao.svg'
import plano from '../../images/Planos/plano.svg'
import operacoes from '../../images/Planos/operacoes.svg'
import consolidacao from '../../images/Planos/consolidacao.svg'
import personalizados from '../../images/Planos/personalizados.svg'
import relatorios from '../../images/Planos/relatorios.svg'
import folhapagamento from '../../images/Planos/folhapagamento.svg'
import fiscal from '../../images/Planos/fiscal.svg'
import patrimonial from '../../images/Planos/patrimonial.svg'
import nuvem from '../../images/Home/nuvem.svg'

function Contabil() {
    const { setPdf } = useContext(EmailContext)
    const { setContabilidade, setOrigem } = useContext(EmailContext)
    const handleContabilidade = () => {
        setContabilidade('Contábil')
        setOrigem('contabil')
    }
    useEffect(() => {
        setPdf('')
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <BannerTop imagem={contabilbanner} icone={contabil} titulo={'CONTÁBIL'} subtitulo={'Um Sistema para Gestão Contábil sob medida para você!'} texto={'Do cálculo do Imposto de Renda à emissão de registros e termos, o Módulo Contábil facilita cada etapa. Com recursos abrangentes de lançamento e movimentação, análises detalhadas, balancetes de verificação e muito mais, você tem tudo que precisa para alcançar resultados sólidos.'} origem={'contabil'} nomeProduto={'Contábil'} />
            <SectionSolucoes produto={'Saiba mais sobre o Módulo Contábil'} link={'https://www.youtube.com/embed/YP6k_GxGlOs?si=cpyBpuSRth201r-h'} origem={'contabil'} nomeProduto={'Contábil'}/>
            <section className="planos">
                <h2 style={{ textAlign: 'center', marginBottom: '50px' }}>Benefícios da Plataforma Exactus</h2>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={importacao} alt="Ícone de importação" style={{ width: '32px', height: '32px' }} />
                        <h3>Importação Automática de Extratos - VAN</h3>
                        <p>Tenha a movimentação financeira de todos os bancos consolidada em um único ambiente Esse novo recurso, realiza a busca automática do extrato bancário (OFX) sem a necessidade de acesso ao Internet Banking do cliente.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={plano} alt="Ícone de plano" style={{ width: '32px', height: '32px' }} />
                        <h3>Plano de Contas Flexível para Análises Precisas</h3>
                        <p>O Maxximum oferece um plano de contas altamente flexível, permitindo que você crie centros de custos personalizados e modelos por atividade.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={operacoes} alt="Ícone de operações" style={{ width: '32px', height: '32px' }} />
                        <h3>Operações Simplificadas e Inteligentes</h3>
                        <p>Lançamentos de partidas dobradas, simples e futuras, tanto na matriz quanto em unidades de negócio, permitem um controle completo. O plano de contas hierárquico ilimitado e a parametrização da máscara da conta proporcionam total adaptação às suas necessidades.</p>
                    </div>
                </div>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={relatorios} alt="Ícone de relatórios" style={{ width: '32px', height: '32px' }} />
                        <h3>Relatórios Abundantes, Decisões Informadas</h3>
                        <p>Com o Maxximum, você obtém demonstrações contábeis e gerenciais em formatos característicos e gráficos. Imprima em sequência, em várias moedas ou índices, para entender melhor sua posição financeira. O planejamento e controle orçamentário garantem que você esteja sempre no controle.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={consolidacao} alt="Ícone de consolidação" style={{ width: '32px', height: '32px' }} />
                        <h3>Consolidação e Integração Perfeitas</h3>
                        <p>Consolide empresas ou filiais facilmente e compartilhe cadastros entre elas. A integração contábil com outros módulos Exactus Software de gestão empresarial garante uma visão ampla de seus negócios.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={personalizados} alt="Ícone de personalizados" style={{ width: '32px', height: '32px' }} />
                        <h3>Relatórios Personalizados e Suporte Completo</h3>
                        <p>O sistema possui um gerador de relatórios que permite criar análises sob medida. E com treinamento, consultoria e suporte, você nunca estará sozinho nessa jornada.</p>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <NavLink to="/contato">
                        <Botao texto="Entre em contato aqui" onClick={() => handleContabilidade()} />
                    </NavLink>
                </div>
            </section>
            <Accordion />
            <section className="planos">
                <h2>Conheça outro produtos para Contabilidade:</h2>
                <div className="container-empresarial">
                    <a href="/fiscal" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={fiscal} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Gestão Fiscal</h4>
                    </a>
                    <a href="/folha-de-pagamento" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={folhapagamento} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Folha de Pagamento</h4>
                    </a>
                    <a href="/patrimonial" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={patrimonial} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Gestão Patrimonial</h4>
                    </a>
                    <a href="/governanca-cadastral" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Governança Cadastral</h4>
                    </a>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Contabil