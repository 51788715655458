import React, { useContext, useEffect, useState } from "react";
import Botao from '../../../components/Botao/Botao'
import Footer from "../../../components/Footer/Footer";
import { NavLink } from "react-router-dom";
import '../style.css'
import ctacert from '../../../images/Certificado/ctacert.png'
import { CompraContext } from "../../../context/CompraContext";
import { EmailContext } from "../../../context/EmailContext";
import Loading from "../../../components/Loading/Loading";
import ObjectList from '../../../components/ObjectList';

function CertificadoHome() {
    const { ObterProdutos, Produto, setLoading, loading, setCadastro, Revenda } = useContext(CompraContext)
    const { setContabilidade, setPdf } = useContext(EmailContext)

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoading(true)
        setPdf('')
        setCadastro('')
        ObterProdutos()
            .then(() => {
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
    }, []);

    const contato = () => {
        setContabilidade("Certificado Digital")
    }

    return (
        <>
            {loading &&
                <Loading />
            }
            < div className="banner-padding-top">
                <section className="certificado-banner">
                    <div className="container-banner-certificado">
                        <div className="content-banner">
                            <h1>Exactus Certify+</h1>
                            <h2>Sua documentação já é digital! <br /> <b>Com toda segurança e praticidade!</b></h2>
                        </div>
                    </div>
                </section>
            </div >
            <section className="certificado">
                <span>Ínicio {'>'} <b>Certificado Digital</b></span>
                <h2>Certificado Digital</h2>
                <hr />
                {Produto &&
                    <ObjectList Produto={Produto} Revenda={Revenda}/>
                }
            </section>
            <section className="certificado-end">
                <div className="certificado-end-imagem">
                    <div>
                        <h2>JÁ PENSOU EM ENTRAR PARA O MERCADO DE CERTIFICAÇÃO DIGITAL?</h2>
                        <p>Junte-se a nós e potencialize seus negócios no mercado de certificado digital! Seja um parceiro da nossa empresa e alcance novos horizontes com soluções inovadoras e suporte especializado.</p>
                        <NavLink to="/contato">
                            <Botao texto={'Entre em contato aqui'} onClick={() => contato()}/>
                        </NavLink>
                    </div>
                    <img src={ctacert} alt="Imagem de uma mulher" />
                </div>
            </section>
            <Footer footerValid={true} certificado={true}/>
        </>
    )
}

export default CertificadoHome