import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Botao from "../../components/Botao/Botao";
import BannerTop from '../../components/Banner/BannerTop'
import SectionSolucoes from "../../components/Section/SectionSolucoes";
import Accordion from "../../components/Accordion/AccordionPatrimonial";
import Footer from "../../components/Footer/Footer";
import patrimonialbanner from '../../images/Solucoes/patrimonial.png'
import patrimonial from '../../images/Planos/patrimonial.svg'
import controle from '../../images/Planos/controle (2).svg'
import controletotal from '../../images/Planos/controletotal.svg'
import experiencia from '../../images/Planos/experiencia.svg'
import gerenciamentosimplificado from '../../images/Planos/gerenciamentosimplificado.svg'
import integracao from '../../images/Planos/integracao (1).svg'
import manutencao from '../../images/Planos/manutencao.svg'
import operacoes from '../../images/Planos/operacoes (1).svg'
import precisao from '../../images/Planos/precisao (1).svg'
import relatorios from '../../images/Planos/relatorios (1).svg'
import { EmailContext } from "../../context/EmailContext";
import folhapagamento from '../../images/Planos/folhapagamento.svg'
import fiscal from '../../images/Planos/fiscal.svg'
import nuvem from '../../images/Home/nuvem.svg'
import contabil from '../../images/Planos/contabil.svg'

function Patrimonial() {
    const { setPdf } = useContext(EmailContext)
    const { setContabilidade, setOrigem } = useContext(EmailContext)
    const handleContabilidade = () => {
        setContabilidade('Patrimonial')
        setOrigem('patrimonial')
    }
    useEffect(() => {
        setPdf('')
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <BannerTop imagem={patrimonialbanner} icone={patrimonial} titulo={'GESTÃO PATRIMONIAL'} subtitulo={'Uma maneira inovadora e eficaz de gerenciar seus ativos!'} texto={'Nossa plataforma robusta simplifica e agiliza todas as etapas da Folha de Pagamento, proporcionando uma experiência completa para o mercado.'} origem={'patrimonial'} nomeProduto={'Patrimonial'} />
            <SectionSolucoes produto={'Saiba mais sobre o Módulo Gestão Patrimonial'} link={'https://www.youtube.com/embed/oyca7lJFemQ?si=O6sZZK-tFGSQBWme'} origem={'patrimonial'} nomeProduto={'Patrimonial'} />
            <section className="planos">
            <h2 style={{ textAlign: 'center', marginBottom: '50px' }}>Benefícios da Plataforma Exactus</h2>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={controle} alt="Ícone de controle" style={{ width: '32px', height: '32px' }} />
                        <h3>Controle Eficiente de Ativos Patrimoniais</h3>
                        <p>Otimize sua gestão patrimonial com o Prattic, um software robusto que oferece controle detalhado dos seus ativos, organizados por tipo, localização, fornecedor e muito mais. Mantenha imagens dos itens para fácil identificação e tomada de decisões.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={precisao} alt="Ícone de precisão" style={{ width: '32px', height: '32px' }} />
                        <h3>Precisão na Depreciação e Cálculos Financeiros</h3>
                        <p>Calcule a depreciação mensal/anual com precisão, personalizando os percentuais por conta ou item. Acelere ou suspenda a depreciação conforme necessário. Gerencie também o ICMS na aquisição de imobilizados, garantindo registros precisos.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={gerenciamentosimplificado} alt="Ícone de gerenciamento" style={{ width: '32px', height: '32px' }} />
                        <h3>Gerenciamento Simplificado de Transferências e Baixas</h3>
                        <p>Realize transferências e baixas totais ou parciais com facilidade. Calcule ganhos ou perdas na baixa por venda. Acompanhe a movimentação dos itens para evitar erros, como lançamentos duplicados.</p>
                    </div>
                </div>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={integracao} alt="Ícone de relatórios integração" style={{ width: '32px', height: '32px' }} />
                        <h3>Integração Contábil Eficiente</h3>
                        <p>Otimize sua contabilização por centro de custos com integração contábil. Exporte arquivos para sua contabilidade, reduzindo a digitação repetitiva de lançamentos. Economize tempo e evite erros.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={relatorios} alt="Ícone de relatórios" style={{ width: '32px', height: '32px' }} />
                        <h3>Relatórios Detalhados e Analíticos</h3>
                        <p>Obtenha insights valiosos com relatórios detalhados sobre itens adquiridos, baixados, transferidos e mais. Acompanhe a depreciação, exaustão ou amortização acumulada por período e centro de custos.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={experiencia} alt="Ícone de experiência" style={{ width: '32px', height: '32px' }} />
                        <h3>Experiência Acumulada para Resultados Confiantes</h3>
                        <p>Beneficie-se da experiência acumulada no desenvolvimento do Prattic. Criado para atender às necessidades de administradores, contadores e suporte técnico, o software oferece resultados seguros e de alta qualidade.</p>
                    </div>
                </div>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={operacoes} alt="Ícone de operações" style={{ width: '32px', height: '32px' }} />
                        <h3>Operações Simplificadas e Treinamento Acessível</h3>
                        <p>Desfrute de menus intuitivos e relatórios claros que tornam a operacionalidade do Prattic simples e eficiente. Ajuda on-line em todos os campos facilita o treinamento e o uso.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={manutencao} alt="Ícone de manutenção" style={{ width: '32px', height: '32px' }} />
                        <h3>Manutenção Contínua Adaptável a Qualquer Empresa</h3>
                        <p>O Prattic é ideal para empresas de todos os tamanhos e setores. Nossa equipe especializada mantém o software atualizado para atender às demandas em constante mudança do controle patrimonial.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={controletotal} alt="Ícone de controle" style={{ width: '32px', height: '32px' }} />
                        <h3>Controle Total sobre Depreciação, Exaustão e Amortização</h3>
                        <p>Calcule depreciação, exaustão e amortização conforme suas taxas preferidas. Acelere a depreciação quando necessário, mantendo o controle total sobre cada bem e seu percentual depreciado, exaurido ou amortizado.</p>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <NavLink to="/contato">
                        <Botao texto="Entre em contato aqui" onClick={() => handleContabilidade()} />
                    </NavLink>
                </div>
            </section>
            <Accordion />
            <section className="planos">
                <h2>Conheça outro produtos para Contabilidade:</h2>
                <div className="container-empresarial">
                    <a href="/fiscal" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={fiscal} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Gestão Fiscal</h4>
                    </a>
                    <a href="folha-de-pagamento" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={folhapagamento} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Folha de Pagamento</h4>
                    </a>
                    <a href="/contabil" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={contabil} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Gestão Contábil</h4>
                    </a>
                    <a href="/exclusao-icms" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Exclusão ICMS</h4>
                    </a>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Patrimonial