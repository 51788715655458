import React from "react";
import './style.css'

function PlanosImagem({ imagem, titulo, texto }) {
    return (
        <section className="planos-imagem">
            <div className="elemento-centralizado">
                <img src={imagem} alt="Ícone" style={{width: '65px', height: '65px'}} className="planos-img"/>
            </div>
            <h2>{titulo}</h2>
            <p>{texto}</p>
        </section>
    )
}

export default PlanosImagem