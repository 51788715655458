import React from "react";
import './style.css'

function Planos({ titulo, texto }) {
    return (
        <section className="planos">
            <h2>{titulo}</h2>
            <p>{texto}</p>
        </section>
    )
}

export default Planos