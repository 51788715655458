import check from '../../images/ExactusBank/check (2).svg'

function CardBank({ imagem, titulo, subtitulo, texto, vantangens, opcoes, vantagensFornece, opcoesFornece }) {
    return (
        <div className="card-bank">
            <img src={imagem} alt="Ícone" style={{width: '40px', height: '40px'}}/>
            <h3><b>{titulo}</b> - {subtitulo}</h3>
            <p>{texto}</p>

            <h3><b>{vantangens}</b></h3>
            <ul>
                {opcoes.map((opcao, index) => (
                    <li key={index}>
                        <img src={check} alt='Ícone de check' style={{width: '15px', height: '15px'}}/>
                        {opcao}
                    </li>
                ))}
            </ul>
            {vantagensFornece &&
                <>
                    <h3><b>{vantagensFornece}</b></h3>
                    <ul>
                        {opcoesFornece.map((opcao, index) => (
                            <li key={index}>
                                <img src={check} alt='Ícone de check' style={{width: '15px', height: '15px'}}/>
                                {opcao}
                            </li>
                        ))}
                    </ul></>
            }
        </div>
    )
}

export default CardBank