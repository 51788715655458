import './style.css'

function Accordion() {
    return (
        <section className='razoes'>
            <h2>Dúvidas frequentes:</h2>
            <div class="accordion">
                <span class="target-fix" id="accordion"></span>
                <div>
                    <span class="target-fix" id="accordion1"></span>

                    <a href="#accordion1" id="open-accordion1" title="open">1 .  O módulo Folha de Pagamento elabora folha por Centro de Custos?</a>
                    <a href="#accordion" id="close-accordion1" title="close">1 .  O módulo Folha de Pagamento elabora folha por Centro de Custos?</a>

                    <div class="accordion-content">
                        <p>Sim, o gerenciamento é realizado através do Cadastro de Locações. A locação neste caso se torna o centro de custos de uma unidade, departamento ou área específica dentro ou fora da empresa. Ao utilizar a folha por centro de custos os dados são gravados de forma a mostrar detalhadamente os custos associados a cada centro, permitindo que as empresas compreendam melhor como os recursos estão sendo utilizados</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion2"></span>
                    <a href="#accordion2" id="open-accordion2" title="open">2 .  O módulo Folha de Pagamento permite a realização de cálculos customizados?</a>
                    <a href="#accordion" id="close-accordion2" title="close">2 .  O módulo Folha de Pagamento permite a realização de cálculos customizados?</a>
                    <div class="accordion-content">
                        <p>Sim, cálculos customizáveis podem ser realizados através do Editor de Fórmulas. A capacidade do editor de personalizar esses cálculos permite que as empresas atendam às suas necessidades específicas, cumprindo regulamentações locais. O uso do editor de fórmulas facilita a personalização, automação e garante a precisão dos cálculos.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion3"></span>
                    <a href="#accordion3" id="open-accordion3" title="open">3 .  O módulo Folha de Pagamento possui um controle de movimentações</a>
                    <a href="#accordion" id="close-accordion3" title="close">3 .  O módulo Folha de Pagamento possui um controle de movimentações</a>
                    <div class="accordion-content">
                        <p>Sim, o controle apresenta os contratos vencidos, férias vencidas, férias em dobro, avisos prévios, afastamentos e vencimentos de exames médicos ocorridos no mês. O relatório pode ser gerado em dois momentos, quando o usuário acessa a folha de pagamento, sendo alertado sobre a ocorrência das movimentações, ou através do menu de geração do relatório.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion4"></span>
                    <a href="#accordion4" id="open-accordion4" title="open">4 .  O módulo Folha de Pagamento possui um mecanismo de implantação de empresas automatizado?</a>
                    <a href="#accordion" id="close-accordion4" title="close">4 .  O módulo Folha de Pagamento possui um mecanismo de implantação de empresas automatizado?</a>
                    <div class="accordion-content">
                        <p>Sim, o mecanismo solicita algumas informações da empresa e os demais dados são fornecidos pelo próprio sistema. O procedimento leva poucos minutos e em seguida o usuário já pode cadastrar as locações, cadastrar os colaboradores e gerar a folha de pagamento.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion5"></span>
                    <a href="#accordion5" id="open-accordion5" title="open">5 .  O módulo Folha de Pagamento integra e transmite informações do SST ao eSocial?</a>
                    <a href="#accordion" id="close-accordion5" title="close">5 .  O módulo Folha de Pagamento integra e transmite informações do SST ao eSocial?</a>
                    <div class="accordion-content">
                        <p>Sim, a integração ocorre através da Plataforma Atendimento no Methodus, ou seja, onde ocorre a comunicação com os clientes da contabilidade. Os dados relacionados ao SST devem estar no padrão do layout eSocial para que o processo ocorra com sucesso e os dados, após serem integrados com a Folha de Pagamento, sejam enviados automaticamente para o eSocial.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion6"></span>
                    <a href="#accordion6" id="open-accordion6" title="open">6 .  O módulo Folha de Pagamento permite a geração de PLR e RRA?</a>
                    <a href="#accordion" id="close-accordion6" title="close">6 .  O módulo Folha de Pagamento permite a geração de PLR e RRA?</a>
                    <div class="accordion-content">
                        <p>Sim, permite a geração dos dois tipos de rendimentos, inclusive a aplicação, caso ocorra, do Imposto de Renda, conforme definido pela legislação.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion7"></span>
                    <a href="#accordion7" id="open-accordion7" title="open">7 .  O módulo Folha de Pagamento permite a transferência de colaboradores entre empresas?</a>
                    <a href="#accordion" id="close-accordion7" title="close">7 .  O módulo Folha de Pagamento permite a transferência de colaboradores entre empresas?</a>
                    <div class="accordion-content">
                        <p>Sim, permite a transferência de colaboradores para a empresa sucessora com ou sem ônus para cedente, por mudança de CPF, entre matriz e filiais (no caso de junção de implantação de empresas) e a simples cópia de dados entre empresas.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion8"></span>
                    <a href="#accordion8" id="open-accordion8" title="open">8 .  O módulo Folha de Pagamento possui mecanismo para desconto de eventos parcelados ou empréstimo consignado?</a>
                    <a href="#accordion" id="close-accordion8" title="close">8 .  O módulo Folha de Pagamento possui mecanismo para desconto de eventos parcelados ou empréstimo consignado?</a>
                    <div class="accordion-content">
                        <p>Sim, possui os dois mecanismos. Em relação ao empréstimo consignado o processo permite a importação, manutenção e geração do arquivo de retorno, desde que se trate de padrões de leiautes já tratados pelo sistema.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion9"></span>
                    <a href="#accordion9" id="open-accordion9" title="open">9 .  O módulo Folha de Pagamento possui controle de banco de horas?</a>
                    <a href="#accordion" id="close-accordion9" title="close">9 .  O módulo Folha de Pagamento possui controle de banco de horas?</a>
                    <div class="accordion-content">
                        <p>Sim, no controle o usuário indica a quantidade de horas extras, horas compensadas e horas remuneradas. As horas remuneradas podem ser lançadas automaticamente pelo sistema nos Lançamentos On-line da chapa/contrato. Todo os dados indicados no controle também são apresentados em demonstrativo.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion10"></span>
                    <a href="#accordion10" id="open-accordion10" title="open">10 .  O módulo Folha de Pagamento possui integração com sistema de Ponto?</a>
                    <a href="#accordion" id="close-accordion10" title="close">10 .  O módulo Folha de Pagamento possui integração com sistema de Ponto?</a>
                    <div class="accordion-content">
                        <p>Sim, o módulo permite a integração de dados de qualquer sistema de ponto, pois o mecanismo e parametrizável, ou seja, permite que seja informado, antes da importação, onde as informações do ponto, tratadas pelo sistema Folha de Pagamento, se encontram no arquivo.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Accordion