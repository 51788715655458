import React, { useContext, useEffect } from 'react'
import BannerSolucoes from '../../components/Banner/BannerSolucoes'
import SectionSolucoes from '../../components/Section/SectionSolucoes';
import SectionTecnologia from '../../components/Section/SectionTecnologia';
import Footer from '../../components/Footer/Footer';
import recuperacao from '../../images/Solucoes/recuperacao.jpg'
import recuperacaoesquema from '../../images/Solucoes/recuperacaoesquema.png'
import nuvem from '../../images/Home/nuvem.svg'
import fiscal from '../../images/Planos/fiscal.svg'
import { NavLink } from "react-router-dom";
import Botao from "../../components/Botao/Botao";
import { EmailContext } from '../../context/EmailContext';

function RecuperacaoTributaria() {
    const { setPdf } = useContext(EmailContext)
    const { setContabilidade, setOrigem } = useContext(EmailContext)
    const handleContabilidade = () => {
        setContabilidade('Recuperação Tributária')
        setOrigem('recuperacao')
    }
    useEffect(() => {
        setPdf("")
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <BannerSolucoes titulo={'REVISÃO TRIBUTÁRIA'} subtitulo={'Identificamos oportunidades de recuperação de créditos fiscais não utilizados pela Empresa!'} imagem={recuperacao} origem={'recuperacao'} nomeProduto={'Recuperação Tributária'} />
            <SectionSolucoes produto={'Como funciona a Revisão Tributária da Exactus?'} link={'https://www.youtube.com/embed/BfU6g2GIOnc?si=8xKXufLRDQ7hKleX'} origem={'recuperacao'} nomeProduto={'Recuperação Tributária'} />
            <SectionTecnologia texto={'Após os dados enviados para análise pela Inteligência Artificial da Exactus, o processamento resulta em um relatório analítico, item a item, conforme exigido pelo Fisco. Esse relatório pode ser utilizado para composição de ações judiciais ou para opções administrativas de compensação, se escolhidas pela empresa.'} imagem={recuperacaoesquema} />
            <section className='razoes'>
                <h2>Em resumo:</h2>
                <h3 style={{ marginBottom: '50px' }}>O serviço de Revisão Tributária é um conjunto de regras específicas para análise dos tributos, aplicadas nos arquivos da empresa em alinhamento com a legislação tributária, resultando uma maior eficiência tributária para a empresa e promovendo um ganho financeiro real.</h3>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <NavLink to="/contato">
                        <Botao texto="Entre em contato aqui" onClick={() => handleContabilidade()} />
                    </NavLink>
                </div>
            </section>
            <section className="planos">
                <h2 style={{ textAlign: 'center' }}>Conheça mais soluções da EXACTUS:</h2>
                <div className="container-empresarial">
                    <a href="/governanca-cadastral" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Governança Cadastral</h4>
                    </a>
                    <a href="/compliance-fiscal" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Compliance Fiscal</h4>
                    </a>
                    <a href="/fiscal" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={fiscal} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Gestão Fiscal</h4>
                    </a>
                    <a href="/exclusao-icms" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Exclusão do ICMS</h4>
                    </a>
                </div>
            </section>
            <Footer footerWhik={true} />
        </>
    )
}

export default RecuperacaoTributaria 