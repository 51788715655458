import React, { useContext, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import './style.css'
import { NavLink } from "react-router-dom";
import Botao from "../../components/Botao/Botao";
import BannerTop from '../../components/Banner/BannerTop'
import SectionSolucoes from "../../components/Section/SectionSolucoes";
import Accordion from "../../components/Accordion/AccordionFiscal";
import fiscal from '../../images/Planos/fiscal.svg'
import fiscalbanner from '../../images/Solucoes/fiscal.png'
import importacao from '../../images/Planos/importacao (1).svg'
import integracao from '../../images/Planos/integracao.svg'
import controle from '../../images/Planos/controle.svg'
import guia from '../../images/Planos/guia.svg'
import relatorio from '../../images/Planos/relatorio.svg'
import sistema from '../../images/Planos/sistema.svg'
import fisco from '../../images/Planos/fisco.svg'
import { EmailContext } from "../../context/EmailContext";
import folhapagamento from '../../images/Planos/folhapagamento.svg'
import patrimonial from '../../images/Planos/patrimonial.svg'
import nuvem from '../../images/Home/nuvem.svg'
import contabil from '../../images/Planos/contabil.svg'

function Fiscal() {
    const { setPdf } = useContext(EmailContext)
    const { setContabilidade, setOrigem } = useContext(EmailContext)
    const handleContabilidade = () => {
        setContabilidade('Fiscal')
        setOrigem('gestaofiscal')
    }
    useEffect(() => {
        setPdf('')
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <BannerTop imagem={fiscalbanner} icone={fiscal} titulo={'GESTÃO FISCAL'} subtitulo={'Escrita Fiscal Otimizada com Inteligência Artificial'} texto={'O usuário programa os computadores para processamento à noite e pela manhã recebe a Escrita Fiscal PRONTA, ou seja, o máximo de automação operacional. Deixe o trabalho pesado para nossos robôs de Inteligência Artificial!'} origem={'gestaofiscal'} nomeProduto={'Fiscal'} />
            <SectionSolucoes produto={'Saiba mais sobre o Módulo Gestão Fiscal'} link={'https://www.youtube.com/embed/tiousQU_EiA?si=z2u4y4mLhnRZfAnp'} origem={'gestaofiscal'} nomeProduto={'Fiscal'} />
            <section className="planos">
                <h2 style={{ textAlign: 'center', marginBottom: '50px' }}>Tarefas Repetitivas feitas por Robôs de Inteligência Artificial</h2>
                <div className="card-razoes-inteiro">
                    <div className="elemento-centralizado">
                        <img src={importacao} alt="Ícone de importação" style={{ width: '32px', height: '32px' }} />
                    </div>
                    <h3>Importação de Dados Flexível</h3>
                    <p>Importe dados com facilidade a partir da NF-e, NFC-e, CF-e SAT, CT-e, NFS-e, Sintegra, SPED Fiscal, EFD Contribuições e arquivos de texto personalizáveis. Integração com o e-Busca Plus. Simplifique e automatize seu fluxo de trabalho com o Skill.
                        O e-Busca capta os documentos eletrônicos e integra de forma automática através de horário agendado com a escrita fiscal.
                        Os processos agendados são disparados automaticamente pelo sistema na data e horário definido, ou seja, o usuário programa os computadores para processamento em momentos ociosos, exemplo: à noite, e pela manhã recebe a importação concluída.</p>
                </div>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={integracao} alt="Ícone de integração" style={{ width: '32px', height: '32px' }} />
                        <h3>Integração Simplificada e Otimizada</h3>
                        <p>Otimize suas operações com integrações perfeitas ao módulo contábil Exactus. Gerencie seus dados contábeis em tempo real ou agende conforme necessário, garantindo eficiência e controle.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={controle} alt="Ícone de controle" style={{ width: '32px', height: '32px' }} />
                        <h3>Controle Tributário Preciso</h3>
                        <p>Assegure um controle total sobre as apurações dos tributos essenciais para atender às demandas de gestão fiscal e tributária. Nossa habilidade garante a realização de operações em plena conformidade com todas as regulamentações vigentes.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={guia} alt="Ícone de guia" style={{ width: '32px', height: '32px' }} />
                        <h3>Guia e Pagamento Centralizados</h3>
                        <p>Facilite a emissão e controle de guias federais e estaduais, assegurando precisão total sobre os pagamentos.</p>
                    </div>
                </div>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={relatorio} alt="Ícone de relatórios" style={{ width: '32px', height: '32px' }} />
                        <h3>Relatórios Abrangentes</h3>
                        <p>Obtenha uma perspectiva abrangente por meio de relatórios completos que atendem às necessidades da gestão fiscal e tributária.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={sistema} alt="Ícone de sistema" style={{ width: '32px', height: '32px' }} />
                        <h3>Sistema completo e Melhoria Contínua</h3>
                        <p>Desde a busca de notas às apurações, tudo desenvolvido num ambiente único e completo. Sistema em constante desenvolvimento, apresentando contínuas melhorias e inovações continuadamente..</p>
                    </div>
                    <div className="card-razoes">
                        <img src={fisco} alt="Ícone de fisco" style={{ width: '32px', height: '32px' }} />
                        <h3>Precisão das Informações ao Fisco</h3>
                        <p>Em um cenário em que o fisco emprega a inteligência artificial para cruzar dados, nosso módulo fiscal é respaldado por um capital humano altamente qualificado. Isso assegura a eficácia dos processos relacionados às informações fornecidas ao fisco.</p>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <NavLink to="/contato">
                        <Botao texto="Entre em contato aqui" onClick={() => handleContabilidade()} />
                    </NavLink>
                </div>
            </section>
            <Accordion />
            <section className="planos">
                <h2>Conheça outro produtos para Contabilidade:</h2>
                <div className="container-empresarial">
                    <a href="/folha-de-pagamento" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={folhapagamento} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Folha de Pagamento</h4>
                    </a>
                    <a href="/fiscal" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={contabil} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Gestão Fiscal</h4>
                    </a>
                    <a href="/patrimonial" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={patrimonial} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Gestão Patrimonial</h4>
                    </a>
                    <a href="/recuperacao-tributaria" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Recuperação Tributária</h4>
                    </a>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Fiscal