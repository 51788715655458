import React from "react";
import './style.css'

function Card({ imagem, alt, texto }) {
    return (
        <div className="card">
            <div className="elemento-centralizado">
                <img src={imagem} alt={alt} style={{width: '80px', height: '80px'}}/>
            </div>
            <h1>{texto}</h1>
        </div>
    )
}

export default Card