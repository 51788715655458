import React, { useEffect } from "react";
import Banner from '../../../components/Banner/Banner'
import Botao from "../../../components/Botao/Botao";
import Footer from "../../../components/Footer/Footer";
import check from '../../../images/ERP/check (3).svg'
import SectionCertificado from "../../../components/Section/SectionCertificado";
import { NavLink } from 'react-router-dom'

function CertificadoSaibaMaisCte() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner titulo={'Exactus Certify+'} texto={'A Exactus tem o certificado certo para você! Adquira ou renove seu Certificado com a Exactus Certify+'} classe={'banner-certify'} origem={"Certificado Digital"} nomeProduto={"Certificado Digital"}/>
            <section className="razoes">
                <h2 style={{ textAlign: 'start', margin: '0 0 30px 0' }}>O que é um Certificado Digital CT-e?</h2>
                <p>O Certificado Digital CT-e é a chave para a autenticação segura e eficiente dos Conhecimentos de Transporte Eletrônico, garantindo a conformidade com as normativas fiscais e proporcionando mais agilidade nas operações logísticas.</p>
            </section>
            <section className="planos">
                <h2 style={{ margin: '0 0 30px 0' }}>Vantagens do Certificado Digital CT-e:</h2>
                <div className="certificado-itens">
                    <ul>
                        <li><img src={check} alt='Ícone de check' /><b>Emissão Rápida e Desburocratizada:</b></li>
                        <li>Emite Conhecimentos de Transporte Eletrônico de forma rápida e desburocratizada, otimizando o processo logístico e reduzindo custos operacionais.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Conformidade com as Normativas Fiscais:</b></li>
                        <li>Mantém sua empresa em conformidade com as normativas fiscais, evitando penalidades e garantindo a integridade das informações contidas nos CT-e.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Assinatura Digital com Validade Jurídica:</b></li>
                        <li>Assina digitalmente seus Conhecimentos de Transporte, conferindo a eles validade jurídica e garantindo a autenticidade das operações logísticas.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Integração com Sistemas de Gestão:</b></li>
                        <li>Integra-se facilmente aos sistemas de gestão da sua empresa, proporcionando uma gestão logística mais eficiente e integrada.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Facilidade na Transmissão para Órgãos Fiscais:</b></li>
                        <li>Simplifica a transmissão de informações fiscais para os órgãos competentes, agilizando o cumprimento das obrigações legais.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Controle de Acesso e Rastreabilidade:</b></li>
                        <li>Reforça o controle de acesso às informações logísticas, garantindo a rastreabilidade das operações e a segurança dos dados.</li>
                    </ul>
                </div>
            </section>
            <SectionCertificado />
            <section className="planos">
                <h2 style={{textAlign: 'center'}}>Adquirir meu Certificado Digital CT-e?</h2>
                <div className="elemento-centralizado">
                    <NavLink to="/certificado">
                        <Botao texto={'Adquira aqui seu Certificado Digital'} />
                    </NavLink>
                </div>
            </section>
            <Footer footerValid={true} />
        </>
    )
}

export default CertificadoSaibaMaisCte