import React, {useEffect} from "react";
import Footer from "../../components/Footer/Footer";

function PoliticaCookies() {
        useEffect(() => {
        window.scrollTo(0, 0);
    }, []);;
    return (
        <>
            <div className="politica">
                <h1>Política de Cookies da Exactus</h1>
                <h2>Última Atualização: 07/2021</h2>
                <p><b>POLÍTICA DE PRIVACIDADE</b></p>
                <p>A Exactus tem um compromisso com você, nosso cliente e/ou usuário, quanto à segurança e privacidade de seus dados. O respeito e o sigilo de suas informações é muito importante para nós. Nossa Política de Privacidade explica como a Exactus Software, Ltda (“Exactus”, “nós” ou “nosso”) e as suas empresas afiliadas, que não possuam suas próprias declarações de privacidade, coletam, processam, usam, protegem e compartilham as informações pessoais que obtemos sobre você e sua empresa, de acordo com os seus direitos de privacidade sob as leis aplicáveis, incluindo a Lei Geral de Proteção de Dados do Brasil (Lei 13.709/2018), ao acessar nossos sites, aplicativos (incluindo nossos aplicativos móveis), produtos, softwares (On-Premise) ou serviços (SaaS) vinculados a nossa política (nossos “Serviços”).</p>
                <p>Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um de nossos Serviço. Essa coleta é realizada por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como iremos usá-lo.</p>
                <p>Retemos as informações coletadas pelo tempo necessário para fornecer e concluir o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, modificações ou uso não autorizado.</p>
                <p>Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei ou com seu devido consentimento.</p>
                <p>Nossos Serviços podem ter links para serviços externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses serviços e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.</p>
                <p>Você é livre para recusar as nossas forma de coletar, usar, manter suas informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.</p>
                <p>O uso continuado de nossos Serviços será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais acesse nossa política de privacidade ou entre em contato conosco.</p>
                <p><b>POLÍTICA DE COOKIES</b></p>
                <p><b>O que são cookies?</b></p>
                <p>Como é prática comum em quase todos os sites profissionais, este site usa cookies, que são pequenos arquivos baixados no seu computador, para melhorar sua experiência. Esta página descreve quais informações eles coletam, como as usamos e por que às vezes precisamos armazenar esses cookies. Também compartilharemos como você pode impedir que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou "quebrar" certos elementos da funcionalidade dos nossos Serviços.</p>
                <p><b>Como usamos esses cookies?</b></p>
                <p>Utilizamos cookies por vários motivos e estão detalhados abaixo. Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site. É recomendável que você deixe todos os cookies se não tiver certeza se precisa ou não deles, caso sejam usados para fornecer um serviço que você usa.</p>
                <p><b>Quais Tipos de Cookies que utilizamos em nossos Serviços?</b></p>
                <p>
                    Cookies relacionados à conta: Se você criar uma conta conosco, usaremos cookies para o gerenciamento do processo de inscrição e administração geral. Esses cookies geralmente serão excluídos quando você sair do sistema, porém, em alguns casos, eles poderão permanecer posteriormente para lembrar as preferências do seu site ao sair.</p>
                <p>Cookies relacionados ao login: Utilizamos cookies quando você está logado, para que possamos lembrar dessa ação. Isso evita que você precise fazer login sempre que visitar uma nova página. Esses cookies são normalmente removidos ou limpos quando você efetua logout para garantir que você possa acessar apenas a recursos e áreas restritas ao efetuar login.</p>
                <p>Cookies relacionados a boletins por e-mail: Este site oferece serviços de assinatura de boletim informativo ou e-mail e os cookies podem ser usados ??para lembrar se você já está registrado e se deve mostrar determinadas notificações válidas apenas para usuários inscritos / não inscritos.
                    Pedidos processando cookies relacionados: Alguns de nossos Serviços oferecem facilidades de comércio eletrônico ou pagamento, e alguns cookies são essenciais para garantir que seu pedido seja lembrado entre as páginas e para que possamos processá-lo adequadamente.</p>
                <p>Cookies relacionados a pesquisas: Periodicamente, oferecemos pesquisas e questionários para fornecer informações interessantes, ferramentas úteis ou para entender nossa base de usuários com mais precisão. Essas pesquisas podem usar cookies para lembrar quem já participou numa pesquisa ou para fornecer resultados precisos após a alteração das páginas.</p>
                <p>Cookies relacionados a formulários: Quando você envia dados por meio de um formulário como os encontrados nas páginas de contacto ou nos formulários de comentários, os cookies podem ser configurados para lembrar os detalhes do usuário para correspondência futura.</p>
                <p>Cookies de preferências do site: Para proporcionar uma ótima experiência neste site, fornecemos a funcionalidade para definir suas preferências de como esse site é executado quando você o usa. Para lembrar suas preferências, precisamos definir cookies para que essas informações possam ser chamadas sempre que você interagir com uma página for afetada por suas preferências.</p>
                <p>Cookies de Terceiros: Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis, como o Google Analytics, que é uma das soluções de análise mais difundidas e confiáveis da Web, para nos ajudar a entender como você usa o site e como podemos melhorar sua experiência. Esses cookies podem rastrear itens como quanto tempo você gasta em nossos Serviços e as páginas visitadas, para que possamos continuar produzindo conteúdo atraente. Para mais informações sobre cookies do Google Analytics, consulte a página oficial do Google Analytics.</p>
                <p><b>Como você pode gerenciar os cookies?</b></p>
                <p>Você pode gerenciar os “cookies” diretamente em seu navegador. Contudo, cancelando os “cookies” o navegador pode remover preferências salvas por nossos Serviço e afetar a disponibilidade de algumas funcionalidades em nossos Serviços, podendo não funcionar corretamente. A seguir seguem alguns links que direcionam à seção de ajuda dos navegadores mais utilizados, para verificar como gerir os “cookies”:</p>
                <ul>
                    <li>
                        Internet Explorer: https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies</li>
                    <li>Safari: https://support.apple.com/pt-br/guide/safari/sfri11471/mac</li>
                    <li>Google Chrome: https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt</li>
                    <li>Mozila Firefox: https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-serviços-usam</li>
                    <li>Opera: https://www.opera.com/help/tutorials/security/privacy/</li>
                </ul>
                <p><b>Isenção de responsabilidade</b></p>
                <p>Podemos atualizar nossa Política de cookies periodicamente para refletir, por exemplo, alterações nos cookies que usamos ou por outras razões operacionais, legais ou regulamentares. Portanto, volte a acessar esta Política de cookies regularmente para se manter informado sobre o uso de cookies e tecnologias relacionadas.</p>
            </div>
            <Footer />
        </>
    )
}

export default PoliticaCookies