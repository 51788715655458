import React, { useContext } from "react";
import Botao from "../Botao/Botao";
import './style.css'
import { NavLink } from "react-router-dom";
import { EmailContext } from "../../context/EmailContext";

function Banner({ titulo, texto, classe, nomeProduto, origem }) {
    const { setContabilidade, setOrigem } = useContext(EmailContext)
    const handleContabilidade = () => {
        setContabilidade(nomeProduto)
        setOrigem(origem)
    }
    return (
        <div className="banner">
            <section className={classe}>
                <div>
                    <h1>{titulo}</h1>
                    <p>{texto}</p>
                    <NavLink to="/contato">
                        <Botao texto="Entre em contato aqui" onClick={() => handleContabilidade()} />
                    </NavLink>
                </div>
            </section>
        </div>
    )
}

export default Banner 
