import React, { useContext } from "react";
import Botao from "../Botao/Botao";
import './style.css'
import { NavLink } from "react-router-dom";
import { EmailContext } from "../../context/EmailContext";

function Banner({ titulo, subtitulo, imagem, nomeProduto, origem }) {
    const { setContabilidade, setOrigem } = useContext(EmailContext)
    const handleContabilidade = () => {
        setContabilidade(nomeProduto)
        setOrigem(origem)
    }
    return (
        <div className="banner">
            <section className="banner-top">
                <div>
                    <span style={{display: 'flex', alignItems: 'end'}}>
                        <h2>{titulo}</h2>
                    </span>
                    <h3>{subtitulo}</h3>
                    <NavLink to="/contato">
                        <Botao texto="Contratar o serviço" onClick={() => handleContabilidade()} />
                    </NavLink>
                </div>
                <div>
                    <img src={imagem} alt="Banner" style={{width: '100%'}}/>
                </div>
            </section>
        </div>
    )
}

export default Banner 
