import React, { useContext, useEffect } from "react";
import Banner from '../../../components/Banner/Banner'
import Planos from "../../../components/Planos/Planos";
import Beneficios from '../../../components/Beneficios/Beneficios'
import BotaoBranco from "../../../components/Botao/BotaoBranco";
import Footer from "../../../components/Footer/Footer";
import '../../Planos/style.css'
import escritafiscal from '../../../images/Planos/escritafiscal.webp'
import folha from '../../../images/Planos/folha.webp'
import contabilidade from '../../../images/Planos/contabilidade.webp'
import patrimonial from '../../../images/Planos/patrimonialw.webp'
import { NavLink } from 'react-router-dom'
import { EmailContext } from "../../../context/EmailContext";

function PlanoFit() {
    const { setPdf } = useContext(EmailContext)
    useEffect(() => {
        setPdf('')
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner titulo={'Plano Fit'} texto={'Potencialize seu negócio contábil com nossa plataforma: ferramentas completas para a excelência no atendimento aos clientes!'} textoBotao={'Entre em Contato!'} classe={'banner-fit'} origem={"Assunto: Assinatura Plano Fit"} nomeProduto={"Plano Fit"} />
            <Planos titulo={'O que é o Plano FIT?'} texto={'O Plano Fit é uma solução personalizada da Exactus Intelligence, especialmente projetada para atender às necessidades de pequenas empresas. Este plano abrange módulos essenciais, como escrita fiscal, folha de pagamento, contabilidade e controle patrimonial, oferecendo uma abordagem sob medida para otimizar as operações contábeis das pequenas empresas.'} />
            <section className="plano-modulos">
                <h2>Módulos do Plano FIT</h2>
                <div className="container-modulos">
                    <img src={escritafiscal} alt="Imagem de escrita fiscal" />
                    <div className="content-modulos-direita">
                        <h3>Escrita Fiscal</h3>
                        <h4>Skill</h4>
                        <p>Escrita Fiscal Simplificada e Eficiente. Automatize obrigações fiscais, emissão de guias, controle de impostos e integração contábil. Ganhe tempo e evite erros.</p>
                        <div>
                            <NavLink to="/fiscal">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="container-modulos">
                    <div className="content-modulos-esquerda">
                        <h3>Folha de Pagamento</h3>
                        <h4>Winner</h4>
                        <p>Plataforma robusta simplifica e agiliza todas as etapas da Folha de Pagamento, proporcionando uma experiência completa para o mercado.</p>
                        <div>
                            <NavLink to="/folha-de-pagamento">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                    <img src={folha} alt="Imagem de folha de pagamento" />
                </div>
                <div className="container-modulos">
                    <img src={contabilidade} alt="Imagem de contabilidade" />
                    <div className="content-modulos-direita">
                        <h3>Contabilidade</h3>
                        <h4>Maxximum</h4>
                        <p>Sistema eficiente é mais do que apenas um software; é uma solução completa que otimiza todas as suas operações contábeis.</p>
                        <div>
                            <NavLink to="/contabil">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="container-modulos">
                    <div className="content-modulos-esquerda">
                        <h3>Patrimonial</h3>
                        <h4>Prattic</h4>
                        <p>O Seu Parceiro Eficiente para Controle Patrimonial.</p>
                        <div>
                            <NavLink to="/patrimonial">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                    <img src={patrimonial} alt="Imagem de patrimônio" />
                </div>
            </section>
            <Beneficios tituloBeneficio={'Benefícios do Plano FIT'} tituloServico={'Serviços Disponíveis'} qtdUsuarios={'3'} qtdEmpresas={'10'} qtdWeb={'x'} servicoSuporte={'Multicanais'} servicoTreinamento={'Treinamento remoto'} textoBotao={'Contratar Plano FIT'} plano={'Plano Fit'} />
            <Footer />
        </>
    )
}

export default PlanoFit