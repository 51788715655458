import React, { useContext, useEffect } from 'react'
import './style.css'
import BannerSolucoes from '../../components/Banner/BannerSolucoes'
import SectionSolucoes from '../../components/Section/SectionSolucoes';
import SectionTecnologia from '../../components/Section/SectionTecnologia';
import Footer from '../../components/Footer/Footer';
import governanca from '../../images/Solucoes/governanca.jpg'
import governancaesquema from '../../images/Solucoes/governancaesquema.png'
import check from '../../images/GovernancaCadastral/Exclude.svg'
import nuvem from '../../images/Home/nuvem.svg'
import contabil from '../../images/Planos/contabil.svg'
import beneficios from '../../images/ERP/beneficios.svg'
import { NavLink } from "react-router-dom";
import Botao from "../../components/Botao/Botao";
import { EmailContext } from '../../context/EmailContext';

function GovernancaCadastral() {
    const { setPdf } = useContext(EmailContext)
    const { setContabilidade, setOrigem } = useContext(EmailContext)
    const handleContabilidade = () => {
        setContabilidade('Governança Cadastral')
        setOrigem('governanca-cadastral')
    }
    useEffect(() => {
        setPdf('')
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <BannerSolucoes titulo={'GOVERNANÇA CADASTRAL'} subtitulo={'Um super Organizador que funciona com Inteligência Artificial para o cadastro de produtos da sua Empresa.'} imagem={governanca} origem={'governanca-cadastral'} nomeProduto={'Governança Cadastral'} />
            <SectionSolucoes produto={'Como funciona a Governança Cadastral Exactus?'} link={'https://www.youtube.com/embed/TBA-JSoc12M?si=y9kpvuRuutgsfz0_'} origem={'governanca-cadastral'} nomeProduto={'Governança Cadastral'} />
            <SectionTecnologia texto={'A Inteligência Artificial da EXACTUS confere dados como SPED, NFE e Tabelas cadastrais. O processamento é feito para organizar códigos de produtos, unidades de medida e descrições dos itens. Garantindo tanto a conformidade com padrões cadastrais, como a eliminação de registros irrelevantes e a gestão da multiplicidade de entradas.'} imagem={governancaesquema} />
            <section className='razoes '>
                <div className='elemento-centralizado'>
                    <img src={beneficios} alt='Ícone' />
                </div>
                <h2>O que é analisado e quais os Benefícios deste serviço?</h2>
                <div className='beneficios-flex'>
                    <div className='beneficios-itens'>
                        <h2 style={{ textAlign: 'start' }}>Aspectos analisados:</h2>
                        <ul>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />NCM cadastrada;</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Código de barras;</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Unidades de medidas;</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Descrições dos produtos e mercadorias;</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Valores unitários.</li>
                        </ul>
                    </div>
                    <div className='beneficios-itens'>
                        <h2 style={{ textAlign: 'start' }}>Benefícios:</h2>
                        <ul>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Ajuste da tributação conforme práticas atuais;</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Aprimoramento no gerenciamento de estoque;</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Maior precisão no cálculo de custo.</li>
                        </ul>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <NavLink to="/contato">
                        <Botao texto="Entre em contato aqui" onClick={() => handleContabilidade()} />
                    </NavLink>
                </div>
            </section>
            <section className="planos">
                <h2 style={{ textAlign: 'center' }}>Conheça mais soluções da EXACTUS:</h2>
                <div className="container-empresarial">
                    <a href='/contabil' className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={contabil} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Gestão Contábil</h4>
                    </a>
                    <a href="/compliance-fiscal" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Compliance Fiscal</h4>
                    </a>
                    <a href="/recuperacao-tributaria" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Recuperação Tributária</h4>
                    </a>
                    <a href="/exclusao-icms" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Exclusão do ICMS</h4>
                    </a>
                </div>
            </section>
            <Footer footerWhik={true} />
        </>
    )
}

export default GovernancaCadastral