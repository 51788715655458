import React, { useContext, useEffect } from "react";
import './style.css'
import Botao from '../../components/Botao/Botao'
import Footer from "../../components/Footer/Footer";
import InputMask from "react-input-mask";
import formulario from '../../images/Contato/formulario.png'
import { NavLink } from "react-router-dom";
import { EmailContext } from "../../context/EmailContext";
import Sucesso from '../../components/Alert/Sucesso'
import Erro from '../../components/Alert/Erro'
import Loading from "../../components/Loading/Loading";
import { validateUF, validateEmail } from 'validations-br';

function Contato() {
    const { FaleConosco, msgSucessoEmail, msgErroEmail, setMsgErroEmail, nomeContato, setNomeContato,
        emailContato, setEmailContato,
        ddd, setDdd,
        telefone, setTelefone,
        cidade, setCidade,
        origem,
        uf, setUf,
        contabilidade, setContabilidade, loading, check, setCheck, pdf, representante, setRepresentante } = useContext(EmailContext)

    const handleCheckbox = (event) => {
        setCheck(event.target.checked)
    }

    const verifica = (origem) => {
        if (!validateEmail(emailContato)) {
            setMsgErroEmail("E-mail inválido")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        } else if(!validateUF(uf)) {
            setMsgErroEmail("UF inválido")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        FaleConosco(origem)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {msgSucessoEmail &&
                <Sucesso />
            }
            {msgErroEmail &&
                <Erro />
            }
            {loading &&
                <Loading />
            }
            <div className="contato">
                <div className="container-contato">
                    <img src={formulario} alt="Imagem de mulher segurando tablet" />
                    <div className="content-contato">
                        <h1>Entre em contato com a EXACTUS!</h1>
                        <p>Com nossas soluções em nuvem suas rotinas contábeis serão mais dinâmicas podendo escalar seus negócios e levar sua empresa para o próximo patamar!</p>
                        <form className="input-contato">
                            {pdf &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>Referente ao produto: {pdf}</span>
                                </div>
                            }
                            <select onChange={(e) => setContabilidade(e.target.value)} value={contabilidade} name="contabilidade" id="contabilidade">
                                <option value="">Assunto</option>
                                <option value="Contábil">Contábil</option>
                                <option value="Folha">Folha</option>
                                <option value="Fiscal">Fiscal</option>
                                <option value="Patrimonial">Patrimonial</option>
                                <option value="Planos e Assinatura">Planos e Assinatura</option>
                                <option value="Plano Acadêmico">Plano Acadêmico</option>
                                <option value="Plano Fit">Plano Fit</option>
                                <option value="Plano Plus">Plano Plus</option>
                                <option value="Plano Maxx">Plano Maxx</option>
                                <option value="Plano Empresarial">Plano Empresarial</option>
                                <option value="Parceiro">Parceiro</option>
                                <option value="Methodus">Methodus</option>
                                <option value="e-Eficatus">e-Eficatus</option>
                                <option value="e-Busca">e-Busca</option>
                                <option value="Governança Cadastral">Governança Cadastral</option>
                                <option value="Compliance Fiscal">Compliance Fiscal</option>
                                <option value="Recuperação Tributária">Recuperação Tributária</option>
                                <option value="Exclusão do ICMS">Exclusão do ICMS</option>
                                <option value="Exactus ERP TESTE 15 dias">Exactus ERP TESTE 15 dias</option>
                                <option value="Exactus ERP">Exactus ERP</option>
                                <option value="Exactus Five">Exactus Five</option>
                                <option value="Soluções para minha empresa">Soluções para minha empresa</option>
                                <option value="Exactus Bank">Exactus Bank</option>
                                <option value="Certificado Digital">Certificado Digital</option>
                                <option value="Outro Assunto">Outro Assunto</option>
                            </select>
                            <input type="text" placeholder="Nome / Empresa" value={nomeContato} onChange={(e) => setNomeContato(e.target.value)} />
                            <input type="email" placeholder="E-mail" value={emailContato} onChange={(e) => setEmailContato(e.target.value)} />
                            <div className="input-interno">
                                <InputMask mask="(99)" placeholder="DDD" name="ddd" style={{ width: '30%' }} value={ddd} onChange={(e) => setDdd(e.target.value)} />
                                <InputMask mask="99999-9999" placeholder="Fone / Whatsapp" name="telefone" style={{ width: '65%' }} value={telefone} onChange={(e) => setTelefone(e.target.value)} />
                            </div>
                            <div className="input-interno">
                                <input type="text" placeholder="Cidade" style={{ width: '65%' }} value={cidade} onChange={(e) => setCidade(e.target.value)} />
                                <InputMask mask="aa" placeholder="UF" name="uf" style={{ width: '30%' }} value={uf} onChange={(e) => setUf(e.target.value.toUpperCase())} />
                            </div>
                            <div className="input-interno">
                                <input type="text" placeholder="Representante" value={representante} onChange={(e) => setRepresentante(e.target.value)} />
                            </div>
                        </form>
                        <div style={{ marginBottom: '15px' }}>
                            <input type="checkbox" name="aceitar" id="option1" value="option1" style={{ marginRight: '15px' }} />
                            <label htmlFor="aceitar">Aceito receber contato pela EXACTUS a respeito de novos serviços e produtos. Estou ciente de que posso a qualquer tempo solicitar que cesse toda e qualquer atividade de publicidade direcionada.</label>
                        </div>
                        <div style={{ marginBottom: '30px' }}>
                            <input type="checkbox" name="politicaprivacidade" id="option2" value="option2" style={{ marginRight: '15px' }} checked={check} onChange={(e) => handleCheckbox(e)} />
                            <label htmlFor="politicaprivacidade">Declaro que li a presente <NavLink to="/politica-de-privacidade">Política de Privacidade</NavLink></label>
                        </div>
                        <div>
                            <Botao onClick={() => verifica(origem)} texto={'Enviar'} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contato