import React, { useContext } from "react";
import Botao from "../Botao/Botao";
import './style.css'
import { NavLink } from "react-router-dom";
import { EmailContext } from "../../context/EmailContext";


function BannerImagem({ titulo, imagem, subtitulo, texto, classe, nomeProduto, origem }) {
    const { setContabilidade, setOrigem } = useContext(EmailContext)
    const handleContabilidade = () => {
        setContabilidade(nomeProduto)
        setOrigem(origem)
    }
    return (
        <div className="banner">
            <section className={classe}>
                <div>
                    <span className="container-texto-banner">
                        <h1>{titulo}</h1>
                        <img src={imagem} alt="Ícone" style={{width: '70px', height: '70px'}}/>
                    </span>
                    <h2>{subtitulo}</h2>
                    <p>{texto}</p>
                    <NavLink to="/contato">
                        <Botao texto="Entre em contato aqui" onClick={() => handleContabilidade()} />
                    </NavLink>
                </div>
            </section>
        </div>
    )
}

export default BannerImagem 
