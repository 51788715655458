import './style.css'

function Accordion() {
    return (
        <section className='razoes'>
            <h2>Dúvidas frequentes:</h2>
            <div class="accordion">
                <span class="target-fix" id="accordion"></span>
                <div>
                    <span class="target-fix" id="accordion1"></span>

                    <a href="#accordion1" id="open-accordion1" title="open">1 . O módulo fiscal é capaz de lidar de forma eficaz e automática com todas as diferentes tributações fiscais (Lucro Real, Lucro Presumido e Simples Nacional) que a empresa está sujeita? Isso inclui impostos federais, estaduais e municipais, bem como eventuais particularidades regionais?</a>
                    <a href="#accordion" id="close-accordion1" title="close">1 . O módulo fiscal é capaz de lidar de forma eficaz e automática com todas as diferentes tributações fiscais (Lucro Real, Lucro Presumido e Simples Nacional) que a empresa está sujeita? Isso inclui impostos federais, estaduais e municipais, bem como eventuais particularidades regionais?</a>

                    <div class="accordion-content">
                        <p>Sim, o módulo fiscal foi projetado para abranger todas as tributações fiscais. Ele possui funcionalidades integradas para lidar eficazmente com impostos federais, estaduais e municipais, considerando as particularidades regionais. Além disso, o sistema é regularmente atualizado para incorporar mudanças na legislação fiscal, garantindo conformidade e precisão nos cálculos e processos.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion2"></span>
                    <a href="#accordion2" id="open-accordion2" title="open">2 .  O módulo fiscal importa/integra documentos fiscais?</a>
                    <a href="#accordion" id="close-accordion2" title="close">2 .  O módulo fiscal importa/integra documentos fiscais?</a>
                    <div class="accordion-content">
                        <p>O módulo fiscal realiza a importação e integração de diversos documentos fiscais. Isso inclui a NF-e, NFC-e, CF-e SAT, CT-e, NFS-e, Sintegra, SPED Fiscal, EFD Contribuições e até mesmo arquivos de texto personalizáveis. Além disso, há uma integração automática com o e-Busca Plus, simplificando e automatizando significativamente o fluxo de trabalho, visando conformidade legal e eficiência operacional.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion3"></span>
                    <a href="#accordion3" id="open-accordion3" title="open">3 .  O módulo fiscal dispõe de automação através da inteligência artificial e robotização?</a>
                    <a href="#accordion" id="close-accordion3" title="close">3 .  O módulo fiscal dispõe de automação através da inteligência artificial e robotização?</a>
                    <div class="accordion-content">
                        <p>Sim, através do <b>AGIUS</b>, o robô Exactus que automatiza rotinas manuais através de agendamentos, você pode agendar processos noturnos, que são executados como serviço, e analisar os resultados no dia seguinte. Ou seja, sua contabilidade 24 horas.
                            <br />
                            <br />
                            <b>Exemplo prático:</b> O usuário programa os computadores para processamento à noite e pela manhã recebe a Escrita Fiscal PRONTA, com as apurações realizadas, havendo apenas o trabalho de conferência das informações, ou seja, o máximo de automação operacional.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion4"></span>
                    <a href="#accordion4" id="open-accordion4" title="open">4 .  O módulo fiscal garante a precisão nas Informações Prestadas ao Fisco?</a>
                    <a href="#accordion" id="close-accordion4" title="close">4 .  O módulo fiscal garante a precisão nas Informações Prestadas ao Fisco?</a>
                    <div class="accordion-content">
                        <p>Num ambiente onde o fisco utiliza inteligência artificial para a análise cruzada de dados, nosso módulo fiscal conta com o suporte de uma equipe altamente qualificada. Essa combinação assegura a eficácia dos processos envolvidos na prestação de informações ao fisco, proporcionando uma base sólida e confiável para lidar com as demandas do ambiente regulatório.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion5"></span>
                    <a href="#accordion5" id="open-accordion5" title="open">5 .  O módulo de Gestão Fiscal conta com Relatórios de apuração e acompanhamento fiscal?</a>
                    <a href="#accordion" id="close-accordion5" title="close">5 .  O módulo de Gestão Fiscal conta com Relatórios de apuração e acompanhamento fiscal?</a>
                    <div class="accordion-content">
                        <p>Sim, o módulo fiscal possui todos os relatórios necessários ao atendimento a gestão fiscal e tributária de uma empresa. Além disso, permite aos usuários a publicação automática dos relatórios para envio aos clientes do escritório contábil através do portal do atendimento do Methodus.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Accordion