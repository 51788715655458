import React, {useEffect} from "react";
import './style.css'
import Footer from '../../components/Footer/Footer'
import evolucao from '../../images/Institucional/evolucao.png'
import raizes from '../../images/Institucional/raizes.png'
import modernidade from '../../images/Institucional/modernidade.png'
import fundacao from '../../images/Institucional/1962.png'
import associacao from '../../images/Institucional/1969.png'
import educacao from '../../images/Institucional/1970.png'
import cpi from '../../images/Institucional/1975.png'
import adaptacao from '../../images/Institucional/1986.png'
import criacao from '../../images/Institucional/1991.jpg'
import nacional from '../../images/Institucional/1990.png'
import inovacao from '../../images/Institucional/2000.png'
import tecnologias from '../../images/Institucional/2020.png'
import logo from '../../images/Footer/logo-branco.svg'
import logoo from '../../images/Institucional/Group 497.svg'

function Institucional() {
        useEffect(() => {
        window.scrollTo(0, 0);
    }, []);;
    return (
        <>
            <section className="banner-institucional">
                <h1>54 anos de tecnologia</h1>
            </section>
            <section className="planos">
                <h2 style={{ textAlign: 'center' }}>Bem-vindo à Exactus Software: Excelência em Soluções Tecnológicas para Gestão Empresarial</h2>
            </section>
            <section className="razoes">
                <div className="container-institucional">
                    <div className="content-institucional" style={{marginBottom: '100px'}}>
                        <img src={raizes} alt="Imagem que remete às raízes da empresa" />
                        <div>
                            <h2>Raízes do Pioneirismo</h2>
                            <p>Desde 1973, a Exactus Software tem sido uma força pioneira no mundo da tecnologia, evoluindo a maneira como as empresas gerenciam seus negócios.</p>
                        </div>
                    </div>
                    <div className="content-institucional" style={{marginBottom: '100px'}}>
                        <div>
                            <h2 style={{ textAlign: 'end' }}>Evolução Tecnológica</h2>
                            <p style={{ textAlign: 'end' }}>Fundada em Londrina-PR, nossa jornada começou com a instalação pioneira de um mainframe IBM - 360/20. Essa ousadia nos impulsionou a criar conhecimento e formar profissionais qualificados na região.</p>
                        </div>
                        <img src={evolucao} alt="Imagem que remete à evolução tecnológica da empresa" />
                    </div>
                    <div className="content-institucional">
                        <img src={modernidade} alt="Imagem dos funcionários da empresa" />
                        <div>
                            <h2>Marca de Modernidade</h2>
                            <p>Desde 1973, a Exactus Software tem sido uma força pioneira no mundo da tecnologia, evoluindo a maneira como as empresas gerenciam seus negócios.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="planos institucional-meio">
                <div className="elemento-centralizado">
                    <img src={logoo} alt="Logo da Exactus" style={{width: '120px', marginBottom: '30px'}}/>
                </div>
                <h2>A História da <b>EXACTUS</b> é a mesma história da Informática no <b>Norte do Paraná</b>. E teve início quando a <b>EXACTUS</b> trouxe o primeiro computador para <b>Londrina!</b></h2>
                <p>Texto de Márcia Sawczuk</p>
            </section>
            <section className="razoes razoes-historia">
                <h2>Nossa História em fatos e fotos</h2>
            </section>
            <section>
                <div className="historia">
                    <div className="container-historia" style={{backgroundColor: '#f1f1f1'}}>
                        <div className="content-historia">
                            <img src={fundacao} alt="Imagem da fundação da Exactus" />
                            <div>
                                <h2>Fundação da EXACTUS S/A</h2>
                                <p>Sr. Florêncio Muniz funda a EXACTUS S/A em Londrina, com o objetivo de trazer o primeiro computador para a cidade.</p>
                            </div>
                        </div>
                        <div className="fundacao">
                            <span>1970</span>
                        </div>
                    </div>
                    <div className="container-historia">
                        <div className="content-historia">
                            <div>
                                <h2>Instalação do Primeiro Computador</h2>
                                <p>A EXACTUS instala o primeiro mainframe IBM/360-20 em Londrina, um marco para a cidade.</p>
                                <p>Sr. Muniz se associa aos Srs. Heitor Bertin e Romeu Dematté Jr. para realizar a importação do primeiro computador para Londrina.</p>
                            </div>
                            <img src={educacao} alt="Imagem da associação da Exactus" />
                        </div>
                        <div className="associacao">
                            <span>1970</span>
                        </div>
                    </div>
                    <div className="container-historia">
                        <div className="content-historia">
                            <img src={associacao} alt="Imagem da Instalação do Primeiro Computador." />
                            <div>
                                <h2>Instalação do Primeiro Computador</h2>
                                <p>A EXACTUS instala o primeiro mainframe IBM/360-20 em Londrina, um marco para a cidade.</p>
                            </div>
                        </div>
                        <div className="instalacao">
                            <span>1970</span>
                        </div>
                    </div>
                    <div className="container-historia">
                        <div className="content-historia">
                            <div>
                                <h2>Participação na CPI das Multinacionais </h2>
                                <p>A EXACTUS participa da CPI brasileira das multinacionais, contribuindo para a criação da Secretaria Especial da Informática (SEI).</p>
                            </div>
                            <img src={cpi} alt="Imagem da Participação na CPI das Multinacionais " />
                        </div>
                        <div className="cpi">
                            <span>1975</span>
                        </div>
                    </div>
                    <div className="container-historia">
                        <div className="content-historia">
                            <img src={adaptacao} alt="Imagem da Adaptação para Microcomputadores" />
                            <div>
                                <h2>Adaptação para Microcomputadores</h2>
                                <p>A empresa inicia o desenvolvimento de softwares contábeis para microcomputadores e redes, acompanhando a evolução da tecnologia.</p>
                            </div>
                        </div>
                        <div className="adaptacao">
                            <span>1986</span>
                        </div>
                    </div>
                    <div className="container-historia">
                        <div className="content-historia">
                            <div>
                                <h2>Criação das Centrais de Atendimento</h2>
                                <p>A EXACTUS cria as Centrais de Atendimento (CAs) para distribuir, treinar e dar assistência aos usuários em todo o Brasil.</p>
                            </div>
                            <img src={criacao} alt="Imagem da Criação das Centrais de Atendimento" />
                        </div>
                        <div className="criacao">
                            <span>1991</span>
                        </div>
                    </div>
                    <div className="container-historia">
                        <div className="content-historia">
                            <img src={nacional} alt="Imagem da Expansão Nacional" />
                            <div>
                                <h2>Expansão Nacional</h2>
                                <p>A rede de CAs se expande para diversos estados, tornando-se a maior rede de assistência a softwares contábeis e administrativos do país.</p>
                            </div>
                        </div>
                        <div className="nacional">
                            <span>1990 a 2000</span>
                        </div>
                    </div>
                    <div className="container-historia">
                        <div className="content-historia">
                            <div>
                                <h2>Inovação Contínua</h2>
                                <p>A empresa continua a inovar, lançando soluções ERP, abrindo uma fábrica de software e oferecendo hospedagem de sites. Atualmente, a EXACTUS é uma líder no setor de gestão empresarial e tecnologia contábil.</p>
                            </div>
                            <img src={inovacao} alt="Imagem de inovação" />
                        </div>
                        <div className="inovacao">
                            <span>2000 a 2010</span>
                        </div>
                    </div>
                    <div className="container-historia">
                        <div className="content-historia">
                            <img src={tecnologias} alt="Imagem de Novas Tecnologias para Novos Mercados" />
                            <div>
                                <h2>Novas Tecnologias para Novos Mercados</h2>
                                <p>A EXACTUS acompanhando as grandes transformaçnoes tecnológicas dos últimos 50 anos pode perceber a importância de estar sempre à frente das ferramentas mais modernas da atualidade. Inteligência artificial já é realidade, comércio online é nosso dia a dia. São essas novas gerações que acompanharemos oferecendo sempre o que temos de melhor para nossos clientes.</p>
                            </div>
                        </div>
                        <div className="tecnologias">
                            <span>2010 a 2023</span>
                        </div>
                    </div>
                </div>
                <div className="banner-end"/>
            </section>
            <section className="institucional-end">
                <p>e de 2023 em diante...</p>
                <h2>CONSTRUIREMOS JUNTOS!</h2>
                <div className="elemento-centralizado">
                    <img src={logo} alt="Logo da Exactus" width="300"/>
                    <span />
                    <h3>54 anos</h3>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Institucional