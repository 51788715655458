import React, { useContext, useEffect } from 'react'
import BannerSolucoes from '../../components/Banner/BannerSolucoes'
import SectionSolucoes from '../../components/Section/SectionSolucoes';
import SectionTecnologia from '../../components/Section/SectionTecnologia';
import Footer from '../../components/Footer/Footer';
import exclusao from '../../images/Solucoes/exclusao.jpg'
import exclusaoesquema from '../../images/Solucoes/exclusaoesquema.png'
import nuvem from '../../images/Home/nuvem.svg'
import patrimonial from '../../images/Planos/patrimonial.svg'
import { NavLink } from "react-router-dom";
import Botao from "../../components/Botao/Botao";
import { EmailContext } from '../../context/EmailContext';

function ExclusaoICMS() {
    const { setPdf } = useContext(EmailContext)
    const { setContabilidade, setOrigem } = useContext(EmailContext)
    const handleContabilidade = () => {
        setContabilidade('Exclusão do ICMS')
        setOrigem('exclusao')
    }
    useEffect(() => {
        setPdf("")
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <BannerSolucoes titulo={'EXCLUSÃO DO ICMS DA BASE DE CÁLCULOS DO PIS/COFINS'} subtitulo={'Corrigimos seus SPEDs para validar o uso prévio de créditos! Mais economia para sua EMPRESA.'} imagem={exclusao} origem={'exclusao'} nomeProduto={'Exclusão do ICMS'} />
            <SectionSolucoes produto={'Como funciona a Exclusão do ICMS da Exactus?'} link={'https://www.youtube.com/embed/NjDBb4kf9dY?si=qqsJT981H9xYu7sJ'} origem={'exclusao'} nomeProduto={'Exclusão do ICMS'} />
            <SectionTecnologia texto={'Após os dados enviados para análise pela Inteligência Artificial da Exactus, o processamento resulta em um relatório analítico, item a item, conforme exigido pelo Fisco. Esse relatório pode ser utilizado para composição de ações judiciais ou para opções administrativas de compensação, se escolhidas pela empresa.'} imagem={exclusaoesquema}/>
            <section className='razoes'>
                <h2>Em resumo:</h2>
                <h3 style={{marginBottom: '50px'}}>Para empresários, contadores ou advogados, esta é uma forma de determinar com precisão o valor de ICMS que pode ser excluído da base de cálculo do PIS/COFINS.</h3>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <NavLink to="/contato">
                        <Botao texto="Entre em contato aqui" onClick={() => handleContabilidade()} />
                    </NavLink>
                </div>
            </section>
            <section className="planos">
                <h2 style={{textAlign: 'center'}}>Conheça mais soluções da EXACTUS:</h2>
                <div className="container-empresarial">
                    <a href="/governanca-cadastral" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Governança Cadastral</h4>
                    </a>
                    <a href="/compliance-fiscal" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Compliance Fiscal</h4>
                    </a>
                    <a href="/recuperacao-tributaria" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Recuperação Tributária</h4>
                    </a>
                    <a href="/patrimonial" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={patrimonial} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Gestão Patrimonial</h4>
                    </a>
                </div>
            </section>
            <Footer footerWhik={true} />
        </>
    )
}

export default ExclusaoICMS 