import React, { useContext, useEffect } from "react";
import Banner from '../../../components/Banner/Banner'
import Planos from "../../../components/Planos/Planos";
import Beneficios from "../../../components/Beneficios/Beneficios";
import BotaoBranco from "../../../components/Botao/BotaoBranco";
import Footer from "../../../components/Footer/Footer";
import '../../Planos/style.css'
import escritafiscal from '../../../images/Planos/escritafiscal.webp'
import folha from '../../../images/Planos/folha.webp'
import contabilidade from '../../../images/Planos/contabilidade.webp'
import { NavLink } from 'react-router-dom'
import { EmailContext } from "../../../context/EmailContext";

function PlanoAcademico() {
    const { setPdf } = useContext(EmailContext)
    useEffect(() => {
        setPdf('')
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner titulo={'Plano Acadêmico'} texto={'Potencialize seu negócio contábil com nossa plataforma: ferramentas completas para a excelência no atendimento aos clientes!'} textoBotao={'Entre em Contato!'} classe={'banner-academico'} origem={'Assunto: Assinatura Plano Acadêmico'} nomeProduto={'Plano Acadêmico'} />
            <Planos titulo={'O que é o Plano ACADÊMICO?'} texto={'O Plano Acadêmico é uma oferta exclusiva da Exactus , desenvolvida para atender às necessidades dos estudantes de contabilidade. Este plano proporciona acesso a ferramentas essenciais, incluindo escrita fiscal, folha de pagamento e contabilidade, preparando os futuros contadores para o sucesso em sua carreira.'} />
            <section className="plano-modulos">
                <h2>Módulos do Plano ACADÊMICO</h2>
                <div className="container-modulos">
                    <img src={escritafiscal} alt="Imagem de escrita fiscal" />
                    <div className="content-modulos-direita">
                        <h3>Escrita Fiscal</h3>
                        <h4>Skill</h4>
                        <p>Escrita Fiscal Simplificada e Eficiente. Automatize obrigações fiscais, emissão de guias, controle de impostos e integração contábil. Ganhe tempo e evite erros.</p>
                        <div>
                            <NavLink to="/fiscal">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="container-modulos">
                    <div className="content-modulos-esquerda">
                        <h3>Folha de Pagamento</h3>
                        <h4>Winner</h4>
                        <p>Plataforma robusta simplifica e agiliza todas as etapas da Folha de Pagamento, proporcionando uma experiência completa para o mercado.</p>
                        <div>
                            <NavLink to="/folha-de-pagamento">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                    <img src={folha} alt="Imagem de folha de pagamento" />
                </div>
                <div className="container-modulos">
                    <img src={contabilidade} alt="Imagem de contabilidade" />
                    <div className="content-modulos-direita">
                        <h3>Contabilidade</h3>
                        <h4>Maxximum</h4>
                        <p>Sistema eficiente é mais do que apenas um software; é uma solução completa que otimiza todas as suas operações contábeis.</p>
                        <div>
                            <NavLink to="/contabil">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
            <Beneficios tituloBeneficio={'Benefícios do Plano ACADÊMICO'} tituloServico={'Serviços Disponíveis'} qtdUsuarios={'1'} qtdEmpresas={'1'} qtdWeb={'x'} servicoSuporte={'Via e-mail'} servicoTreinamento={'Solicitar orçamento'} textoBotao={'Contratar Plano ACADÊMICO'} plano={'Plano Acadêmico'} />
            <Footer />
        </>
    )
}

export default PlanoAcademico