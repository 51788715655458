import React, { useEffect, useContext, useState } from "react";
import "./style.css";
import Botao from "../../components/Botao/Botao";
import Footer from "../../components/Footer/Footer";
import nuvem from "../../images/Home/nuvem.svg";
import seguranca from "../../images/Home/seguranca.svg";
import confiabilidade from "../../images/Home/confiabilidade.svg";
import agilidade from "../../images/Home/agilidade.svg";
import otimizacao from "../../images/Home/otimizacao.svg";
import industria from "../../images/Home/industria.svg";
import comercio from "../../images/Home/comercio.svg";
import servicos from "../../images/Home/servicos.svg";
import logoSimplesNacional from "../../images/SimplesNacional/simples_nacional_logo.svg";
import logoPessoaSimplesNacional from "../../images/SimplesNacional/modelo_banner.png";
import planos from "../../images/Home/planosassinatura.webp";
import { NavLink } from "react-router-dom";
import { EmailContext } from "../../context/EmailContext";
import { CompraContext } from "../../context/CompraContext";
import BotaoSimplesNacional from "../../components/Botao/BotaoSimplesNacional";

function Home() {
  const { setContabilidade, setOrigem } = useContext(EmailContext);
  const { Revenda, setRevenda } = useContext(CompraContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalItems = 2;
  const tempoCarrosel = 14000;
  const handleContabilidade = () => {
    setContabilidade("Parceiro");
    setOrigem("Assunto: Parceiro");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const rvParam = params.get("rv");

    if (rvParam) {
      setRevenda(rvParam);
    }
  }, [Revenda]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalItems);
    }, tempoCarrosel);
    return () => clearInterval(interval);
  }, [currentIndex]);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

    return (
        <div className="home">
            <div className="carousel">
                <section className={`carousel-item ${currentIndex === 1 ? 'active' : ''}`}>
                    <div className="home-banner">
                        <div className="container-banner">
                            <div className="content-banner">
                                <h1>54 anos de tecnologia</h1>
                                <h2>Sendo <b>Disruptivo</b> na evolução fisco-contábil de sua Empresa</h2>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <NavLink to="/institucional" style={{ marginBottom: '15px' }}>
                                        <Botao texto={'Conheça a Exactus'} />
                                    </NavLink>
                                    <NavLink to="/contato">
                                        <Botao texto={'Seja um parceiro EXACTUS'} onClick={() => handleContabilidade()} />
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={`carousel-item ${currentIndex === 0 ? 'active' : ''}`}>
                    <div className="home-banner-simples-nacional">
                        <div className="content-banner-simples-nacional">
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src={logoSimplesNacional} alt="Logo simples nacional" className="logo-simples-nacional" />
                                <div>
                                    <p>Descubra o que fizemos para <br /> você ter mais agilidade com o <br /> <strong>Simples Nacional!</strong></p>
                                    <NavLink to="/simplesnacionalfacil">
                                        <BotaoSimplesNacional texto={"Saiba Mais"} />
                                    </NavLink>
                                </div>
                            </div>
                            <img src={logoPessoaSimplesNacional} alt="Imagem de um homem" className="logo-pessoa-simples-nacional" />
                        </div>
                    </div>
                </section>
            </div>
            <div className="carousel-indicators">
                {[...Array(totalItems)].map((_, index) => (
                    <button
                        key={index}
                        className={`carousel-dot ${currentIndex === index ? 'active' : ''}`}
                        onClick={() => handleDotClick(index)}
                    />
                ))}
            </div>
            <section className="home-contato">
                <div className="container-contato-home">
                    <h2>Conheça na Exactus, as melhores Soluções para o seu Negócio</h2>
                    <p>Potencialize seu negócio com nossa suíte completa de softwares. Oferecemos soluções para gestão empresarial, fiscal e patrimonial. Além disso, proporcionamos certificados digitais e soluções SaaS na nuvem para simplificar sua operação. A excelência que seu negócio merece, agora ao seu alcance.</p>
                </div>
                <div className="elemento-centralizado">
                    <NavLink to="/contato">
                        <Botao texto={'Entre em contato para mais informações sobre nossos Produtos'} />
                    </NavLink>
                </div>
            </section>
            <section className="home-contabilidade">
                <h2>Produtos Exactus para Contabilidade</h2>
                <p>A solução contábil definitiva para sua contabilidade. Descubra nosso sistema de última geração, projetado especialmente para atender todas as suas necessidades operacionais. Aumente a eficiência do seu escritório e conquiste resultados surpreendentes com nossa plataforma altamente integrada. Junte-se a milhares de profissionais satisfeitos que já experimentaram a transformação dos seus negócios com nosso sistema.</p>
                <div className="container-contabilidade">
                    <div className="card-contablidade">
                        <h3>Contábil</h3>
                        <p>Este sistema eficiente é mais do que apenas um software, é uma solução completa que otimiza todas as suas operações contábeis.</p>
                        <NavLink to="/contabil">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                    <div className="card-contablidade">
                        <h3>Folha</h3>
                        <p>Nossa plataforma robusta simplifica e agiliza todas as etapas da Folha de Pagamento, proporcionando uma experiência completa para o mercado.</p>
                        <NavLink to="/folha-de-pagamento">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                    <div className="card-contablidade">
                        <h3>Fiscal</h3>
                        <p>Escrita Fiscal simplificada com o Skill, sua ferramenta definitiva para operações Multiusuário e Multiempresa.</p>
                        <NavLink to="/fiscal">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                    <div className="card-contablidade">
                        <h3>Patrimonial</h3>
                        <p>Prattic, uma maneira inovadora e eficaz de gerenciar seus ativos fixos!</p>
                        <NavLink to="/patrimonial">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                </div>
            </section>
            <section className="home-solucoes">
                <h2>Soluções em Nuvem para sua Empresa</h2>
                <div className="container-solucoes">
                    <div className="card-solucoes">
                        <img src={nuvem} alt="Ícone de nuvem" style={{ width: '44px', height: '32px' }} />
                        <h3>Methodus</h3>
                        <p>Simplifique sua vida contábil com acesso fácil a relatórios, dados em tempo real e integração com sistemas. Libere tempo para focar no crescimento do seu negócio enquanto nossa plataforma cuida do restante. Ganhe eficiência com a automação de processos e aproveite o poder da tecnologia a favor da sua contabilidade.</p>
                        <NavLink to="/methodus">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                    <div className="card-solucoes">
                        <img src={nuvem} alt="Ícone de nuvem" style={{ width: '44px', height: '32px' }} />
                        <h3>e-Eficatus</h3>
                        <p>Nosso robô com inteligência artificial aplicada à contabilidade e escrita fiscal, é precursor em automatizar todas as movimentações de quaisquer sistemas de gestão para quaisquer sistemas contábeis.</p>
                        <NavLink to="/eficatus">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                    <div className="card-solucoes">
                        <img src={nuvem} alt="Ícone de nuvem" style={{ width: '44px', height: '32px' }} />
                        <h3>e-Busca Tudo</h3>
                        <p>Seu Cliente fica sabendo em tempo real quem emitiu NF-e para o CNPJ dele, seu Escritório recebe automaticamente todos os arquivos digitais XML do seu cliente, chega de leitores de código de barra. Importe para o TOP Fiscal com apenas um clique.</p>
                        <NavLink to="/busca">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                </div>
                <div className="container-solucoes">
                    <div className="card-solucoes">
                        <img src={nuvem} alt="Ícone de nuvem" style={{ width: '44px', height: '32px' }} />
                        <h3>Governança Cadastral</h3>
                        <p>Através de uma perspectiva fiscal, tenha o cadastro de produtos alinhado à legislação vigente, assegurando um cadastro robusto e confiável para todos os departamentos da empresa.</p>
                        <NavLink to="/governanca-cadastral">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                    <div className="card-solucoes">
                        <img src={nuvem} alt="Ícone de nuvem" style={{ width: '44px', height: '32px' }} />
                        <h3>Compliance Fiscal</h3>
                        <p>Para empresas enquadradas no Lucro Real ou Presumido, elimine as imprecisões nas declarações ao Fisco, elevando a eficácia e precisão na escrituração dos dados.</p>
                        <NavLink to="/compliance-fiscal">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                    <div className="card-solucoes">
                        <img src={nuvem} alt="Ícone de nuvem" style={{ width: '44px', height: '32px' }} />
                        <h3>Recuperação Tributária</h3>
                        <p>Conjunto de regras específicas à análise dos tributos, aplicadas nos arquivos da empresa em alinhamento com a legislação tributária, resultando uma maior eficiência tributária para a empresa e promovendo um ganho financeiro real.</p>
                        <NavLink to="/recuperacao-tributaria">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                </div>
                <div className="container-solucoes">
                    <div className="card-solucoes">
                        <img src={nuvem} alt="Ícone de nuvem" style={{ width: '44px', height: '32px' }} />
                        <h3>Exclusão do ICMS da base de cálculo do PIS/COFINS</h3>
                        <p>Para empresários, contadores ou advogados, esta é uma forma de determinar com precisão o valor de ICMS que pode ser excluído da base de cálculo do PIS/COFINS.</p>
                        <NavLink to="/exclusao-icms">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                    <div className="card-solucoes">
                        <img src={nuvem} alt="Ícone de nuvem" style={{ width: '44px', height: '32px' }} />
                        <h3>EXACTUS ERP</h3>
                        <p>O EXACTUS ERP é um poderoso aliado para quem precisa de gestão financeira, vendas e emissão de todos os tipos de notas fiscais.</p>
                        <NavLink to="/erp">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                    <div className="card-solucoes">
                        <img src={nuvem} alt="Ícone de nuvem" style={{ width: '44px', height: '32px' }} />
                        <h3>EXACTUS FIVE</h3>
                        <p>A gestão da sua empresa em um sistema completo e personalizável.</p>
                        <NavLink to="/five">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                </div>
            </section>
            <section className="home-certificado">
                <h2>Adquira seu Certificado Digital EXACTUS</h2>
                <div className="container-certificado">
                    <div className="card-certificado">
                        <div className="elemento-centralizado">
                            <img src={seguranca} alt="Ícone de segurança" style={{ width: '32px', height: '42px' }} />
                        </div>
                        <h3>Segurança de dados</h3>
                        <p>Assinatura digital com validade jurídica garante autenticidade e integridade em documentos eletrônicos</p>
                    </div>
                    <div className="card-certificado">
                        <div className="elemento-centralizado">
                            <img src={confiabilidade} alt="Ícone de confiabilidade" style={{ width: '44px', height: '47px' }} />
                        </div>
                        <h3>Confiabilidade na declaração do IR</h3>
                        <p>Uso da Declaração Pré-Preenchida agiliza o processo e antecipa restituições"</p>
                    </div>
                    <div className="card-certificado">
                        <div className="elemento-centralizado">
                            <img src={agilidade} alt="Ícone de agilidade" style={{ width: '40px', height: '40px' }} />
                        </div>
                        <h3>Facilidade e Agilidade</h3>
                        <p>Substitui a necessidade de deslocamentos e reduz burocracia em transações eletrônicas e interações com órgãos públicos</p>
                    </div>
                    <div className="card-certificado">
                        <div className="elemento-centralizado">
                            <img src={otimizacao} alt="Ícone de otimização" style={{ width: '46px', height: '45px' }} />
                        </div>
                        <h3>Otimização de Serviços Online</h3>
                        <p>Acesso simplificado a portais governamentais como eSocial, e-CAC e Receita Federal</p>
                    </div>
                </div>
                <NavLink to="/certificado">
                    <Botao texto={'Adquira seu Certificado aqui!'} />
                </NavLink>
            </section>
            <section className="home-ajuda">
                <h2>Como a Exactus pode ajudar sua Empresa?</h2>
                <p>Conheça nossos Sistemas de Gestão para sua Empresa. Oferecemos uma gama completa de ferramentas personalizadas para indústria, comércio e serviços. Simplifique suas operações, otimize processos e obtenha resultados excepcionais.</p>
                <div className="container-ajuda">
                    <div className="card-ajuda">
                        <div className="elemento-centralizado">
                            <img src={industria} alt="Ícone de indústria" style={{ width: '55px', height: '40px' }} />
                        </div>
                        <h3>Soluções para Indústria</h3>
                        <p>Sistemas ERP para Gestão de vários segmentos Industriais, baixe nossos manuais ou entre em contato com nosso suporte.</p>
                        <NavLink to="/solucoes">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                    <div className="card-ajuda">
                        <div className="elemento-centralizado">
                            <img src={comercio} alt="Ícone de comércio" style={{ width: '44px', height: '40px' }} />
                        </div>
                        <h3>Soluções para Comércio</h3>
                        <p>Sistemas Gestão de vários segmentos do Comércio, baixe nossos manuais ou entre em contato com nosso suporte.</p>
                        <NavLink to="/solucoes">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                    <div className="card-ajuda">
                        <div className="elemento-centralizado">
                            <img src={servicos} alt="Ícone de serviços" style={{ width: '40px', height: '40px' }} />
                        </div>
                        <h3>Soluções para Serviços</h3>
                        <p>Sistemas Gestão de vários segmentos da área de Serviços, Clique abaixo para conhecer nossas soluções e confira.</p>
                        <NavLink to="/solucoes">
                            <Botao texto={'Saiba Mais'} />
                        </NavLink>
                    </div>
                </div>
                <div className="elemento-centralizado">
                    <NavLink to="/solucoes">
                        <Botao texto={'Explore Aqui!'} />
                    </NavLink>
                </div>
            </section>
            <section className="home-conheca">
                <h2>Conheça na Exactus, as melhores Soluções Fiscais e Contábeis</h2>
                <div className="container-conheca">
                    <div>
                        <h3>+70<span>mil</span></h3>
                        <p>Empresas atendidas pela Plataforma Exactus</p>
                    </div>
                    <div>
                        <h3>+1000</h3>
                        <p>Escritórios de Contabilidade preferem Exactus</p>
                    </div>
                    <div>
                        <h3>+160</h3>
                        <p>Centrais de Atendimento em Todo Brasil</p>
                    </div>
                </div>
            </section>
            <section className="home-banner-final">
                <div className="container-banner-final">
                    <h2>Do Presente ao Futuro, a Exactus nos Conecta!</h2>
                    <NavLink to="/institucional">
                        <Botao texto={'Conheça mais sobre a Exactus!'} />
                    </NavLink>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Home;
