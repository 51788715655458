import React, { useContext, useEffect } from "react";
import Banner from '../../../components/Banner/Banner'
import Planos from "../../../components/Planos/Planos";
import Beneficios from '../../../components/Beneficios/Beneficios'
import BotaoBranco from "../../../components/Botao/BotaoBranco";
import Footer from "../../../components/Footer/Footer";
import '../../Planos/style.css'
import escritafiscal from '../../../images/Planos/escritafiscal.webp'
import folha from '../../../images/Planos/folha.webp'
import contabilidade from '../../../images/Planos/contabilidade.webp'
import patrimonial from '../../../images/Planos/patrimonialw.webp'
import beneficios from '../../../images/Planos/portal.webp'
import check from '../../../images/PlanosAssinatura/check.svg'
import { NavLink } from 'react-router-dom'
import { EmailContext } from "../../../context/EmailContext";

function PlanoMaxx() {
    const { setPdf } = useContext(EmailContext)
    useEffect(() => {
        setPdf("")
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner titulo={'Plano Maxx'} texto={'Eleve o potencial da sua Empresa com este plano Definitivo. O Plano MAXX oferece todas as melhores ferramentas desenvovidas pela EXACTUS!'} textoBotao={'Entre em Contato!'} classe={'banner-maxx'} origem={"Assunto: Assinatura Plano MAXX"} nomeProduto={"Plano Maxx"} />
            <Planos titulo={'O que é o Plano MAXX?'} texto={'O Plano Maxx é a expressão máxima da excelência em serviços contábeis oferecidos pela Exactus Intelligence. Esta solução abrangente incorpora todas as melhores ferramentas disponíveis em nossa gama de serviços, incluindo escrita fiscal, folha de pagamento, contabilidade, gestão de faturamento e honorários, módulo financeiro, E-Busca XML e Módulo CND. É a escolha definitiva para empresas que buscam a mais alta eficiência e conformidade contábil.'} />
            <section className="plano-modulos">
                <h2>Módulos do Plano MAXX</h2>
                <div className="container-modulos">
                    <img src={escritafiscal} alt="Imagem de escrita fiscal" />
                    <div className="content-modulos-direita">
                        <h3>Escrita Fiscal</h3>
                        <h4>Skill</h4>
                        <p>Escrita Fiscal Simplificada e Eficiente. Automatize obrigações fiscais, emissão de guias, controle de impostos e integração contábil. Ganhe tempo e evite erros.</p>
                        <div>
                            <NavLink to="/fiscal">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="container-modulos">
                    <div className="content-modulos-esquerda">
                        <h3>Folha de Pagamento</h3>
                        <h4>Winner</h4>
                        <p>Plataforma robusta simplifica e agiliza todas as etapas da Folha de Pagamento, proporcionando uma experiência completa para o mercado.</p>
                        <div>
                            <NavLink to="/folha-de-pagamento">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                    <img src={folha} alt="Imagem de folha de pagamento" />
                </div>
                <div className="container-modulos">
                    <img src={contabilidade} alt="Imagem de contabilidade" />
                    <div className="content-modulos-direita">
                        <h3>Contabilidade</h3>
                        <h4>Maxximum</h4>
                        <p>Sistema eficiente é mais do que apenas um software; é uma solução completa que otimiza todas as suas operações contábeis.</p>
                        <div>
                            <NavLink to="/contabil">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="container-modulos">
                    <div className="content-modulos-esquerda">
                        <h3>Patrimonial</h3>
                        <h4>Prattic</h4>
                        <p>O Seu Parceiro Eficiente para Controle Patrimonial.</p>
                        <div>
                            <NavLink to="/patrimonial">
                                <BotaoBranco texto={'Saiba mais'} />
                            </NavLink>
                        </div>
                    </div>
                    <img src={patrimonial} alt="Imagem de patrimônio" />
                </div>
                <div className="container-beneficios">
                    <img src={beneficios} alt="Imagem de portal" />
                    <div className="content-modulos-direita">
                        <h3>Benefícios</h3>
                        <div className="beneficios-modulos">
                            <span>Porta do Cliente <img src={check} alt="Ícone de check" /></span>
                            <span>Portal do Colaborador <img src={check} alt="Ícone de check" /></span>
                            <span>Faturamento e Honorários <img src={check} alt="Ícone de check" /></span>
                            <span>Módulo Financeiro <img src={check} alt="Ícone de check" /></span>
                            <span>Tarefas e Processos <img src={check} alt="Ícone de check" /></span>
                            <span>e-Busca XML <img src={check} alt="Ícone de check" /></span>
                            <span>CND <img src={check} alt="Ícone de check" /></span>
                        </div>
                    </div>
                </div>
            </section>
            <Beneficios tituloBeneficio={'Benefícios do Plano MAXX'} tituloServico={'Serviços Disponíveis'} qtdUsuarios={'15'} qtdEmpresas={'Ilimitado'} qtdWeb={'Adicionado por usuário'} servicoSuporte={'Multicanais'} servicoTreinamento={'Multicanais'} textoBotao={'Contratar Plano MAXX'} plano={'Plano Maxx'} />
            <Footer />
        </>
    )
}

export default PlanoMaxx