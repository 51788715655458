import descritivo from '../../images/ERP/descritivo.svg'

function SectionTecnologia({ texto, imagem }) {
    return (
        <section className="planos-imagem">
            <div className="elemento-centralizado">
                <img src={descritivo} alt="Ícone" style={{ width: '65px', height: '65px' }} />
            </div>
            <h2>Como funciona essa Tecnologia?</h2>
            <div className='elemento-centralizado'>
            <p style={{width: '80%'}}>{texto}</p>
            </div>
            <div className='elemento-centralizado'>
                <img src={imagem} alt='Esquema' width='80%' />
            </div>
        </section>
    )
}

export default SectionTecnologia