import React, { useContext } from "react";
import './style.css'
import Botao from "../../components/Botao/Botao";
import Footer from "../../components/Footer/Footer";
import { NavLink } from "react-router-dom";
import { CompraContext } from "../../context/CompraContext";
import Lottie from "lottie-react";
import json from '../../images/animation_lnypqqsr.json'

function CompraFinalizada() {
    const { ProdutoCompra } = useContext(CompraContext)
    return (
        <>
            <div className="compra-finalizada">
                <h2>Sua compra foi feita com sucesso!</h2>
                <div style={{ width: '100px', height: '100px', margin: '0 auto 30px auto' }}>
                    <Lottie animationData={json} loop={false} />
                </div>
                <p>Parabéns! Você acabou de adquirir o <b>{ProdutoCompra.resumo}</b>.</p>
                <p>Você receberá um e-mail com os passos para adquirir seu certificado.</p>
                <p>Qualquer dúvida entre em contato com <b>comercial@exactus.net.br</b></p>
                <NavLink to="/">
                    <Botao texto={'Voltar para a home'} />
                </NavLink>
            </div>
            <Footer footerValid={true} certificado={true}/>
        </>
    )
}

export default CompraFinalizada