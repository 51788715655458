import React, { useEffect } from "react";
import Banner from '../../../components/Banner/Banner'
import Botao from "../../../components/Botao/Botao";
import Footer from "../../../components/Footer/Footer";
import check from '../../../images/ERP/check (3).svg'
import SectionCertificado from "../../../components/Section/SectionCertificado";
import { NavLink } from 'react-router-dom'

function CertificadoSaibaMaisNfe() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner titulo={'Exactus Certify+'} texto={'A Exactus tem o certificado certo para você! Adquira ou renove seu Certificado com a Exactus Certify+'} classe={'banner-certify'} origem={"Certificado Digital"} nomeProduto={"Certificado Digital"}/>
            <section className="razoes">
                <h2 style={{ textAlign: 'start', margin: '0 0 30px 0' }}>O que é um Certificado Digital NF-e?</h2>
                <p>O Certificado Digital NF-e é a ferramenta que garante a autenticidade e a integridade das notas fiscais eletrônicas, elevando a segurança das transações comerciais e o cumprimento das obrigações fiscais.</p>
            </section>
            <section className="planos">
                <h2 style={{ margin: '0 0 30px 0' }}>Vantagens do Certificado Digital NF-e:</h2>
                <div className="certificado-itens">
                    <ul>
                        <li><img src={check} alt='Ícone de check' /><b>Emissão Rápida e Descomplicada:</b></li>
                        <li>Emita notas fiscais eletrônicas de forma rápida e descomplicada, reduzindo o tempo gasto nesse processo e evitando retrabalhos.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Conformidade com a Legislação Fiscal:</b></li>
                        <li>Mantenha sua empresa em conformidade com a legislação fiscal, evitando penalidades e garantindo a integridade das informações contidas nas notas fiscais.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Assinatura Digital com Validade Jurídica:</b></li>
                        <li>Assine digitalmente suas notas fiscais, conferindo a elas validade jurídica e garantindo a autenticidade das transações comerciais.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Facilidade na Transmissão para Órgãos Fiscais:</b></li>
                        <li>Simplifique a transmissão de informações fiscais para os órgãos competentes, garantindo o cumprimento das obrigações legais de forma eficiente.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Acesso a Ambientes Corporativos:</b></li>
                        <li>Garanta o acesso seguro a ambientes corporativos, integrando sua empresa a plataformas e sistemas que exigem a autenticação via Certificado Digital.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Controle de Acesso:</b></li>
                        <li>Reforce o controle de acesso às informações fiscais da sua empresa, garantindo que apenas usuários autorizados possam emitir e gerenciar notas fiscais eletrônicas.</li>
                    </ul>
                </div>
            </section>
            <SectionCertificado />
            <section className="planos">
                <h2 style={{textAlign: 'center'}}>Adquirir meu Certificado Digital NF-e?</h2>
                <div className="elemento-centralizado">
                    <NavLink to="/certificado">
                        <Botao texto={'Adquira aqui seu Certificado Digital'} />
                    </NavLink>
                </div>
            </section>
            <Footer footerValid={true} />
        </>
    )
}

export default CertificadoSaibaMaisNfe