import React, { useEffect } from "react";
import Banner from '../../../components/Banner/Banner'
import Botao from "../../../components/Botao/Botao";
import Footer from "../../../components/Footer/Footer";
import check from '../../../images/ERP/check (3).svg'
import SectionCertificado from "../../../components/Section/SectionCertificado";
import { NavLink } from 'react-router-dom'

function CertificadoSaibaMaisCnpj() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner titulo={'Exactus Certify+'} texto={'A Exactus tem o certificado certo para você! Adquira ou renove seu Certificado com a Exactus Certify+'} classe={'banner-certify'} link={"/certificado"} origem={"Certificado Digital"} nomeProduto={"Certificado Digital"}/>
            <section className="razoes">
                <h2 style={{ textAlign: 'start', margin: '0 0 30px 0' }}>O que é um e-CNPJ?</h2>
                <p>O Certificado Digital e-CNPJ é uma ferramenta tecnológica avançada que permite a identificação eletrônica de empresas e empresários, garantindo a autenticidade e a integridade de documentos e transações online. Ele é a versão digital do CNPJ (Cadastro Nacional da Pessoa Jurídica), elevando a segurança das operações comerciais para um novo patamar.</p>
            </section>
            <section className="planos">
                <h2 style={{ margin: '0 0 30px 0' }}>Vantagens do Certificado Digital e-CNPJ:</h2>
                <div className="certificado-itens">
                    <ul>
                        <li><img src={check} alt='Ícone de check' /><b>Segurança Jurídica:</b></li>
                        <li>Assine digitalmente contratos e documentos com validade jurídica, garantindo a autenticidade das transações e a segurança das informações.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Acesso a Serviços Públicos:</b></li>
                        <li>Simplifique sua relação com órgãos públicos, como Receita Federal, acessando serviços online de forma rápida e segura.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Facilidade nas Transações Bancárias:</b></li>
                        <li>Agilize o processo de transações bancárias e reduza a burocracia ao utilizar seu Certificado Digital e-CNPJ.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Nota Fiscal Eletrônica (NF-e):</b></li>
                        <li>Emita suas notas fiscais eletrônicas de forma ágil e segura, cumprindo todas as exigências fiscais.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Acesso a Sistemas Corporativos:</b></li>
                        <li>Garanta o acesso seguro a sistemas corporativos e plataformas online, protegendo informações estratégicas da sua empresa.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Assinatura Digital de Documentos:</b></li>
                        <li>Assine contratos, procurações e outros documentos de forma eletrônica, economizando tempo e recursos.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Impulsione a Credibilidade do seu Negócio:</b></li>
                        <li>Demonstre comprometimento com a segurança e a transparência, fortalecendo a confiança dos clientes, parceiros e investidores.</li>
                    </ul>
                </div>
            </section>
            <SectionCertificado />
            <section className="planos">
                <h2 style={{textAlign: 'center'}}>Adquirir meu Certificado Digital e-CNPJ:</h2>
                <div className="elemento-centralizado">
                    <NavLink to="/certificado">
                        <Botao texto={'Adquira aqui seu Certificado Digital'} />
                    </NavLink>
                </div>
            </section>
            <Footer footerValid={true} />
        </>
    )
}

export default CertificadoSaibaMaisCnpj