import "./style.css";
function CardBuscar({ imagem, titulo, conteudo }) {
  return (
    <div className="card-buscar">
      <img src={imagem} alt="imagem card" style={{ width: "50px" }} />
      <span>{titulo}</span>
      <p>{conteudo}</p>
    </div>
  );
}

export default CardBuscar;
