import React, { createContext, useState } from "react";
import baseurl from "../services/baseurl";
import publickey from "../services/publickey";
import axios from "axios";

export const CompraContext = createContext()

export const CompraContextProvider = ({ children }) => {
    const token = 'D8ADD116-5203-4571-B3D3-73ECC007A13E'

    const [msgSucesso, setMsgSucesso] = useState("")
    const [msgErro, setMsgErro] = useState('')
    const [loading, setLoading] = useState('')

    const [Nome, setNome] = useState('')
    const [NumeroCartao, setNumeroCartao] = useState('')
    const [DataValidade, setDataValidade] = useState('')
    const [CodSeguranca, setCodSeguranca] = useState('')

    const [Titulo, setTitulo] = useState('')
    const [Icone, setIcone] = useState('')
    const [Texto, setTexto] = useState('')
    const [Formato, setFormato] = useState('')
    const [Validade, setValidade] = useState('')
    const [Preco, setPreco] = useState('')

    const [Cadastro, setCadastro] = useState('')
    const [Produto, setProduto] = useState()
    const [ProdutoCompra, setProdutoCompra] = useState()

    const [QRCode, setQRCode] = useState('')
    const [pixCola, setPixCola] = useState('')
    const [transactionId, setTransactionId] = useState('')

    const [DadosPix, setDadosPix] = useState("")

    const [abreModal, setAbreModal] = useState(false)

    const [Cupom, setCupom] = useState('')
    const [Descricao, setDescricao] = useState('')
    const [Desconto, setDesconto] = useState("")

    const [Revenda, setRevenda] = useState("")
    const [VendaKey, setVendaKey] = useState("")


    const encryptCardDetails = async (cupom, vendaKey) => {
        const card = window.PagSeguro.encryptCard({
            publicKey: publickey,
            holder: Nome,
            number: NumeroCartao.replace(/\s/g, ''),
            expMonth: DataValidade.split("/")[0],
            expYear: DataValidade.split("/")[1],
            securityCode: CodSeguranca
        });

        const encrypted = card.encryptedCard;
        const hasErrors = card.hasErrors;
        const errors = card.errors;

        const errorMessages = {
            INVALID_NUMBER: 'Número de cartão inválido',
            INVALID_EXPIRATION_MONTH: 'Mês de validade inválido',
            INVALID_EXPIRATION_YEAR: 'Ano de validade inválido',
            INVALID_HOLDER: 'Titular inválido',
        };

        if (hasErrors) {
            const errorCodes = card.errors.map(error => error.code);
            const errorReasons = errorCodes.map(errorCode => {
                const errorMessage = errorMessages[errorCode] || 'Erro desconhecido';
                return `${errorCode}: ${errorMessage}`;
            });

            errorReasons.forEach(reason => {
                setMsgErro(reason);
                setTimeout(() => {
                    setMsgErro("");
                }, 6000);
            });

            throw new Error('Erro de validação');
        } else {
            await Cartao(encrypted, cupom, vendaKey);
        }
    }

    const Cartao = async (encrypt, cupom, vendaKey) => {
        setLoading(true)
        const data = {
            cardToken: encrypt,
            cardToken_Holder: Nome,
            cupom: cupom,
            vendaKey: vendaKey
        }
        return axios({
            url: baseurl + 'PagamentoCartao/ProcessaPagamento',
            method: 'POST',
            headers: { Token: `${token}` },
            data: data
        })
            .then((response) => {
                setLoading(false)
                setCadastro('')
                return Promise.resolve()
            })
            .catch((error) => {
                setLoading(false)
                setMsgErro(error.response.data.returnMessage)
                setTimeout(() => {
                    setMsgErro("");
                }, 6000);
                return Promise.reject(error)
            })
    }

    const GeraPix = async (cupom, vendaKey) => {
        setLoading(true)
        setDadosPix('')
        const data = {
            duracaoEmMinutos: '90',
            cupom: cupom,
            vendaKey: vendaKey
        }
        console.log(data)
        return axios({
            url: baseurl + 'PagamentoPix/GeraPix',
            method: 'POST',
            headers: { Token: `${token}` },
            data: data
        })
            .then((response) => {
                setAbreModal(true)
                setLoading(false)
                setQRCode(response.data.imageContentPNG)
                setPixCola(response.data.pixCola)
                setTransactionId(response.data.transactionId)
                return Promise.resolve()
            })
            .catch((error) => {
                setLoading(false)
                return Promise.reject(error)
            })
    }

    const ConsultaPix = async (transactionId, vendaKey) => {
        setLoading(true)
        const data = {
            transactionId: transactionId,
            vendaKey: vendaKey
        }
        return axios({
            url: baseurl + 'PagamentoPix/ConsultaPix',
            method: 'POST',
            headers: { Token: `${token}` },
            data: data
        })
            .then((response) => {
                setLoading(false)
                setDadosPix(response.data)
                return Promise.resolve(response)
            })
            .catch((error) => {
                setAbreModal(false)
                setMsgErro(error)
                setTimeout(() => {
                    setMsgErro("");
                }, 6000);
                return Promise.reject(error)
            })
    }

    const ObterProdutos = async () => {
        return axios({
            url: baseurl + 'Produtos/ObterTodos',
            method: 'GET',
            headers: { Token: `${token}` }
        })
            .then((response) => {
                setProduto(response.data)
                return Promise.resolve()
            })
            .catch((error) => {
                return Promise.reject(error)
            })
    }

    const ConsultaProdutos = async (id, anoId) => {
        return axios({
            url: baseurl + 'Produtos/ConsultaProduto?IdProduto=' + id + '&IdAno=' + anoId,
            method: 'GET',
            headers: { Token: `${token}` }
        })
            .then((response) => {
                setProdutoCompra(response.data)
                return Promise.resolve()
            })
            .catch((error) => {
                return Promise.reject(error)
            })
    }

    const ValidaCupom = async (descricao) => {
        return axios({
            url: baseurl + 'Cupom/CupomValido?Descricao=' + descricao,
            method: 'GET',
            headers: { Token: `${token}` }
        })
            .then((response) => {
                setDesconto(response.data.descontoPorcentagem)
                setCupom(response.data.descricao)
                setMsgSucesso("Cupom adicionado com sucesso.")
                setTimeout(() => {
                    setMsgSucesso("");
                }, 6000);
                return Promise.resolve()
            })
            .catch(function (error) {
                setCupom('')
                error.response.data.map((er) => {
                    setMsgErro(`${er.campo}: ${er.mensagem}`)
                    setTimeout(() => {
                        setMsgErro("");
                    }, 6000);
                })
            });
    }

    const GeraVenda = async (codProduto, codAno, revenda) => {
        setLoading(true)
        const data = {
            documento: Cadastro.documento,
            email: Cadastro.email,
            telefone: Cadastro.telefone,
            endereco: Cadastro.endereco,
            enderecoNumero: Cadastro.enderecoNumero,
            enderecoComplemento: Cadastro.enderecoComplemento,
            enderecoBairro: Cadastro.enderecoBairro,
            cep: Cadastro.cep,
            cidade: Cadastro.cidade,
            uf: Cadastro.uf,
            pais: 'BRA',
            nome: Cadastro.nome,
            representante: Cadastro.representante,
            produtoId: codProduto,
            anoId: codAno,
            revendaId: revenda,
        }
        console.log(data)
        return axios({
            url: baseurl + 'Venda/Gerar',
            method: 'POST',
            headers: { Token: `${token}` },
            data: data
        })
            .then((response) => {
                setVendaKey(response.data)
                console.log(response)
                setLoading(false)
                return Promise.resolve(response)
            })
            .catch((error) => {
                setAbreModal(false)
                console.log(error)
                // error.response.data.map((er) => {
                //     console.log(er)
                // })
                // setMsgErro(error)
                // setTimeout(() => {
                //     setMsgErro("");
                // }, 6000);
                return Promise.reject(error)
            })
    }

    let data = {
        Nome,
        setNome,
        NumeroCartao,
        setNumeroCartao,
        DataValidade,
        setDataValidade,
        CodSeguranca,
        setCodSeguranca,
        Titulo, setTitulo,
        Icone, setIcone,
        Texto, setTexto,
        Formato, setFormato,
        Validade, setValidade,
        Preco, setPreco,
        GeraPix,
        ConsultaPix,
        DadosPix,
        Cartao,
        Cadastro,
        setCadastro,
        abreModal, setAbreModal,
        QRCode,
        pixCola,
        transactionId, setTransactionId,
        msgSucesso, setMsgSucesso,
        msgErro, setMsgErro,
        encryptCardDetails,
        loading, setLoading,
        ObterProdutos,
        Produto, setProduto,
        ConsultaProdutos,
        ProdutoCompra, setProdutoCompra,
        ValidaCupom,
        Cupom, setCupom,
        Descricao, setDescricao,
        Desconto, setDesconto,
        Revenda, setRevenda,
        GeraVenda,
        VendaKey, setVendaKey
    }

    return (
        <CompraContext.Provider value={data}>
            {children}
        </CompraContext.Provider>
    )
}