import React, { useContext } from "react";
import './style.css'
import Botao from "../Botao/Botao";
import usuario from '../../images/Beneficios/usuarios.svg'
import empresas from '../../images/Beneficios/empresas.svg'
import web from '../../images/Beneficios/web.svg'
import suporte from '../../images/Beneficios/suporte.svg'
import treinamento from '../../images/Beneficios/treinamento.svg'
import migracao from '../../images/Beneficios/migracao.svg'
import implantacao from '../../images/Beneficios/implantacao.svg'
import { EmailContext } from "../../context/EmailContext";
import { useNavigate } from "react-router-dom";

function Beneficios({ tituloBeneficio, tituloServico, qtdUsuarios, qtdEmpresas, qtdWeb, servicoSuporte, servicoTreinamento, textoBotao, plano }) {
    const { setContabilidade } = useContext(EmailContext)

    const navigate = useNavigate()

    const contratar = (plano) => {
        setContabilidade(plano)
        navigate('/contato')
    }
    return (
        <section className="beneficios">
            <h2>{tituloBeneficio}</h2>
            <div className="container-beneficios">
                <div className="card-beneficios">
                    <div className="elemento-centralizado">
                        <img src={usuario} alt="Ícone de usuários" />
                    </div>
                    <h3>Usuários</h3>
                    <p>{qtdUsuarios}</p>
                </div>
                <div className="card-beneficios">
                    <div className="elemento-centralizado">
                        <img src={empresas} alt="Ícone de empresas" />
                    </div>
                    <h3>Empresas</h3>
                    <p>{qtdEmpresas}</p>
                </div>
                <div className="card-beneficios">
                    <div className="elemento-centralizado">
                        <img src={web} alt="Ícone de WEB" />
                    </div>
                    <h3>Exactus WEB</h3>
                    <p>{qtdWeb}</p>
                </div>
            </div>
            <h2>{tituloServico}</h2>
            <div className="container-servicos">
                <div className="card-servicos">
                    <div className="elemento-centralizado">
                        <img src={suporte} alt="Ícone de suporte" />
                    </div>
                    <h3>Suporte</h3>
                    <p>{servicoSuporte}</p>
                </div>
                <div className="card-servicos">
                    <div className="elemento-centralizado">
                        <img src={treinamento} alt="Ícone de treinamento" />
                    </div>
                    <h3>Treinamento</h3>
                    <p>{servicoTreinamento}</p>
                </div>
                <div className="card-servicos">
                    <div className="elemento-centralizado">
                        <img src={migracao} alt="Ícone de migração" />
                    </div>
                    <h3>Migração</h3>
                    <p>Solicitar orçamento</p>
                </div>
                <div className="card-servicos">
                    <div className="elemento-centralizado">
                        <img src={implantacao} alt="Ícone de implantação" />
                    </div>
                    <h3>Implantação</h3>
                    <p>Solicitar orçamento</p>
                </div>
            </div>
            <div className="elemento-centralizado">
                <Botao texto={textoBotao} onClick={() => contratar(plano)} />
            </div>
        </section>
    )
}

export default Beneficios