import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";

function PoliticaReembolso() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="politica">
                <h1>Política de Reembolso</h1>
                <p><b>Política de Reembolso</b></p>
                <p><b>Objetivo</b></p>
                <p>Esta política visa esclarecer nossos clientes em caso de solicitação de desistência da compra e/ou reembolso em relação aos serviços e produtos comercializados no site <a href="https://validcertificadora.com.br/">https://validcertificadora.com.br/</a></p>
                <p><b>Beneficiários</b></p>
                <p>Aplica-se a Pessoa Física ou Pessoa Jurídica que tenha adquirido os serviços e/ou produtos fornecidos pela Valid Certificadora.</p>
                <p><b>Direito de Arrependimento</b></p>
                <p>O cliente poderá desistir da aquisição do certificado digital no prazo de até 7 (sete) dias, a contar da data da COMPRA. Para que seja possível o reembolso do cliente pela desistência do produto, o certificado digital emitido deverá ser revogado pelo cliente.
                    <br />
                    <br />
                    A revogação do certificado digital deve ser realizada pelo titular e/ou responsável legal pelo uso do certificado digital via site, telefone ou na localidade onde realizou a emissão. Clique aqui para saber como realizar a revogação.</p>
                <p><b>Procedimentos para solicitação de reembolso</b></p>
                <p>Conforme artigo 49 do Código de Defesa do Consumidor Lei 8.078/90, a solicitação de desistência de um produto ou serviço poderá ocorrer no prazo de 7 (sete) dias, a contar da data de compra/contratação.
                    <br />
                    <br />
                    Para solicitar o reembolso, o cliente deverá acessar o site da Valid Certificadora, e preencher corretamente o formulário. Veja mais abaixo como preencher.</p>
                <p><b>Como funciona</b></p>
                <p>- Caso o pagamento tenha sido realizado por cartão de crédito, o estorno é realizado para o cartão onde foi realizada a compra;
                    <br />
                    <br />
                    - Caso o pagamento tenha sido realizado por boleto, escolha de que forma deseja receber o estorno, se por transferência bancária ou ordem de pagamento.Para transferência bancária: é necessário informar os dados bancários em nome do titular da compra, como código e nome do banco, número da agência e conta corrente.Para ordem de pagamento: é realizada em uma agência Itaú mais próxima a você. Basta informar qual o número da agência desejada e os dados do responsável pela retirada, como nome, CPF e RG;
                    <br />
                    <br />
                    - É necessário o envio do comprovante de pagamento.</p>
                <p><b>Prazos</b></p>
                <p><b>Para pagamentos com cartão de crédito:</b></p>
                <p>O fluxo poderá durar até 30 dias, a partir da data da solicitação, podendo ocorrer antes deste prazo.
                    <br />
                    <br />
                    A Valid solicitará o cancelamento à operadora do cartão. O processo de estorno da compra será feito conforme a política da operadora do cartão e não da Valid Certificadora.
                    <br />
                    <br />
                    Após a realização do estorno, acompanhe sua fatura. Em caso de dúvida, acione a operadora de seu cartão de crédito.</p>
                <p><b>Para pagamentos com boleto bancário:</b></p>
                <p>O fluxo poderá durar até 30 dias, a partir da data da solicitação, podendo ocorrer antes deste prazo.
                    <br />
                    <br />
                    Se a opção desejada foi por transferência bancária, o reembolso será realizado por depósito bancário na conta da pessoa física ou jurídica informada nos dados de faturamento da Nota Fiscal.
                    <br />
                    <br />
                    Caso seja ordem de pagamento, após disponibilizada a ordem, você possui até 60 dias para a retirada. Passado este prazo, será necessária nova solicitação.
                    <br />
                    <br />
                    Havendo divergências, falta de informações ou inconsistência dos dados bancários com o titular constante na Nota Fiscal para que o reembolso seja processado, o prazo de até 30 dias se reiniciará somente após as devidas correções passadas pelo cliente, por isso a importância de enviar as informações corretas e completas.
                    <br />
                    <br />
                    Fora o prazo citado, o reembolso só será admitido nos casos cobertos pela Política de Garantia, os quais serão constatados após análise e mediante laudo emitido pela equipe técnica da Valid Certificadora, considerando as diretrizes conforme disposta em nosso site.</p>
            </div>
            <Footer />
        </>
    )
}

export default PoliticaReembolso