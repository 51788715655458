import './style.css'

function Accordion() {
    return (
        <section className='razoes'>
            <h2>Dúvidas frequentes:</h2>
            <div class="accordion">
                <span class="target-fix" id="accordion"></span>
                <div>
                    <span class="target-fix" id="accordion1"></span>

                    <a href="#accordion1" id="open-accordion1" title="open">1 . O Patrimonial efetua quais tipos de cálculo?</a>
                    <a href="#accordion" id="close-accordion1" title="close">1 . O Patrimonial efetua quais tipos de cálculo?</a>

                    <div class="accordion-content">
                        <p>O sistema efetua o cálculo da Depreciação, Amortização ou Exaustão.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion2"></span>
                    <a href="#accordion2" id="open-accordion2" title="open">2 .  Como o sistema diferencia os itens cadastrados?</a>
                    <a href="#accordion" id="close-accordion2" title="close">2 .  Como o sistema diferencia os itens cadastrados?</a>
                    <div class="accordion-content">
                        <p>Através do cadastro do Plano de Contas. Nesse cadastro serão inseridas as principais informações para cálculo da depreciação, amortização ou exaustão, como por exemplo a taxa, e as conta de despesa, para que o sistema contabilize posteriormente essas informações.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion3"></span>
                    <a href="#accordion3" id="open-accordion3" title="open">3 .  O sistema permite a Implantação de um imobilizado em andamento?</a>
                    <a href="#accordion" id="close-accordion3" title="close">3 .  O sistema permite a Implantação de um imobilizado em andamento?</a>
                    <div class="accordion-content">
                        <p>Sim, existe a possibilidade de inclusão de duas formas, sendo elas pela Implantação, onde será informado o valor do bem e sua depreciação acumulada, ou a Implantação Retroativa, onde ao informar o valor atualizado do bem, o sistema irá calcular toda a depreciação desde a data informada da aquisição.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion4"></span>
                    <a href="#accordion4" id="open-accordion4" title="open">4 .Posso efetuar baixa parcial de um imobilizado?</a>
                    <a href="#accordion" id="close-accordion4" title="close">4 .Posso efetuar baixa parcial de um imobilizado?</a>
                    <div class="accordion-content">
                        <p>O sistema permite que sejam efetuadas baixas parciais de um item, bem como a baixa total. Existe ainda a possibilidade de efetuar a baixa por Intervalo de Itens.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion5"></span>
                    <a href="#accordion5" id="open-accordion5" title="open">5 .  Consigo verificar de forma rápida, o valor residual de um item?</a>
                    <a href="#accordion" id="close-accordion5" title="close">5 .  Consigo verificar de forma rápida, o valor residual de um item?</a>
                    <div class="accordion-content">
                        <p>Sim, na Consultas de Itens, é possível verificar todo o histórico do item, desde os valores da aquisição, depreciação acumulada e o valor residual na data do cálculo.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion6"></span>
                    <a href="#accordion6" id="open-accordion6" title="open">6 . Existem filtros que possibilitem a impressão dos relatórios do Patrimonial de acordo com informações do bem?</a>
                    <a href="#accordion" id="close-accordion6" title="close">6 . Existem filtros que possibilitem a impressão dos relatórios do Patrimonial de acordo com informações do bem?</a>
                    <div class="accordion-content">
                        <p>Diversos relatórios possibilitam a impressão detalhada do bem cadastrado, com diversas informações de cadastro, como por exemplo Centro de Custos, Local, Seguradora, descrição, fornecedor, entre outros. </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Accordion