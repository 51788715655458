import React, { useContext, useEffect, useState } from "react";
import Botao from '../../components/Botao/Botao'
import Footer from "../../components/Footer/Footer";
import './style.css'
import { NavLink, useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { CompraContext } from "../../context/CompraContext";
import Erro from "../../components/Alert/Erro";
import { validateCPF, validateCNPJ, validateCep, validateUF, validateEmail, validatePhone } from 'validations-br';

function Cadastro() {
    const { Cadastro, setCadastro, msgErro, setMsgErro, setAbreModal, GeraVenda, ProdutoCompra, Revenda } = useContext(CompraContext)
    const [check, setCheck] = useState(false)
    const [dados, setDados] = useState(false)
    const navigate = useNavigate()

    const handleChange = (event) => {
        if (event.target.name === 'uf') {
            event.target.value = event.target.value.toUpperCase();
        }
        setCadastro({ ...Cadastro, [event.target.name]: event.target.value })
    }

    const handleCheckbox = (event) => {
        setCheck(event.target.checked)
    }

    const handleDados = (event) => {
        setDados(event.target.value)
    }

    const verifica = () => {
        if (typeof Cadastro.email === 'undefined' || Cadastro.email === '') {
            setMsgErro("Campo email obrigatório")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        } else if (!validateEmail(Cadastro.email)) {
            setMsgErro("E-mail inválido")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        if (typeof Cadastro.nome === 'undefined' || Cadastro.nome === '') {
            setMsgErro("Campo nome obrigatório")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        if (typeof Cadastro.documento === 'undefined' || Cadastro.documento === '') {
            setMsgErro("Campo CPF/CNPJ obrigatório")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        } else if (!validateCPF(Cadastro.documento) && !validateCNPJ(Cadastro.documento)) {
            setMsgErro("CPF/CNPJ inválido")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        if (typeof Cadastro.telefone === 'undefined' || Cadastro.telefone === '') {
            setMsgErro("Campo celular obrigatório")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        } else if (!validatePhone(Cadastro.telefone)) {
            setMsgErro("Telefone inválido")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        if (typeof Cadastro.cep === 'undefined' || Cadastro.cep === '') {
            setMsgErro("Campo CEP obrigatório")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        } else if (!validateCep(Cadastro.cep)) {
            setMsgErro("CEP inválido")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        if (typeof Cadastro.enderecoBairro === 'undefined' || Cadastro.enderecoBairro === '') {
            setMsgErro("Campo bairro obrigatório")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        if (typeof Cadastro.endereco === 'undefined' || Cadastro.endereco === '') {
            setMsgErro("Campo endereço obrigatório")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        if (typeof Cadastro.enderecoNumero === 'undefined' || Cadastro.enderecoNumero === '') {
            setMsgErro("Campo número obrigatório")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        if (typeof Cadastro.uf === 'undefined' || Cadastro.uf === '') {
            setMsgErro("Campo UF obrigatório")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        } else if (!validateUF(Cadastro.uf)) {
            setMsgErro("UF inválido")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        if (typeof Cadastro.cidade === 'undefined' || Cadastro.cidade === '') {
            setMsgErro("Campo cidade obrigatório")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        if (!check) {
            setMsgErro("Aceitar Política de Privacidade é obrigatório.")
            setTimeout(() => {
                setMsgErro("");
            }, 6000);
            return
        }
        GeraVenda(ProdutoCompra.id, ProdutoCompra.anos[0].id, Revenda)
        navigate("/compra")
    }

    useEffect(() => {
        setAbreModal(false)
        window.scrollTo(0, 0);
        setCadastro('')
    }, []);

    return (
        <>
            {msgErro &&
                <Erro />
            }
            <div className="cadastro">
                <div className="alert">NOVIDADE: compre ou renove o seu Certificado Digital com pagamento via PIX.</div>
                <p style={{ textAlign: 'center' }}><b>Dados Cadastrais</b> {'>'} Pagamento</p>
                <div className="container-cadastro">
                    <div className="input">
                        <label htmlFor="email">E-mail*</label>
                        <input required type="email" name="email" id="email" value={Cadastro.email} onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="input-checkbox">
                        <input type="checkbox" name="info" id="info" />
                        <label htmlFor="info">Aceito receber informações e novidades da EXACTUS por e-mail</label>
                    </div>
                    <h2>Dados Pessoais</h2>
                    <div style={{ display: 'flex', margin: '15px 0' }}>
                        <div style={{ marginRight: '20px' }}>
                            <input type="radio" name="dados" value="pessoaFisica" style={{ marginRight: '10px' }} onChange={(e) => handleDados(e)} />
                            <label htmlFor="pessoaFisica">Pessoa Física</label>
                        </div>
                        <div>
                            <input type="radio" name="dados" value="pessoaJuridica" style={{ marginRight: '10px' }} onChange={(e) => handleDados(e)} />
                            <label htmlFor="pessoaJuridica">Pessoa Jurídica</label>
                        </div>
                    </div>
                    {dados == 'pessoaFisica'
                        ?
                        <>
                            <div className="input">
                                <label htmlFor="documento">CPF*</label>
                                <InputMask mask="999.999.999-99" name="documento" id="documento" value={Cadastro.documento} onChange={(e) => handleChange(e)} checked />
                            </div>
                            <div className="input">
                                <label htmlFor="nome">Nome*</label>
                                <input type="text" name="nome" id="nome" value={Cadastro.nome} onChange={(e) => handleChange(e)} />
                            </div>
                        </>
                        :
                        <>
                            <div className="input">
                                <label htmlFor="documento">CNPJ*</label>
                                <InputMask mask="99.999.999/9999-99" name="documento" id="documento" value={Cadastro.documento} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="input">
                                <label htmlFor="nome">Razão Social*</label>
                                <input type="text" name="nome" id="nome" value={Cadastro.nome} onChange={(e) => handleChange(e)} />
                            </div>
                        </>
                    }
                    {dados == 'pessoaFisica' &&
                        <div className="input">
                            <label htmlFor="data">Data de Nascimento</label>
                            <InputMask mask="99/99/9999" name="data" id="data" />
                        </div>
                    }
                    <div className="input">
                        <label htmlFor="telefone">Telefone*</label>
                        <input type="phone" name="telefone" id="telefone" value={Cadastro.telefone} onChange={(e) => handleChange(e)} />
                    </div>

                    <h2>Endereço de Cobrança</h2>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="input" style={{ width: '45%' }}>
                            <label htmlFor="cep">CEP*</label>
                            <InputMask mask="99999-999" name="cep" id="cep" value={Cadastro.cep} onChange={(e) => handleChange(e)} />
                        </div>
                        <div className="input" style={{ width: '52%' }}>
                            <label htmlFor="enderecoBairro">Bairro*</label>
                            <input type="text" name="enderecoBairro" id="enderecoBairro" value={Cadastro.enderecoBairro} onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                    <div className="input">
                        <label htmlFor="endereco">Endereço*</label>
                        <input type="text" name="endereco" id="endereco" value={Cadastro.endereco} onChange={(e) => handleChange(e)} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="input" style={{ width: '30%' }}>
                            <label htmlFor="enderecoNumero">Número*</label>
                            <input type="text" name="enderecoNumero" id="enderecoNumero" value={Cadastro.enderecoNumero} onChange={(e) => handleChange(e)} />
                        </div>
                        <div className="input" style={{ width: '67%' }}>
                            <label htmlFor="enderecoComplemento">Complemento</label>
                            <input type="text" name="enderecoComplemento" id="enderecoComplemento" value={Cadastro.enderecoComplemento} onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="input" style={{ width: '48%' }}>
                            <label htmlFor="uf">UF*</label>
                            <InputMask mask="aa" name="uf" id="uf" value={Cadastro.uf} onChange={(e) => handleChange(e)} />
                        </div>
                        <div className="input" style={{ width: '49%' }}>
                            <label htmlFor="cidade">Cidade*</label>
                            <input type="text" name="cidade" id="cidade" value={Cadastro.cidade} onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                    <h2>Representante</h2>
                    <div className="input">
                            <label htmlFor="representante">Quem é seu representante*</label>
                            <input type="text" name="representante" id="representante" value={Cadastro.representante} onChange={(e) => handleChange(e)} />
                        </div>
                    <div className="input-checkbox" style={{ marginBottom: '50px' }}>
                        <input type="checkbox" name="politica" id="politica" checked={check} onChange={(e) => handleCheckbox(e)} />
                        <label htmlFor="politica">Li e aceito a <NavLink to="/politica-de-privacidade">Política de Privacidade</NavLink> do Exactus Software.</label>
                    </div>
                    <Botao texto={'Continuar'} onClick={() => verifica()} />
                </div>
            </div>
            <Footer footerValid={true} certificado={true}/>
        </>
    )
}

export default Cadastro