import { useContext } from "react";
import "./style.css";
import { EmailContext } from "../../context/EmailContext";
function CardPreco({ nome, quantidade, preco, recursos, produto, onClick }) {
  const { setPdf } = useContext(EmailContext);

  const handleClick = () => {
    if (produto === "start") {
      setPdf("Plano Start");
    } else if (produto === "basic") {
      setPdf("Plano Basic");
    } else if (produto === "pro") {
      setPdf("Plano Pro");
    }
    onClick();
  };
  return (
    <div className="card-preco">
      <div className="conteudo">
        <h4>{nome}</h4>
        <p>{quantidade}</p>
        <div className="preco">
        </div>

        <span>Principais Recursos</span>
        <ul className="recursos">{recursos}</ul>
      </div>
      <div className="botao">
        <button onClick={handleClick} className="botao-azul">
          Agende uma Demonstração
        </button>
      </div>
    </div>
  );
}

export default CardPreco;
