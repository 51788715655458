import React, { useContext, useEffect } from 'react'
import BannerSolucoes from '../../components/Banner/BannerSolucoes'
import SectionSolucoes from '../../components/Section/SectionSolucoes';
import SectionTecnologia from '../../components/Section/SectionTecnologia';
import Footer from '../../components/Footer/Footer';
import compliancefiscal from '../../images/Solucoes/compliancefiscal.jpg'
import compliancefiscalesquema from '../../images/Solucoes/compliancefiscalesquema.png'
import check from '../../images/GovernancaCadastral/Exclude.svg'
import nuvem from '../../images/Home/nuvem.svg'
import folhapagamento from '../../images/Planos/folhapagamento.svg'
import beneficios from '../../images/ERP/beneficios.svg'
import { NavLink } from "react-router-dom";
import Botao from "../../components/Botao/Botao";
import { EmailContext } from '../../context/EmailContext';

function ComplianceFiscal() {
    const { setPdf } = useContext(EmailContext)
    const { setContabilidade, setOrigem } = useContext(EmailContext)
    const handleContabilidade = () => {
        setContabilidade('Compliance Fiscal')
        setOrigem('compliance-fiscal')
    }
    useEffect(() => {
        setPdf('')
        window.scrollTo(0, 0);
    }, []);;
    return (
        <>
            <BannerSolucoes titulo={'COMPLIANCE FISCAL'} subtitulo={'O ajuste fino que faltava para as demonstrações contábeis da sua Empresa!'} imagem={compliancefiscal} origem={'compliance-fiscal'} nomeProduto={'Compliance Fiscal'} />
            <SectionSolucoes produto={'Como funciona o Compliance Fiscal Exactus?'} link={'https://www.youtube.com/embed/B14jpOQ8PEs?si=3Gnc41ofzikhSWiG'} origem={'compliance-fiscal'} nomeProduto={'Compliance Fiscal'} />
            <SectionTecnologia texto={'Compliance Fiscal é mais um dos serviços em nuvem da Exactus Intelligence. É como um Ajuste Fino nas demonstrações contábeis da empresa para garantir que todas as informações do Sped estejam em conformidade com os demais documentos, alinhando as informações nos documentos fiscais digitais com as normas fiscais atuais garantindo que todas os lançamentos estejam de acordo com a lei.'} imagem={compliancefiscalesquema} />
            <section className='razoes'>
                <div className='elemento-centralizado'>
                    <img src={beneficios} alt='Ícone' />
                </div>
                <h2>O que é analisado e quais os Benefícios deste serviço?</h2>
                <div className='beneficios-itens' style={{ margin: 'auto' }}>
                    <ul>
                        <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Implementação de correções que reduzem ou anulam riscos de penalidades</li>
                        <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Aumento da eficácia e da qualidade na geração de documentos e das escriturações fiscais digitais geradas pela empresa</li>
                        <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Direcionamento na escrituração e aproveitamento dos créditos identificados no período vigente</li>
                    </ul>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <NavLink to="/contato">
                        <Botao texto="Entre em contato aqui" onClick={() => handleContabilidade()} />
                    </NavLink>
                </div>
            </section>
            <section className="planos">
            <h2 style={{textAlign: 'center'}}>Conheça mais soluções da EXACTUS:</h2>
                <div className="container-empresarial">
                    <a href="/governanca-cadastral" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Governança Cadastral</h4>
                    </a>
                    <a href="/folha-de-pagamento" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={folhapagamento} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Folha de Pagamento</h4>
                    </a>
                    <a href="/recuperacao-tributaria" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Recuperação Tributária</h4>
                    </a>
                    <a href="/exclusao-icms" className="card-empresarial">
                        <div className="elemento-centralizado">
                            <img src={nuvem} alt="Ícone" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <h4>Exclusão do ICMS</h4>
                    </a>
                </div>
            </section>
            <Footer footerWhik={true} />
        </>
    )
}

export default ComplianceFiscal