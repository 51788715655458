import "./style.css";
import simples_nacional_logo from "../../images/SimplesNacional/simples_nacional_logo.svg";
import simples_nacional_modelo from "../../images/SimplesNacional/bannerhome2x.png";
import icon_lampada from "../../images/SimplesNacional/icon_lampada.svg";
import icon_curtir from "../../images/SimplesNacional/icon_curtir.svg";
import icon_automatizar from "../../images/SimplesNacional/icon_engrenagem.svg";
import icon_passoapasso from "../../images/SimplesNacional/icon_passoapasso.svg";
import icon_lupa from "../../images/SimplesNacional/icon_lupa.svg";
import icon_ia from "../../images/SimplesNacional/icon_ia.svg";
import icon_processamento from "../../images/SimplesNacional/icon_processamento.svg";
import icon_relatorio from "../../images/SimplesNacional/icon_relatorio.svg";
import icon_transmitir from "../../images/SimplesNacional/icon_transmitir.svg";
import icon_cam from "../../images/SimplesNacional/icon_cam.svg";
import icon_automatizado from "../../images/SimplesNacional/icon_automatizado.svg";
import icon_foguete from "../../images/SimplesNacional/icon_foguete.svg";
import icon_integracao from "../../images/SimplesNacional/icon_integracao.svg";
import modulos from "../../images/SimplesNacional/conjuntos_modulos.svg";

import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import InputMask from "react-input-mask";
import Sucesso from "../../components/Alert/Sucesso";
import Erro from "../../components/Alert/Erro";
import Loading from "../../components/Loading/Loading";
import { EmailContext } from "../../context/EmailContext";
import { validateUF, validateEmail } from 'validations-br';
import BotaoSimplesNacional from '../../components/Botao/BotaoSimplesNacional'
import Footer from '../../components/Footer/Footer'

function SimplesNacional() {

  const { FaleConosco, msgSucessoEmail, msgErroEmail, setMsgErroEmail, nomeContato, setNomeContato,
    emailContato, setEmailContato,
    ddd, setDdd,
    telefone, setTelefone,
    cidade, setCidade,
    origem,
    uf, setUf,
    contabilidade, setContabilidade, loading, check, setCheck } = useContext(EmailContext)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setContabilidade("Simples Nacional Fácil")
  }, [contabilidade]);

  const handleCheckbox = (event) => {
    setCheck(event.target.checked)
  }

  const verifica = (e, origem) => {
    e.preventDefault()
    if (nomeContato < 6) {
      setMsgErroEmail("Preencha o Nome")
      setTimeout(() => {
        setMsgErroEmail("");
      }, 6000);
      return
    }
    else if (!validateEmail(emailContato)) {
      setMsgErroEmail("E-mail inválido")
      setTimeout(() => {
        setMsgErroEmail("");
      }, 6000);
      return
    }
    else if (cidade < 3) {
      setMsgErroEmail("Preencha a Cidade")
      setTimeout(() => {
        setMsgErroEmail("");
      }, 6000);
      return
    }
    else if (!validateUF(uf)) {
      setMsgErroEmail("UF inválido")
      setTimeout(() => {
        setMsgErroEmail("");
      }, 6000);
      return
    }
    FaleConosco(origem)
  }


  return (
    <>
      {msgSucessoEmail && <Sucesso />}
      {msgErroEmail && <Erro />}
      {loading && <Loading />}
      {/* parte rodrigo */}
      <section className="banner-simples-nacional">
        <div className="conteudo-banner">
          <img
            src={simples_nacional_logo}
            alt="Simples Nacional Fácil"
            style={{ width: "250px", height: "230px", marginTop: "20px" }}
          />
          <p>
            Entre um Café e outro, seu <br />
            <b>Simples Nacional Fácil.</b>
            <br />
            Ficou Pronto!
          </p>
          <BotaoSimplesNacional
            onClick={() =>
              document
                .getElementById("formulario-section")
                .scrollIntoView({ behavior: "smooth" })
            }
            texto={"Fale Conosco"}
          />
        </div>
        <div>
          <img
            id="img-modelo"
            src={simples_nacional_modelo}
            style={{ width: "450px", height: "450px", marginTop: "40px" }}
          />
        </div>
      </section>
      <section>
        <div className="vetor-1">
          <div className="conteudo-vetor-1">
            <div className="info-vetor-1">
              <img
                src={icon_lampada}
                alt="Icone Lampada"
                style={{ width: "60px", height: "60px" }}
              />
              <p className="titulo-info-vetor-1">
                O que é o <b>Simples Nacional Fácil?</b>
              </p>
              <p>
                O <b>Simples Nacional Fácil</b> da <b>Exactus Intelligence</b> é
                uma solução automatizada que oferece agilidade e segurança total
                no processo de escrituração fiscal, apuração de tributos no
                regime simplificado, e entrega de obrigações fiscais das
                Empresas para o Fisco.
              </p>
              <button
                onClick={() =>
                  document
                    .getElementById("formulario-section")
                    .scrollIntoView({ behavior: "smooth" })
                }
              >
                {" "}
                Fale Conosco{" "}
              </button>
            </div>
            <div className="info-2-vetor-1">
              <div className="conteudo-info-2">
                <img src={icon_curtir} />
                <p className="info-titulos">
                  Performace
                  <br />
                  <b>Aumentada</b>
                </p>
                <p className="info-msg">
                  Otimizar o tempo permitindo ao contador atender mais clientes
                  aumentando seus lucros.
                </p>
              </div>
              <div className="conteudo-info-2">
                <img src={icon_automatizar} />
                <p className="info-titulos">
                  Automatização
                  <br />
                  <b>de Processos</b>
                </p>
                <p className="info-msg">
                  Redução de trabalho operacional aumentando as possibilidades
                  estratégicas do contador.
                </p>
              </div>
              <div className="conteudo-info-2">
                <img src={icon_integracao} />
                <p className="info-titulos">
                  Integração
                  <br />
                  <b>Completa</b>
                </p>
                <p className="info-msg">
                  Integração automatizada e completa entre o seu cliente, o
                  contador e o Governo.{" "}
                </p>
              </div>
            </div>
            <div className="video-vetor-1">
              <iframe
                style={{ borderRadius: "30px" }}
                width="560"
                height="315"
                src="https://www.youtube.com/embed/oLBWhdbuv2o?si=2GA12rIYx0DsT7fR"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <div className="p-1">
        <div className="conteudo-p-1">
          <img src={icon_passoapasso} />
          <p>
            Passo a Passo do <br />
            <b>Simples Nacional Fácil</b>
          </p>
        </div>
      </div>
      <section>
        <div className="vetor-2">
          <div className="conteudo-vetor-2">
            <div className="esquerda-vetor-2">
              <div>
                <img src={icon_lupa} />
                <div className="conteiner-titulo-vetor-2">
                  <span>1</span>
                  <p className="titulo-vetor-2">
                    Busca de <br />
                    <b>Documentos</b>
                  </p>
                </div>
                <p className="texto-vetor-2">
                  O buscador do <b>SIMPLES NACIONAL FÁCIL</b> da Exactus
                  Intelligence faz a busca e organiza automaticamente todos os
                  seus documentos fiscais.
                </p>
              </div>
              <div>
                <img src={icon_processamento} />
                <div className="conteiner-titulo-vetor-2">
                  <span>3</span>
                  <p className="titulo-vetor-2">
                    Processamento <br />
                    <b>dos Cálculos</b>
                  </p>
                </div>
                <p className="texto-vetor-2">
                  O <b>SIMPLES NACIONAL FÁCIL</b> da <b>Exactus Intelligence</b>{" "}
                  automatiza esses cálculos, eliminando erros e garantindo seus
                  custos com valores na medida certa.
                </p>
              </div>
              <div>
                <img src={icon_transmitir} />
                <div className="conteiner-titulo-vetor-2">
                  <span>5</span>
                  <p className="titulo-vetor-2">
                    Transmissão <br />
                    <b> para o Fisco </b>
                  </p>
                </div>
                <p className="texto-vetor-2">
                  Com os processos concluídos de forma automatizada o contador
                  tem a autonomia para enviar os arquivos ao Fisco. É tão
                  simples quanto apertar um botão, seguro e eficiente.
                </p>
              </div>
            </div>
            <div className="direita-vetor-2">
              <div>
                <img src={icon_ia} />
                <div className="conteiner-titulo-vetor-2">
                  <span>2</span>
                  <p className="titulo-vetor-2">
                    Escrituração <br />
                    <b>Fiscal Automatizada</b>
                  </p>
                </div>
                <p className="texto-vetor-2">
                  Com o <b>SIMPLES NACIONAL FÁCIL</b>, esse processo é mais
                  rápido e livre de erros. Com nosso sistema automatizado, nós
                  garantimos a conformidade sem dor de cabeça!{" "}
                </p>
              </div>
              <div>
                <img src={icon_relatorio} />
                <div className="conteiner-titulo-vetor-2">
                  <span>4</span>
                  <p className="titulo-vetor-2">
                    Relatórios <br />
                    <b>Automatizados</b>
                  </p>
                </div>
                <p className="texto-vetor-2">
                  Com o <b>SIMPLES NACIONAL FÁCIL</b> da{" "}
                  <b>Exactus Intelligence</b> você terá relatórios simples e
                  precisos para sua gestão.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="p-2">
        <div className="conteudo-p-2">
          <div>
            <img src={icon_cam} />
            <p>
              Mais <br />
              <b>Seguro</b>
            </p>
          </div>
          <div>
            <img src={icon_automatizado} />
            <p>
              Mais <br />
              <b>Automatizado</b>
            </p>
          </div>
          <div>
            <img src={icon_foguete} />
            <p>
              Mais
              <br />
              <b>Ágil</b>
            </p>
          </div>
        </div>
        <button
          onClick={() =>
            document
              .getElementById("formulario-section")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          {" "}
          Fale Conosco{" "}
        </button>
      </div>

      {/* parte raquel */}
      <div>
        <h2></h2>
      </div>
      <section>
        <div className="vetor-3">
          <div className="imagem-fundo">
            <div className="content">
              <div className="texto">
                <p className="info-titulos">O <b>Simples Nacional Fácil</b> <br />é um sistema completo para o contador <br /> automatizar suas rotinas</p>
              </div>
              <div className="modulos">
                <img src={modulos} alt="conjunto de modulos" />
              </div>
              <div className="texto">
                <p className="info-titulos">Feito com a tecnologia da <br /><b>Exactus Intelligence!</b></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* formulario*/}
      <section>
        <div id='formulario-section' className="formulario">
          <div className="form-container">
            <form >
              <div className="texto" style={{ color: 'black', padding: '40px' }}>
                <h2>O <b>Simples Nacional Fácil</b> foi feito sob <br />medida para facilitar suas rotinas diárias, <br />entre em contato para saber mais!</h2>
              </div>
              <div className="form-group">
                <input
                  onChange={(e) => setNomeContato(e.target.value)}
                  placeholder="Nome Completo *"
                  type="text"
                  value={nomeContato}
                  id="nome"
                  name="nome" />
              </div>
              <div className="form-group inline-group">
                <InputMask className="input-mask"
                  mask="(99)"
                  placeholder="DDD *"
                  style={{ width: '15%' }}
                  name="DDD"
                  value={ddd}
                  onChange={(e) => setDdd(e.target.value)} />
                <InputMask className="input-mask"
                  mask="99999-9999"
                  placeholder="Celular *"
                  style={{ width: '85%' }}
                  name="telefone"
                  value={telefone}
                  onChange={(e) => setTelefone(e.target.value)} />

              </div>
              <div className="form-group">
                <input
                  placeholder="Email *"
                  type="email"
                  value={emailContato}
                  id="email"
                  name="email"
                  onChange={(e) => setEmailContato(e.target.value)} />
              </div>
              <div className="form-group inline-group">
                <input
                  className="input-mask"
                  type="text"
                  placeholder="Cidade *"
                  style={{ width: '70%' }}
                  value={cidade}
                  onChange={(e) => setCidade(e.target.value)} />
                <InputMask className="input-mask"
                  mask="aa"
                  placeholder="UF *"
                  name="uf"
                  style={{ width: '30%' }}
                  value={uf}
                  onChange={(e) => setUf(e.target.value.toUpperCase())} />
              </div>
              <div className="form-group checked-group">
                <input style={{ width: '5%' }} type="checkbox" name="politicaprivacidade" id="option2" value="option2" checked={check} onChange={(e) => handleCheckbox(e)} />
                <label htmlFor="politicaprivacidade">Declaro que li a presente <NavLink style={{ color: '#777777' }} to="/politica-de-privacidade">Política de Privacidade</NavLink></label>
              </div>
              <div style={{ width: '30%' }}>
                <BotaoSimplesNacional onClick={(e) => verifica(e, origem)} texto={"Enviar"} />
              </div>

            </form>
          </div>
        </div >
      </section >
      <Footer />
    </>

  );
}

export default SimplesNacional;
