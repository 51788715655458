import './style.css'

function Accordion() {
    return (
        <section className='razoes'>
            <h2>Dúvidas frequentes:</h2>
            <div class="accordion">
                <span class="target-fix" id="accordion"></span>
                <div>
                    <span class="target-fix" id="accordion1"></span>

                    <a href="#accordion1" id="open-accordion1" title="open">1 . É possível salvar os relatórios da contabilidade em quais formatos?</a>
                    <a href="#accordion" id="close-accordion1" title="close">1 . É possível salvar os relatórios da contabilidade em quais formatos?</a>

                    <div class="accordion-content">
                        <p>Os relatórios podem ser exportados em PDF, Excel, RTF e diversas outros formatos.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion2"></span>
                    <a href="#accordion2" id="open-accordion2" title="open">2 .  O sistema possui controle das contas de Resultado por Centro de Custos?</a>
                    <a href="#accordion" id="close-accordion2" title="close">2 .  O sistema possui controle das contas de Resultado por Centro de Custos?</a>
                    <div class="accordion-content">
                        <p>Temos a contabilização por Centro de Custos, onde o sistema permite  a geração dos relatórios por Centro de Custos, Conjunto de Centro de Custos, facilitando a análise gerencial das informações.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion3"></span>
                    <a href="#accordion3" id="open-accordion3" title="open">3 .  O sistema está apto a entrega das Escriturações Digitais?</a>
                    <a href="#accordion" id="close-accordion3" title="close">3 .  O sistema está apto a entrega das Escriturações Digitais?</a>
                    <div class="accordion-content">
                        <p>Sim, sempre atentos a legislação, o sistema gera a ECD - Escrituração Contábil Digital e ECF - Escrituração Contábil Fiscal.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion4"></span>
                    <a href="#accordion4" id="open-accordion4" title="open">4 .  Existe opção de importação de Extratos Bancários?</a>
                    <a href="#accordion" id="close-accordion4" title="close">4 .  Existe opção de importação de Extratos Bancários?</a>
                    <div class="accordion-content">
                        <p>Nós temos a solução para otimizar o trabalho dos contadores. A importação Automática de Extratos. Ela possibilita que o sistema através de uma API, efetue a importação do extrato direto do Banco no qual seu cliente possui conta corrente, tudo isso em alguns cliques. Também existe opção no sistema para importação de arquivos gerados no formato OFX, OFC e Cnab240 posições.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion5"></span>
                    <a href="#accordion5" id="open-accordion5" title="open">5 .  Tenho um arquivo de lançamentos e preciso importar para a contabilidade, é possível?</a>
                    <a href="#accordion" id="close-accordion5" title="close">5 .  Tenho um arquivo de lançamentos e preciso importar para a contabilidade, é possível?</a>
                    <div class="accordion-content">
                        <p>Sim, através do leiaute configurado, é possível a importação de lançamentos através de arquivos no formato texto e Excel (Ainda está em desenvolvimento).</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion6"></span>
                    <a href="#accordion6" id="open-accordion6" title="open">6 . O sistema permite a inclusão de lançamentos em quais partida?</a>
                    <a href="#accordion" id="close-accordion6" title="close">6 . O sistema permite a inclusão de lançamentos em quais partida?</a>
                    <div class="accordion-content">
                        <p>É possível a inclusão de lançamentos através de partida dobrada, simples ou múltiplas.</p>
                    </div>
                </div>

                <div>
                    <span class="target-fix" id="accordion7"></span>
                    <a href="#accordion7" id="open-accordion7" title="open">7 .  É possível alterar um lançamento através do Razão e Balancete?</a>
                    <a href="#accordion" id="close-accordion7" title="close">7 .  É possível alterar um lançamento através do Razão e Balancete?</a>
                    <div class="accordion-content">
                        <p>Nas opções do Consulta Razão e Consulta Balancete, é possível efetuar qualquer alteração do lançamento, e ao salvar o lançamento, o relatório é atualizado automaticamente.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Accordion